<p-blockUI [blocked]="blockedDocument" style="background: black" [baseZIndex]="5000">
  <div class="order-block">
    <div class="row">
      <div class="col text-center">
        <h1 style="color: #fff; padding: 20px;">
          This order is currently edited by {{ otherUser.user}}
        </h1>
      </div>
    </div>
    <div class="row">
      <div class="col text-center">
        <i class="fa fa-lock" [ngClass]="isSmallerThanLarge? 'fa-3x': 'fa-5x'" (click)="unlock()">
          Click to unlock
        </i>
      </div>
    </div>
  </div>
</p-blockUI>
<p-dialog [(visible)]="deleteSidebar" [modal]="true" id="deleteDialog" [style]="{width: '850px', height: '800px', minWidth: '600px'}"
    [minY]="700" [maximizable]="true" [baseZIndex]="2000">
  <div class="container">
    <h1 style="font-weight:normal">Delete Confirmation</h1>
    <h2 style="font-weight:normal">For security, please re-submit your account password to delete this record</h2>
    <label for="deleteEmail" class="delLabel">Email</label>
    <input id="deleteEmail" type="text" [(ngModel)]="email"><br><br>
    <label for="deletePwd" class="delLabel">Password</label>
    <input id="deletePwd" type="password" [(ngModel)]="password" ><br>
    <app-message></app-message>
  </div>
  <p-footer>
    <button type="button" class="btn btn-danger" style=" padding: 6px" (click)="delete(password)">Delete</button>
    <button type="button" class="btn btn-warning" style="margin-left: 20px; padding: 7px" (click)="deleteSidebar = false">
      Cancel
    </button>
  </p-footer>
</p-dialog>


<!--###########################################################################################################-->
<!--###########################################################################################################-->
<!-- Start Original sidebar-->
<p-sidebar [(visible)]="showAddProductSidebar" [style]="{width:'26em'}" position="right" [baseZIndex]="2000" [dismissible]="false">
  <span class="addProducts">
    <table class="table table-hover">
      <tbody [formGroup]="addHardwareForm">
        <ng-container formArrayName="addHardware">
          <tr *ngFor="let addForCategory of addHardwareForms.controls; let categoryIndex=index" [formGroupName]="categoryIndex">
            <th scope="row">{{addForCategory.value.category}}</th>
            <td>
              <p-dropdown class="noAutoSave" [showClear]="true"
                  [options]="hardwareByCategory[addForCategory.value.category]" formControlName="itemToAdd"
                  [filter]="true" [resetFilterOnHide]="true" optionLabel="label" placeholder="Select item to add">
              </p-dropdown>
              <span class="addpointer" (click)="addAdditionalEquipmentFromHardware(addForCategory.value.itemToAdd)">add</span>
            </td>
          </tr>
        </ng-container>
      </tbody>
      <tbody>
        <tr>
          <th scope="row">Key Safes</th>
          <td>
            <p-dropdown class="noAutoSave" [showClear]="true" [options]="keySafes" [(ngModel)]="selectedKeySafe"
                [filter]="true" [resetFilterOnHide]="true" optionLabel="crmTitle" placeholder="Select item to add">
            </p-dropdown>
            <span class="addpointer" (click)="addKeySafe()">add</span>
          </td>
        </tr>
      </tbody>
      <tbody>
        <tr>
          <th scope="row">Services</th>
          <td>
            <p-dropdown class="noAutoSave" [showClear]="true" [options]="allServices" [(ngModel)]="selectedService"
                [filter]="true" [resetFilterOnHide]="true" optionLabel="title" placeholder="Select item to add">
            </p-dropdown>
            <span class="addpointer" (click)="addService()">add</span>
          </td>
        </tr>
      </tbody>
    </table>
  </span>
  <button pButton type="button" (click)="showAddProductSidebar = false" label="Close" class="ui-button-secondary close-side-menu">
  </button>
</p-sidebar>
<!--End sidebar-->
<!--###########################################################################################################-->
<!--###########################################################################################################-->
<!--###########################################################################################################-->
<!--###########################################################################################################-->

<p-toast></p-toast>
<div class="container">
  <p-progressSpinner *ngIf="loading"></p-progressSpinner>
</div>
<!-- Start Order Page Header-->
<div *ngIf="! loading" class="container headersticky"
    [style.background-color]="getStatusBackgroundColour(orderForm.value.status.status)">
  <div class="row no-gutters" id="td-head">
    <div class="col-11 col-md-5">
      <span class="websitecolor"
          [style.background-color]="getBrandTitleBarColour(orderForm.value.website.title)"
          [style.color]="getBrandColour(orderForm.value.website.title)">
        {{orderForm.value.website.title}}
      </span>
      <span class="tdcodencustomername">
        {{orderForm.value.alarmUserDetails.tdCode}} -
        {{orderForm.value.alarmUserDetails.firstName}} {{orderForm.value.alarmUserDetails.lastName}}
      </span>
    </div>
    <div class="col-1 col-md-1">
      <div ngbDropdown class="d-inline-flex">
        <button type="button" id="copyName" ngbDropdownToggle class="ml-2 btn btn-dark" aria-controls="copyNameControl">
          Copy Name
        </button>
        <div ngbDropdownMenu aria-labelledby="copyName" id="copyNameOptions">
          <button ngbDropdownItem type="button" (click)="copyName()">
            Name Only
          </button>
          <button ngbDropdownItem type="button" (click)="copyTdCodeAndName()">
            TD Code and Name
          </button>
          <button *ngIf="alarmCode" ngbDropdownItem type="button" (click)="copyAlarmCodeAndName()">
            Alarm Code and Name
          </button>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6 printHide">
      <div class="row no-gutters">
        <div class="col-10">
          <a role="button" title="Open NCF folder" target="_blank" href="{{ncfLink}}">
            <li class="hlist clickncf">NCF</li>
          </a>
          <a *ngIf="order.initialOrderDetails.transactionId" title="Stripe initial order transaction"
              href="https://dashboard.stripe.com{{environment.production? '': '/test'}}/payments/{{order.initialOrderDetails.transactionId}}"
              target="_blank">
            <li class="hlist">Stripe Payment</li>
          </a>
          <a *ngIf="!order.initialOrderDetails.transactionId" title="Stripe initial order transaction"
              href="{{order.legalCompany | stripeDashboardLink:order.website.title}}"
              target="_blank">
            <li class="hlist">Stripe Payment</li>
          </a>
          <a title="SmartDebit Payer details"
              href="https://{{environment.production? 'online': 'sandbox'}}.smartdebit.co.uk/Payers/Details?Pslid={{order.pslid}}&ReferenceNumber={{order.referencenumber}}" target="_blank">
            <li class="hlist">SmartDebit</li>
          </a>
          <a title="GoCardelss Details"
              href="https://{{environment.production? 'manage': 'manage-sandbox'}}.gocardless.com/customers/{{order.goCardlessId}}" target="_blank">
            <li class="hlist">GoCardless</li>
          </a>
          <a *ngIf="alarmSiteLink > ''"  title="Initial Order on Alarm Site" href="{{alarmSiteLink}}" target="_blank">
            <li class="hlist">Website</li>
          </a>
          <button *ngIf="emailTemplates.length > 0" type="button" style="border:none" class="hlist" (click)="sendCustomerEmail()"
              title="Send a Templated email to the customer">
            Email Customer
          </button>
          <button *ngIf="letterTemplates.length > 0" type="button" style="border:none" class="hlist" (click)="sendCustomerLetter()"
              title="Send a Templated letter to the customer">
            Customer Letter
          </button>
          <button *ngIf="smsTemplates.length > 0" type="button" style="border:none" class="hlist" (click)="sendCustomerSms()"
              title="Send a Templated SMS to the customer">
            Customer SMS
          </button>
          <button type="button" style="border:none" class="hlist" (click)="showMessageReport()">
            Message Log
          </button>
          <button type="button" style="border:none" class="hlist" (click)="saveLayout()"
              title="Save page layout (section position and minimized/maximised status)">
            Save Layout
          </button>
        </div>
        <div class="col-2 icons">
          <button type="button" class="iconButton ml-0" (click)="exportOrder()" title="Export Order to Excel">
            <i class="fa fa-download" [ngClass]="isSmallerThanLarge? 'fa-1x': 'fa-lg'"></i>
          </button>
          <div ngbDropdown class="d-inline-flex">
            <button type="button" id="emailOrderLink" ngbDropdownToggle class="iconButton" aria-label="Share CRM Order" title="Share CRM Order">
              <i class="fa fa-envelope" [ngClass]="isSmallerThanLarge? 'fa-1x': 'fa-lg'"></i>
            </button>
            <div ngbDropdownMenu aria-labelledby="emailOrderLink" id="emailOrderLinkOptions">
              <div class="dropdown-item">
                <a title="Shared CRM Order" target="_blank"
                    href="mailto:?subject=CRM Shared Order: {{orderForm.value.alarmUserDetails.tdCode}}&amp;body=Please check out this CRM Order {{orderLink}}.">
                  Desktop Outlook
                </a>
              </div>
              <div class="dropdown-item">
                <a title="Shared CRM Order" target="_blank"
                    href="https://outlook.office.com/?path=/mail/action/compose&amp;subject=CRM+Shared+Order: {{order.alarmUserDetails.tdCode}}&amp;body=Please+check+out+this+CRM+Order+{{orderLink}}.">
                  Web Outlook
                </a>
              </div>
              <!-- <button ngbDropdownItem type="button" (click)="emailLinkDesktop()">Using Desktop Outlook</button>
              <button ngbDropdownItem type="button" (click)="emailLinkWeb()">Using Web Outlook</button> -->
            </div>
          </div>
          <button type="button" (click)="deleteSidebar=true" *ngIf="userCanDeleteOrders()" class="iconButton" title="Delete Order">
            <i style="color: red" class="fa fa-trash" [ngClass]="isSmallerThanLarge? 'fa-1x': 'fa-lg'">
            </i>
          </button>
        </div>
      </div>
    </div>
    <div class="col-6 px-2 py-1 mt-1 mb-0" [ngClass]="(pendingUpdates > 0)? 'alert alert-danger': 'alert alert-success'">
      Pending Updates: {{pendingUpdates}}
    </div>
    <div class="col-6 px-2 py-1 mt-1 mb-0" [ngClass]="orderForm.value.legalCompany | legalCompanyToClass">
      Company (not brand): {{orderForm.value.legalCompany}}
    </div>
    <div *ngIf="isNotLLMonitored" class="col-12 px-2 py-1 mt-1 mb-0 alert alert-danger">
      Please note this order is monitoring by {{monitoringCompany}}, not by the Norwich ARC team.
    </div>
  </div>
</div>
<!-- End Order Page Header-->
<ng-template #billingAccountLinks>
  <div class="form-row">
    <ng-container *ngIf="order.legalCompany | stripeAccountName:order.website.title">
      <div class="form-group col-5">
        <label for="stripeAccount">Stripe Account Should Be</label>
        <input type="text" name="stripeAccount" id="stripeAccount" value="{{order.legalCompany | stripeAccountName:order.website.title}}" readonly>
      </div>
      <div class="form-group col-1">
        <label></label>
        <a href="{{order.legalCompany | stripeDashboardLink:order.website.title}}" target="_blank" title="Open Stripe Dashboard">
          <i class="pi pi-external-link"></i>
        </a>
      </div>
    </ng-container>
    <ng-container *ngIf="order.legalCompany | smartDebitAccountName:order.website.title">
      <div class="form-group col-6">
        <label for="smartDebitAccount">SmartDebit Service User Should Be</label>
        <input type="text" name="smartDebitAccount" id="smartDebitAccount" value="{{order.legalCompany | smartDebitAccountName:order.website.title}}" readonly>
      </div>
    </ng-container>
  </div>
</ng-template>
<div class="container">
  <p-confirmDialog key="general"></p-confirmDialog>
  <p-confirmDialog key="aboveBlockMessage" [baseZIndex]="6000"></p-confirmDialog>
  <form #all [formGroup]="orderForm" (change)="autosave($event)" novalidate>
    <!-- define all of the templates first-->
    <!-- Start alarm User Details-->
    <ng-template #alarmUserDetailsSection let-pageSection="pageSection">
      <ng-container *ngIf="! loading" formGroupName="alarmUserDetails">
        <div class="formbar row no-gutters">
          <div class="col-6">
            Alarm User Details
          </div>
          <div class="col-6">
            <div class="tdCode">
              <span class="crmTooltipLight">TD Code</span>
              <input class="mid" type="text" name="tdCode" oninput="this.value = this.value.toUpperCase()" formControlName="tdCode" readonly>
            </div>
            <button (click)="minimizeSection(pageSection)" type="button" class="btn minMax">
              <i *ngIf="!pageSection.minimized" class="pi pi-minus"></i>
              <i *ngIf="pageSection.minimized" class="pi pi-plus"></i>
            </button>
          </div>
        </div>
        <div class="formsect">
          <div [ngStyle]="{'display': pageSection.minimized? 'none': 'block'}">
            <div class="primaryContact row no-gutters addPostBar" [ngClass]="{'selected': orderForm.value.alarmUserDetails.primaryContact}">
              <div class="col-8">
                <span class="pcLabel" placement="right" [openDelay]=1000 [closeDelay]=1000
                    [ngbTooltip]="tooltipByLabel('Order Page.Alarm User Details.Primary Contact')">
                  <span class="crmTooltipLight">Primary Contact</span>
                </span>
                <label class="switch">
                  <input type="checkbox" class="noAutoSave" (change)="primaryContactChange($event, 'AlarmUser', -1)" formControlName="primaryContact" >
                  <span class="slider"></span>
                </label>
                <div *ngIf="orderForm.value.alarmUserDetails.primaryContact" class="selectedPCLabel">
                  *** PRIMARY CONTACT ***
                </div>
              </div>
              <div class="col-3 text-right">
                <button *ngIf="false" type="button" (click)="showAddPost()" class="btn btn-primary">
                  Send Post Item
                </button>
              </div>
              <div class="col-1 text-right">
                <button type="button" (click)="validateBestAddressAndCopyToClipboard()" class="iconButton mr-2"
                    title="Copy Correspondence or Alarm User (when no Correspondence) name and address">
                  <i class="fa fa-copy bestAddress"></i>
                </button>
                <button type="button" (click)="validateUserAddressAndCopyToClipboard()" class="iconButton mr-2"
                    title="Copy Alarm User Name and Address">
                  <i class="fa fa-copy"></i>
                </button>
              </div>
            </div>
            <div class="doNotCall row no-gutters" [ngClass]="{'selected': orderForm.value.alarmUserDetails.doNotCall}">
              <div class="col-12">
                <span class="dncLabel">Do Not Call</span>
                <label class="switch">
                  <input type="checkbox" formControlName="doNotCall" >
                  <span class="slider"></span>
                </label>
                <div *ngIf="orderForm.value.alarmUserDetails.doNotCall" class="selectedDNCLabel">
                  *** DO NOT CALL ***
                </div>
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-4">
                <label>First Name</label>
                <input type="text" class="tests" name="firstName" formControlName="firstName" required
                  (change)="contactDetailsChanged('Alarm User First Name', orderFormOld.alarmUserDetails.firstName, alarmUserDetails.value.firstName)">
              </div>
              <div class="form-group col-4">
                <label>Other Names</label>
                <input type="text" name="otherName" formControlName="otherName"
                  (change)="contactDetailsChanged('Alarm User Other Names', orderFormOld.alarmUserDetails.otherName, alarmUserDetails.value.otherName)">
              </div>
              <div class="form-group col-4">
                <label>Last Name</label>
                <input type="text" name="lastName" formControlName="lastName" required
                  (change)="contactDetailsChanged('Alarm User Last Name', orderFormOld.alarmUserDetails.lastName, alarmUserDetails.value.lastName)">
              </div>
              <div class="form-group col-3">
                <label>Preferred Name</label>
                <input type="text" name="preferredName" formControlName="preferredName"
                  (change)="contactDetailsChanged('Alarm User Preferred Name', orderFormOld.alarmUserDetails.preferredName, alarmUserDetails.value.preferredName)">
              </div>
              <div class="form-group col-5" >
                <label>Email</label>
                <input class="validated" type="email" name="email" formControlName="email" >
              </div>
              <div class="form-group col-3" >
                <label>Email Marketing</label>
                <p-dropdown [options]="marketingOptInSelects" formControlName="emailMarketing" class="noAutoSave"
                  (onChange)="updateAlarmUserMarketingUpdated('emailMarketingUpdated')">
                </p-dropdown>
              </div>
              <div class="form-group col-1" >
                <button class="btn showbtn" type="button" (click)="toggle()" id="bt">{{buttonName}}</button>
              </div>
              <ng-container *ngIf="showEmailTwo">
                <div class="form-group col-3 "></div>
                <div class="form-group col-5">
                  <label>Email 2</label>
                  <input class="validated" type="email" name="emailTwo" formControlName="emailTwo" >
                </div>
                <div class="form-group col-3" >
                  <label>Email 2 Marketing</label>
                  <p-dropdown [options]="marketingOptInSelects" formControlName="emailTwoMarketing" class="noAutoSave"
                    (onChange)="updateAlarmUserMarketingUpdated('emailTwoMarketingUpdated')"></p-dropdown>
                </div>
              </ng-container>
            </div>
            <div class="form-row" [ngClass]="alarmUserDetails.errors?.contactDetailsRequired? 'valueRequired': 'valueProvided'">
              <div class="form-group col-3">
                <label>
                  <span placement="right" [openDelay]=1000 [closeDelay]=1000 [ngbTooltip]="tooltipByLabel('Order Page.Common.MobileAndTelephone')" class="crmTooltipDark">
                    Telephone
                  </span>
                </label>
                <input class="validated" type="text" (keypress)="phoneNumberOnly($event)" name="telephone" formControlName="telephone" pattern="[0-9]+"
                  (change)="contactDetailsChanged('Alarm User Telephone', orderFormOld.alarmUserDetails.telephone, alarmUserDetails.value.telephone)">
              </div>
              <div class="form-group col-3">
                <div class="row">&nbsp;</div>
                <div class="row no-gutters">
                  <div class="pound col-3">&pound;</div>
                  <div class="col-3">
                    <span class="disabledInput">{{getPaymentSuccesses(orderForm.value.alarmUserDetails.telephone)}}</span>
                  </div>
                  <div class="thumbs col-3">
                    <button type="button" [disabled]="isThumbsUpDownDisabled || !orderForm.value.alarmUserDetails.telephone"
                        (click)="updateThumbsUpThumbsDownValue(orderForm.value.alarmUserDetails.telephone,'SuccessfulPayments',1)">
                      <i class="pi pi-arrow-up"></i>
                    </button>
                  </div>
                  <div class="thumbs col-3">
                    <button type="button" [disabled]="isThumbsUpDownDisabled || !orderForm.value.alarmUserDetails.telephone"
                        (click)="updateThumbsUpThumbsDownValue(orderForm.value.alarmUserDetails.telephone,'SuccessfulPayments',-1)">
                      <i class="pi pi-arrow-down"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div class="form-group col-3">
                <div class="row">&nbsp;</div>
                <div class="row no-gutters">
                  <div class="thumbs col-3"><i class="pi pi-thumbs-up"></i></div>
                  <div class="col-3">
                    <span class="disabledInput">{{getThumbsUp(orderForm.value.alarmUserDetails.telephone)}}</span>
                  </div>
                  <div class="thumbs col-3">
                    <button type="button" [disabled]="isThumbsUpDownDisabled || !orderForm.value.alarmUserDetails.telephone"
                        (click)="updateThumbsUpThumbsDownValue(orderForm.value.alarmUserDetails.telephone,'ThumbsUp',1)">
                      <i class="pi pi-arrow-up"></i>
                    </button>
                  </div>
                  <div class="thumbs col-3">
                    <button type="button" [disabled]="isThumbsUpDownDisabled || !orderForm.value.alarmUserDetails.telephone"
                        (click)="updateThumbsUpThumbsDownValue(orderForm.value.alarmUserDetails.telephone,'ThumbsUp',-1)">
                      <i class="pi pi-arrow-down"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div class="form-group col-3">
                <div class="row">&nbsp;</div>
                <div class="row no-gutters">
                  <div class="thumbs col-3"><i class="pi pi-thumbs-down"></i></div>
                  <div class="col-3">
                    <span class="disabledInput">{{getThumbsDown(orderForm.value.alarmUserDetails.telephone)}}</span>
                  </div>
                  <div class="thumbs col-3">
                    <button type="button" [disabled]="isThumbsUpDownDisabled || !orderForm.value.alarmUserDetails.telephone"
                        (click)="updateThumbsUpThumbsDownValue(orderForm.value.alarmUserDetails.telephone,'ThumbsDown',1)">
                      <i class="pi pi-arrow-up"></i>
                    </button>
                  </div>
                  <div class="thumbs col-3">
                    <button type="button" [disabled]="isThumbsUpDownDisabled || !orderForm.value.alarmUserDetails.telephone"
                        (click)="updateThumbsUpThumbsDownValue(orderForm.value.alarmUserDetails.telephone,'ThumbsDown',-1)">
                      <i class="pi pi-arrow-down"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div class="form-group col-3">
                <label>
                  <span placement="right" [openDelay]=1000 [closeDelay]=1000 [ngbTooltip]="tooltipByLabel('Order Page.Common.MobileAndTelephone')" class="crmTooltipDark">
                    Mobile
                  </span>
                </label>
                <input class="validated" type="text" (keypress)="phoneNumberOnly($event)" name="mobile" formControlName="mobile" pattern="[0-9]+"
                    (change)="contactDetailsChanged('Alarm User Mobile', orderFormOld.alarmUserDetails.mobile, alarmUserDetails.value.mobile)">
              </div>
              <div class="form-group col-3">
                <div class="row">&nbsp;</div>
                <div class="row no-gutters">
                  <div class="pound col-3">&pound;</div>
                  <div class="col-3">
                    <span class="disabledInput">{{getPaymentSuccesses(orderForm.value.alarmUserDetails.mobile)}}</span>
                  </div>
                  <div class="thumbs col-3">
                    <button type="button" [disabled]="isThumbsUpDownDisabled || !orderForm.value.alarmUserDetails.mobile"
                        (click)="updateThumbsUpThumbsDownValue(orderForm.value.alarmUserDetails.mobile,'SuccessfulPayments',1)">
                      <i class="pi pi-arrow-up"></i>
                    </button>
                  </div>
                  <div class="thumbs col-3">
                    <button type="button" [disabled]="isThumbsUpDownDisabled || !orderForm.value.alarmUserDetails.mobile"
                        (click)="updateThumbsUpThumbsDownValue(orderForm.value.alarmUserDetails.mobile,'SuccessfulPayments',-1)">
                      <i class="pi pi-arrow-down"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div class="form-group col-3">
                <div class="row">&nbsp;</div>
                <div class="row no-gutters">
                  <div class="thumbs col-3"><i class="pi pi-thumbs-up"></i></div>
                  <div class="col-3">
                    <span class="disabledInput">{{getThumbsUp(orderForm.value.alarmUserDetails.mobile)}}</span>
                  </div>
                  <div class="thumbs col-3">
                    <button type="button" [disabled]="isThumbsUpDownDisabled || !orderForm.value.alarmUserDetails.mobile"
                        (click)="updateThumbsUpThumbsDownValue(orderForm.value.alarmUserDetails.mobile,'ThumbsUp',1)">
                      <i class="pi pi-arrow-up"></i>
                    </button>
                  </div>
                  <div class="thumbs col-3">
                    <button type="button" [disabled]="isThumbsUpDownDisabled || !orderForm.value.alarmUserDetails.mobile"
                        (click)="updateThumbsUpThumbsDownValue(orderForm.value.alarmUserDetails.mobile,'ThumbsUp',-1)">
                      <i class="pi pi-arrow-down"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div class="form-group col-3">
                <div class="row">&nbsp;</div>
                <div class="row no-gutters">
                  <div class="thumbs col-3"><i class="pi pi-thumbs-down"></i></div>
                  <div class="col-3">
                    <span class="disabledInput">{{getThumbsDown(orderForm.value.alarmUserDetails.mobile)}}</span>
                  </div>
                  <div class="thumbs col-3">
                    <button type="button" [disabled]="isThumbsUpDownDisabled || !orderForm.value.alarmUserDetails.mobile"
                        (click)="updateThumbsUpThumbsDownValue(orderForm.value.alarmUserDetails.mobile,'ThumbsDown',1)">
                      <i class="pi pi-arrow-up"></i>
                    </button>
                  </div>
                  <div class="thumbs col-3">
                    <button type="button" [disabled]="isThumbsUpDownDisabled || !orderForm.value.alarmUserDetails.mobile"
                        (click)="updateThumbsUpThumbsDownValue(orderForm.value.alarmUserDetails.mobile,'ThumbsDown',-1)">
                      <i class="pi pi-arrow-down"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div class="form-group col-3" >
                <label>Mobile Marketing</label>
                <p-dropdown [options]="marketingOptInSelects" formControlName="mobileMarketing" class="noAutoSave"
                (onChange)="updateAlarmUserMarketingUpdated('mobileMarketingUpdated')"></p-dropdown>
              </div>
            </div>
            <div class="form-row" formGroupName="userAddress">
              <div *ngIf="orderForm.value.alarmUserDetails.userAddress.unknown" class="form-group col-md-12 unknownAddress alert-warning">
                The address shown below is the billing address as the CSE selected "Unknown" for the Alarm User address when entering the order
              </div>
              <div class="form-group col-12" id="postcode_lookup_alarm_user">
                <input type="text" id="getaddress_input_alarm_user" autocomplete="off" [(ngModel)]="alarmUserSearchPostCode"
                  [ngModelOptions]="{standalone:true}" class="noAutoSave" placeholder="Find Postcode">
                <button type="button" id="getaddress_button_alarm_user" (click)="alarmUserAddressSearch()" [disabled]="!alarmUserSearchPostCode">
                  Find Postcode
                </button>
              </div>
              <div *ngIf="alarmUserSearchError" class="form-group col-12">
                <p class="alert alert-danger">{{alarmUserSearchError}}</p>
              </div>
              <div *ngIf="alarmUserAddressResults && (alarmUserAddressResults.length > 0)" class="form-group col-12">
                <p-dropdown [options]="alarmUserAddressResults" (onChange)="setAlarmUserAddress($event)" placeholder="--Please Select an Address--">
                </p-dropdown>
              </div>
              <div class="form-group col-12">
                <label>Address 1</label>
                <input required type="text" name="addressOne" formControlName="addressOne" id="formatted_address_0"
                    (keyup)="upperCaseField(userAddressFormGroup.get('addressOne'))" [readOnly]="!allowAlarmUserAddressManualEntry"
                    (change)="contactDetailsChanged('Alarm User Address 1', orderFormOld.alarmUserDetails.userAddress.addressOne, userAddressFormGroup.value.addressOne)">
              </div>
              <div class="form-group col-12">
                <label>Address 2</label>
                <input type="text" name="addressTwo" formControlName="addressTwo" id="locality"
                    (keyup)="upperCaseField(userAddressFormGroup.get('addressTwo'))" [readOnly]="!allowAlarmUserAddressManualEntry"
                    (change)="contactDetailsChanged('Alarm User Address 2', orderFormOld.alarmUserDetails.userAddress.addressTwo, userAddressFormGroup.value.addressTwo)">
              </div>
              <div class="form-group col-6">
                <label>City</label>
                <input required type="text" name="city" formControlName="city" id="town_or_city"
                    (keyup)="upperCaseField(userAddressFormGroup.get('city'))" [readOnly]="!allowAlarmUserAddressManualEntry"
                    (change)="contactDetailsChanged('Alarm User City', orderFormOld.alarmUserDetails.userAddress.city, userAddressFormGroup.value.city)">
              </div>
              <div class="form-group col-6">
                <label>County</label>
                <input type="text" name="county" formControlName="county" id="county"
                    (keyup)="upperCaseField(userAddressFormGroup.get('county'))" [readOnly]="!allowAlarmUserAddressManualEntry"
                    (change)="contactDetailsChanged('Alarm User County', orderFormOld.alarmUserDetails.userAddress.county, userAddressFormGroup.value.county)">
              </div>
              <div class="form-group col-3">
                <label>Postcode</label>
                <input required type="text" name="postcode" formControlName="postcode" id="postcode"
                    onkeyup="this.value = this.value.toUpperCase();" [readOnly]="!allowAlarmUserAddressManualEntry"
                    (change)="contactDetailsChanged('Alarm User Postcode', orderFormOld.alarmUserDetails.userAddress.postcode, userAddressFormGroup.value.postcode)">
              </div>
              <div class="form-group col-3">
                <label>Date Of Birth</label>
                <input type="date" class="noAutoSave" name="dob" formControlName="dob" (blur)="autosave($event)" max="{{tomorrow | date: 'yyyy-MM-dd'}}" >
              </div>
              <div class="form-group col-6">
                <label>Email ARC to notify them of Alarm/Additional User changes</label>
                <button type="button" (click)="sendContactDetailsChangedEmail()" class="btn btn-primary"
                    [disabled]="!allowContactChangesEmail || (contactDetailChanges.length === 0)">
                  Email Changes to ARC
                </button>
              </div>
            </div>
            <div class="formsect correspondence" formGroupName="correspondenceDetails">
              <div class="formbar row no-gutters">
                <div class="col-10">
                  Correspondence Details
                </div>
                <div class="col-2 text-right">
                  <button type="button" (click)="validateCorrespondenceAddressAndCopyToClipboard()" class="iconButton mr-2"
                      title="Copy Correspondence Name and Address">
                    <i class="fa fa-copy"></i>
                  </button>
                  <button (click)="isCorrespondenceMinimized = !isCorrespondenceMinimized" type="button" class="btn minMax">
                    <i *ngIf="!isCorrespondenceMinimized" class="pi pi-minus"></i>
                    <i *ngIf="isCorrespondenceMinimized" class="pi pi-plus"></i>
                  </button>
                </div>
              </div>
              <div [ngStyle]="{'display': isCorrespondenceMinimized? 'none': 'block'}">
                <div class="form-row">
                  <div class="form-group col-md-12 correspondenceNote">
                    Where set the correspondence address should be used as the address to send all equipment and letters to.
                  </div>
                  <div class="form-group col-md-6">
                    <label>First Name</label>
                    <input type="text" class="tests" name="firstName" formControlName="firstName" required>
                  </div>
                  <div class="form-group col-md-6">
                    <label>Last Name</label>
                    <input type="text" name="lastName" formControlName="lastName" required>
                  </div>
                  <div class="form-group col-md-6" >
                    <label>Email</label>
                    <input class="validated" type="email" name="email" formControlName="email">
                  </div>
                  <div class="form-group col-3" >
                    <label>Email Marketing</label>
                    <p-dropdown [options]="marketingOptInSelects" formControlName="emailMarketing" class="noAutoSave"
                      (onChange)="updateCorrespondenceMarketingUpdated('emailMarketingUpdated')"></p-dropdown>
                  </div>
                  <div class="form-group col-3" >
                    <label>Mobile Marketing</label>
                    <p-dropdown [options]="marketingOptInSelects" formControlName="mobileMarketing" class="noAutoSave"
                      (onChange)="updateCorrespondenceMarketingUpdated('mobileMarketingUpdated')"></p-dropdown>
                  </div>
                  <div class="form-group col-md-3">
                    <label>
                      <span placement="right" [openDelay]=1000 [closeDelay]=1000 [ngbTooltip]="tooltipByLabel('Order Page.Common.MobileAndTelephone')" class="crmTooltipDark">
                        Telephone
                      </span>
                    </label>
                    <input class="validated" type="text" (keypress)="phoneNumberOnly($event)" name="telephone" formControlName="telephone" pattern="[0-9]+" >
                  </div>
                  <div class="form-group col-md-3">
                    <div class="row">&nbsp;</div>
                    <div class="row no-gutters">
                      <div class="pound col-3">&pound;</div>
                      <div class="col-3">
                        <span class="disabledInput">{{getPaymentSuccesses(orderForm.value.alarmUserDetails.correspondenceDetails.telephone)}}</span>
                      </div>
                      <div class="thumbs col-3">
                        <button type="button" [disabled]="isThumbsUpDownDisabled || !orderForm.value.alarmUserDetails.correspondenceDetails.telephone"
                            (click)="updateThumbsUpThumbsDownValue(orderForm.value.alarmUserDetails.correspondenceDetails.telephone,'SuccessfulPayments',1)">
                          <i class="pi pi-arrow-up"></i>
                        </button>
                      </div>
                      <div class="thumbs col-3">
                        <button type="button" [disabled]="isThumbsUpDownDisabled || !orderForm.value.alarmUserDetails.correspondenceDetails.telephone"
                            (click)="updateThumbsUpThumbsDownValue(orderForm.value.alarmUserDetails.correspondenceDetails.telephone,'SuccessfulPayments',-1)">
                          <i class="pi pi-arrow-down"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-md-3">
                    <div class="row">&nbsp;</div>
                    <div class="row no-gutters">
                      <div class="thumbs col-3"><i class="pi pi-thumbs-up"></i></div>
                      <div class="col-3">
                        <span class="disabledInput">{{getThumbsUp(orderForm.value.alarmUserDetails.correspondenceDetails.telephone)}}</span>
                      </div>
                      <div class="thumbs col-3">
                        <button type="button" [disabled]="isThumbsUpDownDisabled || !orderForm.value.alarmUserDetails.correspondenceDetails.telephone"
                            (click)="updateThumbsUpThumbsDownValue(orderForm.value.alarmUserDetails.correspondenceDetails.telephone,'ThumbsUp',1)">
                          <i class="pi pi-arrow-up"></i>
                        </button>
                      </div>
                      <div class="thumbs col-3">
                        <button type="button" [disabled]="isThumbsUpDownDisabled || !orderForm.value.alarmUserDetails.correspondenceDetails.telephone"
                            (click)="updateThumbsUpThumbsDownValue(orderForm.value.alarmUserDetails.correspondenceDetails.telephone,'ThumbsUp',-1)">
                          <i class="pi pi-arrow-down"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-md-3">
                    <div class="row">&nbsp;</div>
                    <div class="row no-gutters">
                      <div class="thumbs col-3"><i class="pi pi-thumbs-down"></i></div>
                      <div class="col-3">
                        <span class="disabledInput">{{getThumbsDown(orderForm.value.alarmUserDetails.correspondenceDetails.telephone)}}</span>
                      </div>
                      <div class="thumbs col-3">
                        <button type="button" [disabled]="isThumbsUpDownDisabled || !orderForm.value.alarmUserDetails.correspondenceDetails.telephone"
                            (click)="updateThumbsUpThumbsDownValue(orderForm.value.alarmUserDetails.correspondenceDetails.telephone,'ThumbsDown',1)">
                          <i class="pi pi-arrow-up"></i>
                        </button>
                      </div>
                      <div class="thumbs col-3">
                        <button type="button" [disabled]="isThumbsUpDownDisabled || !orderForm.value.alarmUserDetails.correspondenceDetails.telephone"
                            (click)="updateThumbsUpThumbsDownValue(orderForm.value.alarmUserDetails.correspondenceDetails.telephone,'ThumbsDown',-1)">
                          <i class="pi pi-arrow-down"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-md-3">
                    <label>
                      <span placement="right" [openDelay]=1000 [closeDelay]=1000 [ngbTooltip]="tooltipByLabel('Order Page.Common.MobileAndTelephone')" class="crmTooltipDark">
                        Mobile
                      </span>
                    </label>
                    <input class="validated" type="text" (keypress)="phoneNumberOnly($event)" name="mobile" formControlName="mobile" pattern="[0-9]+">
                  </div>
                  <div class="form-group col-md-3">
                    <div class="row">&nbsp;</div>
                    <div class="row no-gutters">
                      <div class="pound col-3">&pound;</div>
                      <div class="col-3">
                        <span class="disabledInput">{{getPaymentSuccesses(orderForm.value.alarmUserDetails.correspondenceDetails.mobile)}}</span>
                      </div>
                      <div class="thumbs col-3">
                        <button type="button" [disabled]="isThumbsUpDownDisabled || !orderForm.value.alarmUserDetails.correspondenceDetails.mobile"
                            (click)="updateThumbsUpThumbsDownValue(orderForm.value.alarmUserDetails.correspondenceDetails.mobile,'SuccessfulPayments',1)">
                          <i class="pi pi-arrow-up"></i>
                        </button>
                      </div>
                      <div class="thumbs col-3">
                        <button type="button" [disabled]="isThumbsUpDownDisabled || !orderForm.value.alarmUserDetails.correspondenceDetails.mobile"
                            (click)="updateThumbsUpThumbsDownValue(orderForm.value.alarmUserDetails.correspondenceDetails.mobile,'SuccessfulPayments',-1)">
                          <i class="pi pi-arrow-down"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-md-3">
                    <div class="row">&nbsp;</div>
                    <div class="row no-gutters">
                      <div class="thumbs col-3"><i class="pi pi-thumbs-up"></i></div>
                      <div class="col-3">
                        <span class="disabledInput">{{getThumbsUp(orderForm.value.alarmUserDetails.correspondenceDetails.mobile)}}</span>
                      </div>
                      <div class="thumbs col-3">
                        <button type="button" [disabled]="isThumbsUpDownDisabled || !orderForm.value.alarmUserDetails.correspondenceDetails.mobile"
                            (click)="updateThumbsUpThumbsDownValue(orderForm.value.alarmUserDetails.correspondenceDetails.mobile,'ThumbsUp',1)">
                          <i class="pi pi-arrow-up"></i>
                        </button>
                      </div>
                      <div class="thumbs col-3">
                        <button type="button" [disabled]="isThumbsUpDownDisabled || !orderForm.value.alarmUserDetails.correspondenceDetails.mobile"
                            (click)="updateThumbsUpThumbsDownValue(orderForm.value.alarmUserDetails.correspondenceDetails.mobile,'ThumbsUp',-1)">
                          <i class="pi pi-arrow-down"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-md-3">
                    <div class="row">&nbsp;</div>
                    <div class="row no-gutters">
                      <div class="thumbs col-3"><i class="pi pi-thumbs-down"></i></div>
                      <div class="col-3">
                        <span class="disabledInput">{{getThumbsDown(orderForm.value.alarmUserDetails.correspondenceDetails.mobile)}}</span>
                      </div>
                      <div class="thumbs col-3">
                        <button type="button" [disabled]="isThumbsUpDownDisabled || !orderForm.value.alarmUserDetails.correspondenceDetails.mobile"
                            (click)="updateThumbsUpThumbsDownValue(orderForm.value.alarmUserDetails.correspondenceDetails.mobile,'ThumbsDown',1)">
                          <i class="pi pi-arrow-up"></i>
                        </button>
                      </div>
                      <div class="thumbs col-3">
                        <button type="button" [disabled]="isThumbsUpDownDisabled || !orderForm.value.alarmUserDetails.correspondenceDetails.mobile"
                            (click)="updateThumbsUpThumbsDownValue(orderForm.value.alarmUserDetails.correspondenceDetails.mobile,'ThumbsDown',-1)">
                          <i class="pi pi-arrow-down"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-12" id="postcode_lookup_correspondence">
                    <input type="text" id="getaddress_input_correspondence" autocomplete="off" [(ngModel)]="correspondenceSearchPostCode"
                      [ngModelOptions]="{standalone:true}" class="noAutoSave" placeholder="Find Postcode">
                    <button type="button" id="getaddress_button_correspondence" (click)="correspondenceAddressSearch()" [disabled]="!correspondenceSearchPostCode">
                      Find Postcode
                    </button>
                  </div>
                  <div *ngIf="correspondenceSearchError" class="form-group col-12">
                    <p class="alert alert-danger">{{correspondenceSearchError}}</p>
                  </div>
                  <div *ngIf="correspondenceAddressResults && (correspondenceAddressResults.length > 0)" class="form-group col-12">
                    <p-dropdown [options]="correspondenceAddressResults" (onChange)="setCorrespondenceAddress($event)" placeholder="--Please Select an Address--">
                    </p-dropdown>
                  </div>
                  <div class="form-group col-md-12">
                    <label>Address 1</label>
                    <input type="text" name="addressOne" formControlName="addressOne" [readOnly]="!allowCorrespondenceAddressManualEntry"
                      (keyup)="upperCaseField(correspondenceAddressFormGroup.get('addressOne'))" id="corrAddressOne">
                  </div>
                  <div class="form-group col-md-12">
                    <label>Address 2</label>
                    <input type="text" name="addressTwo" formControlName="addressTwo" [readOnly]="!allowCorrespondenceAddressManualEntry"
                      (keyup)="upperCaseField(correspondenceAddressFormGroup.get('addressTwo'))" id="corrAddressTwo">
                  </div>
                  <div class="form-group col-md-4">
                    <label>City</label>
                    <input type="text" name="city" formControlName="city" [readOnly]="!allowCorrespondenceAddressManualEntry"
                      (keyup)="upperCaseField(correspondenceAddressFormGroup.get('city'))" id="corrCity">
                  </div>
                  <div class="form-group col-md-4">
                    <label>County</label>
                    <input type="text" name="county" formControlName="county" [readOnly]="!allowCorrespondenceAddressManualEntry"
                      (keyup)="upperCaseField(correspondenceAddressFormGroup.get('county'))" id="corrCounty">
                  </div>
                  <div class="form-group col-md-4">
                    <label>Postcode</label>
                    <input type="text" name="postcode" formControlName="postcode" onkeyup="this.value = this.value.toUpperCase();"
                      [readOnly]="!allowCorrespondenceAddressManualEntry" id="corrPostcode">
                  </div>
                </div>
              </div>
            </div>
            <hr>
            <div id="additional" class="formsect" formGroupName="users">
              <div class="form-row">
                <div *ngFor="let user of usersForms.controls; let userIndex=index"
                    class="form-row os-act" style="width: 100%;margin-right: 0;margin-left: 0;" [formGroupName]="userIndex">
                  <div class="primaryContact" [ngClass]="{'selected': user.value.primaryContact}">
                    <span class="pcLabel" placement="right" [openDelay]=1000 [closeDelay]=1000
                        [ngbTooltip]="tooltipByLabel('Order Page.Alarm User Details.Primary Contact')">
                      <span class="crmTooltipLight">Primary Contact</span>
                    </span>
                    <label class="switch">
                      <input type="checkbox" class="noAutoSave" (change)="primaryContactChange($event, 'AlarmUser', userIndex)" formControlName="primaryContact">
                      <span class="slider"></span>
                    </label>
                    <div *ngIf="user.value.primaryContact" class="selectedPCLabel">
                      *** PRIMARY CONTACT ***
                    </div>
                  </div>
                  <div class="doNotCall" [ngClass]="{'selected': user.value.doNotCall}">
                    <span class="dncLabel">Do Not Call</span>
                    <label class="switch">
                      <input type="checkbox" formControlName="doNotCall" >
                      <span class="slider"></span>
                    </label>
                    <div *ngIf="user.value.doNotCall" class="selectedDNCLabel">
                      *** DO NOT CALL ***
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group col-3">
                      <label>First Name</label>
                      <input type="text" name="firstName" formControlName="firstName"
                          (keyup)="upperCaseField(usersForms.at(userIndex).get('firstName'))"
                          (change)="contactDetailsChanged('Additional User ' + (userIndex+1) + ' First Name', orderFormOld.alarmUserDetails.users[userIndex].firstName, usersForms.value[userIndex].firstName)">
                    </div>
                    <div class="form-group col-3">
                      <label>Other Names</label>
                      <input type="text" name="otherName" formControlName="otherName"
                          (keyup)="upperCaseField(usersForms.at(userIndex).get('otherName'))"
                          (change)="contactDetailsChanged('Additional User ' + (userIndex+1) + ' Other Name', orderFormOld.alarmUserDetails.users[userIndex].otherName, usersForms.value[userIndex].otherName)">
                    </div>
                    <div class="form-group col-3">
                      <label>Last Name</label>
                      <input type="text" name="lastName" formControlName="lastName"
                          (keyup)="upperCaseField(usersForms.at(userIndex).get('lastName'))"
                          (change)="contactDetailsChanged('Additional User ' + (userIndex+1) + ' Last Name', orderFormOld.alarmUserDetails.users[userIndex].lastName, usersForms.value[userIndex].lastName)">
                    </div>
                    <div class="form-group col-3">
                      <label>Preferred Name</label>
                      <input type="text" name="preferredName" formControlName="preferredName"
                          (keyup)="upperCaseField(usersForms.at(userIndex).get('preferredName'))"
                          (change)="contactDetailsChanged('Additional User ' + (userIndex+1) + ' Preferred Name', orderFormOld.alarmUserDetails.users[userIndex].preferredName, usersForms.value[userIndex].preferredName)">
                    </div>
                    <div class="form-group col-6">
                      <label>Email</label>
                      <input class="validated" type="email" name="email" formControlName="email">
                    </div>
                    <div class="form-group col-3" >
                      <label>Email Marketing</label>
                      <p-dropdown [options]="marketingOptInSelects" formControlName="emailMarketing" class="noAutoSave"
                        (onChange)="updateUserMarketingUpdated(userIndex, 'emailMarketingUpdated')"></p-dropdown>
                    </div>
                    <div class="form-group col-3" >
                      <label>Mobile Marketing</label>
                      <p-dropdown [options]="marketingOptInSelects" formControlName="mobileMarketing" class="noAutoSave"
                        (onChange)="updateUserMarketingUpdated(userIndex, 'mobileMarketingUpdated')"></p-dropdown>
                    </div>
                    <div class="form-group col-3">
                      <label>
                        <span placement="right" [openDelay]=1000 [closeDelay]=1000 [ngbTooltip]="tooltipByLabel('Order Page.Common.MobileAndTelephone')" class="crmTooltipDark">
                          Mobile
                        </span>
                      </label>
                      <input type="text" class="validated" (keypress)="phoneNumberOnly($event)" name="mobile" formControlName="mobile"
                          (change)="contactDetailsChanged('Additional User ' + (userIndex+1) + ' Mobile', orderFormOld.alarmUserDetails.users[userIndex].mobile, usersForms.value[userIndex].mobile)">
                    </div>
                    <div class="form-group col-3">
                      <div class="row">&nbsp;</div>
                      <div class="row no-gutters">
                        <div class="pound col-3">&pound;</div>
                        <div class="col-3">
                          <span class="disabledInput">{{getPaymentSuccesses(user.value.mobile)}}</span>
                        </div>
                        <div class="thumbs col-3">
                          <button type="button" [disabled]="isThumbsUpDownDisabled || !user.value.mobile"
                              (click)="updateThumbsUpThumbsDownValue(user.value.mobile,'SuccessfulPayments',1)">
                            <i class="pi pi-arrow-up"></i>
                          </button>
                        </div>
                        <div class="thumbs col-3">
                          <button type="button" [disabled]="isThumbsUpDownDisabled || !user.value.mobile"
                              (click)="updateThumbsUpThumbsDownValue(user.value.mobile,'SuccessfulPayments',-1)">
                            <i class="pi pi-arrow-down"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="form-group col-3">
                      <div class="row">&nbsp;</div>
                      <div class="row no-gutters">
                        <div class="thumbs col-3"><i class="pi pi-thumbs-up"></i></div>
                        <div class="col-3">
                          <span class="disabledInput">{{getThumbsUp(user.value.mobile)}}</span>
                        </div>
                        <div class="thumbs col-3">
                          <button type="button" [disabled]="isThumbsUpDownDisabled || !user.value.mobile"
                              (click)="updateThumbsUpThumbsDownValue(user.value.mobile,'ThumbsUp',1)">
                            <i class="pi pi-arrow-up"></i>
                          </button>
                        </div>
                        <div class="thumbs col-3">
                          <button type="button" [disabled]="isThumbsUpDownDisabled || !user.value.mobile"
                              (click)="updateThumbsUpThumbsDownValue(user.value.mobile,'ThumbsUp',-1)">
                            <i class="pi pi-arrow-down"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="form-group col-3">
                      <div class="row">&nbsp;</div>
                      <div class="row no-gutters">
                        <div class="thumbs col-3"><i class="pi pi-thumbs-down"></i></div>
                        <div class="col-3">
                          <span class="disabledInput">{{getThumbsDown(user.value.mobile)}}</span>
                        </div>
                        <div class="thumbs col-3">
                          <button type="button" [disabled]="isThumbsUpDownDisabled || !user.value.mobile"
                              (click)="updateThumbsUpThumbsDownValue(user.value.mobile,'ThumbsDown',1)">
                            <i class="pi pi-arrow-up"></i>
                          </button>
                        </div>
                        <div class="thumbs col-3">
                          <button type="button" [disabled]="isThumbsUpDownDisabled || !user.value.mobile"
                              (click)="updateThumbsUpThumbsDownValue(user.value.mobile,'ThumbsDown',-1)">
                            <i class="pi pi-arrow-down"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="form-group col-6">
                      <label>Relationship</label>
                      <input type="text" name="relationship" formControlName="relationship"
                        (keyup)="upperCaseField(usersForms.at(userIndex).get('relationship'))">
                    </div>
                    <div class="form-group col-5"></div>
                    <div class="form-group col-1 user-bin" (click)="confirmdeleteUser(userIndex)">
                      <label></label>
                      <button class="bg-danger" type="button" class="btn" title="Delete User">
                        <i class="fas fa-trash"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row cust-row">
              <div class="form-group col-6"> </div>
              <div class="form-group col-6" style="text-align: right;padding: 0;">
                <button type="button" (click)="addusers()" class="btn btn-primary" title="Add User">
                  Add additional user <i class="fas fa-plus"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-template>
    <!-- End alarm User Details-->
    <!-- Start Account Contacts-->
    <ng-template #accountContactsSection let-pageSection="pageSection">
      <ng-container *ngIf="! loading" formArrayName="accountContacts">
        <div class="formbar row no-gutters">
          <div class="col-12">
            Account Contacts
            <button (click)="minimizeSection(pageSection)" type="button" class="btn minMax">
              <i *ngIf="!pageSection.minimized" class="pi pi-minus"></i>
              <i *ngIf="pageSection.minimized" class="pi pi-plus"></i>
            </button>
          </div>
        </div>
        <div [ngStyle]="{'display': pageSection.minimized? 'none': 'block'}">
          <div class="contactbox" *ngFor="let accountContact of accountContactsForms.controls; let contactIndex=index" [formGroupName]="contactIndex">
            <div class="formbar">
              <span class="title-h"> Contact No. {{contactIndex+1}}</span>
              <span class="form-h">
                <div class="check-container">
                  <button type="button" *ngIf="accountContactsForms.controls.length > 1" name="deleteContact" class="btn btn-primary"
                      (click)="deleteAccountContact(contactIndex)">
                    Delete
                  </button>
                  <button name="sameAsUser" type="button" (click)="confirmContactsAsUser(contactIndex)" class="btn btn-primary">Same as User</button>
                </div>
              </span>
            </div>
            <div class="primaryContact" [ngClass]="{'selected': accountContact.value.primaryContact}">
              <span class="pcLabel" placement="right" [openDelay]=1000 [closeDelay]=1000
                  [ngbTooltip]="tooltipByLabel('Order Page.Account Contacts.Primary Contact')">
                <span class="crmTooltipLight">Primary Contact</span>
              </span>
              <label class="switch">
                <input type="checkbox" class="noAutoSave" (change)="primaryContactChange($event, 'AccountContact', contactIndex)" formControlName="primaryContact">
                <span class="slider"></span>
              </label>
              <div *ngIf="accountContact.value.primaryContact" class="selectedPCLabel">
                *** PRIMARY CONTACT ***
              </div>
            </div>
            <div class="doNotCall" [ngClass]="{'selected': accountContact.value.doNotCall}">
              <span class="dncLabel">Do Not Call</span>
              <label class="switch">
                <input type="checkbox" formControlName="doNotCall" >
                <span class="slider"></span>
              </label>
              <div *ngIf="accountContact.value.doNotCall" class="selectedDNCLabel">
                *** DO NOT CALL ***
              </div>
            </div>
            <div class="form-row formsect">
              <div class="form-group col-4">
                <label>First Name</label>
                <input type="text" name="accFirstName" formControlName="accFirstName"
                  (keyup)="upperCaseField(accountContactsForms.at(contactIndex).get('accFirstName'))">
              </div>
              <div class="form-group col-4">
                <label>Other Names</label>
                <input type="text" name="accOtherName" formControlName="accOtherName"
                  (keyup)="upperCaseField(accountContactsForms.at(contactIndex).get('accOtherName'))">
              </div>
              <div class="form-group col-4">
                <label>Last Name</label>
                <input type="text" name="accLastName" formControlName="accLastName"
                  (keyup)="upperCaseField(accountContactsForms.at(contactIndex).get('accLastName'))">
              </div>
              <div class="form-group col-6">
                <label>Email</label>
                <input type="text" name="accEmail" formControlName="accEmail" class="validated">
              </div>
              <div class="form-group col-3" >
                <label>Email Marketing</label>
                <p-dropdown [options]="marketingOptInSelects" formControlName="emailMarketing" class="noAutoSave"
                  (onChange)="updateContactMarketingUpdated(contactIndex, 'emailMarketingUpdated')"></p-dropdown>
              </div>
              <div class="form-group col-3" >
                <label>Mobile Marketing</label>
                <p-dropdown [options]="marketingOptInSelects" formControlName="mobileMarketing" class="noAutoSave"
                  (onChange)="updateContactMarketingUpdated(contactIndex, 'mobileMarketingUpdated')"></p-dropdown>
              </div>
              <div class="form-group col-3">
                <label>
                  <span placement="right" [openDelay]=1000 [closeDelay]=1000 [ngbTooltip]="tooltipByLabel('Order Page.Common.MobileAndTelephone')" class="crmTooltipDark">
                    Telephone
                  </span>
                </label>
                <input class="validated" type="text" (keypress)="phoneNumberOnly($event)" name="accTelephone" formControlName="accTelephone">
              </div>
              <div class="form-group col-3">
                <div class="row">&nbsp;</div>
                <div class="row no-gutters">
                  <div class="pound col-3">&pound;</div>
                  <div class="col-3">
                    <span class="disabledInput">{{getPaymentSuccesses(accountContact.value.accTelephone)}}</span>
                  </div>
                  <div class="thumbs col-3">
                    <button type="button" [disabled]="isThumbsUpDownDisabled || !accountContact.value.accTelephone"
                        (click)="updateThumbsUpThumbsDownValue(accountContact.value.accTelephone,'SuccessfulPayments',1)">
                      <i class="pi pi-arrow-up"></i>
                    </button>
                  </div>
                  <div class="thumbs col-3">
                    <button type="button" [disabled]="isThumbsUpDownDisabled || !accountContact.value.accTelephone"
                        (click)="updateThumbsUpThumbsDownValue(accountContact.value.accTelephone,'SuccessfulPayments',-1)">
                      <i class="pi pi-arrow-down"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div class="form-group col-3">
                <div class="row">&nbsp;</div>
                <div class="row no-gutters">
                  <div class="thumbs col-3"><i class="pi pi-thumbs-up"></i></div>
                  <div class="col-3">
                    <span class="disabledInput">{{getThumbsUp(accountContact.value.accTelephone)}}</span>
                  </div>
                  <div class="thumbs col-3">
                    <button type="button" [disabled]="isThumbsUpDownDisabled || !accountContact.value.accTelephone"
                        (click)="updateThumbsUpThumbsDownValue(accountContact.value.accTelephone,'ThumbsUp',1)">
                      <i class="pi pi-arrow-up"></i>
                    </button>
                  </div>
                  <div class="thumbs col-3">
                    <button type="button" [disabled]="isThumbsUpDownDisabled || !accountContact.value.accTelephone"
                        (click)="updateThumbsUpThumbsDownValue(accountContact.value.accTelephone,'ThumbsUp',-1)">
                      <i class="pi pi-arrow-down"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div class="form-group col-3">
                <div class="row">&nbsp;</div>
                <div class="row no-gutters">
                  <div class="thumbs col-3"><i class="pi pi-thumbs-down"></i></div>
                  <div class="col-3">
                    <span class="disabledInput">{{getThumbsDown(accountContact.value.accTelephone)}}</span>
                  </div>
                  <div class="thumbs col-3">
                    <button type="button" [disabled]="isThumbsUpDownDisabled || !accountContact.value.accTelephone"
                        (click)="updateThumbsUpThumbsDownValue(accountContact.value.accTelephone,'ThumbsDown',1)">
                      <i class="pi pi-arrow-up"></i>
                    </button>
                  </div>
                  <div class="thumbs col-3">
                    <button type="button" [disabled]="isThumbsUpDownDisabled || !accountContact.value.accTelephone"
                        (click)="updateThumbsUpThumbsDownValue(accountContact.value.accTelephone,'ThumbsDown',-1)">
                      <i class="pi pi-arrow-down"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div class="form-group col-3">
                <label>
                    <span placement="right" [openDelay]=1000 [closeDelay]=1000 [ngbTooltip]="tooltipByLabel('Order Page.Common.MobileAndTelephone')" class="crmTooltipDark">
                      Mobile
                    </span>
                </label>
                <input class="validated" type="text" (keypress)="phoneNumberOnly($event)" name="accMobile" formControlName="accMobile">
              </div>
              <div class="form-group col-3">
                <div class="row">&nbsp;</div>
                <div class="row no-gutters">
                  <div class="pound col-3">&pound;</div>
                  <div class="col-3">
                    <span class="disabledInput">{{getPaymentSuccesses(accountContact.value.accMobile)}}</span>
                  </div>
                  <div class="thumbs col-3">
                    <button type="button" [disabled]="isThumbsUpDownDisabled || !accountContact.value.accMobile"
                        (click)="updateThumbsUpThumbsDownValue(accountContact.value.accMobile,'SuccessfulPayments',1)">
                      <i class="pi pi-arrow-up"></i>
                    </button>
                  </div>
                  <div class="thumbs col-3">
                    <button type="button" [disabled]="isThumbsUpDownDisabled || !accountContact.value.accMobile"
                        (click)="updateThumbsUpThumbsDownValue(accountContact.value.accMobile,'SuccessfulPayments',-1)">
                      <i class="pi pi-arrow-down"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div class="form-group col-3">
                <div class="row">&nbsp;</div>
                <div class="row no-gutters">
                  <div class="thumbs col-3"><i class="pi pi-thumbs-up"></i></div>
                  <div class="col-3">
                    <span class="disabledInput">{{getThumbsUp(accountContact.value.accMobile)}}</span>
                  </div>
                  <div class="thumbs col-3">
                    <button type="button" [disabled]="isThumbsUpDownDisabled || !accountContact.value.accMobile"
                        (click)="updateThumbsUpThumbsDownValue(accountContact.value.accMobile,'ThumbsUp',1)">
                      <i class="pi pi-arrow-up"></i>
                    </button>
                  </div>
                  <div class="thumbs col-3">
                    <button type="button" [disabled]="isThumbsUpDownDisabled || !accountContact.value.accMobile"
                        (click)="updateThumbsUpThumbsDownValue(accountContact.value.accMobile,'ThumbsUp',-1)">
                      <i class="pi pi-arrow-down"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div class="form-group col-3">
                <div class="row">&nbsp;</div>
                <div class="row no-gutters">
                  <div class="thumbs col-3"><i class="pi pi-thumbs-down"></i></div>
                  <div class="col-3">
                    <span class="disabledInput">{{getThumbsDown(accountContact.value.accMobile)}}</span>
                  </div>
                  <div class="thumbs col-3">
                    <button type="button" [disabled]="isThumbsUpDownDisabled || !accountContact.value.accMobile"
                        (click)="updateThumbsUpThumbsDownValue(accountContact.value.accMobile,'ThumbsDown',1)">
                      <i class="pi pi-arrow-up"></i>
                    </button>
                  </div>
                  <div class="thumbs col-3">
                    <button type="button" [disabled]="isThumbsUpDownDisabled || !accountContact.value.accMobile"
                        (click)="updateThumbsUpThumbsDownValue(accountContact.value.accMobile,'ThumbsDown',-1)">
                      <i class="pi pi-arrow-down"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div class="form-group col-6">
                <label>Relationship</label>
                <input type="text" name="accRelationship" formControlName="accRelationship"
                  (keyup)="upperCaseField(accountContactsForms.at(contactIndex).get('accRelationship'))">
              </div>
            </div>
          </div>
          <div class="formbar addcontactbutton">
            <span class="title-h"> </span>
            <span class="form-h">
              <div class="check-container ">
                <button type="button" name="addContact" (click)="addAccountContacts()" class="btn btn-primary">Add contact</button>
              </div>
            </span>
          </div>
        </div>
      </ng-container>
    </ng-template>
    <!-- End Account Contacts-->

    <ng-template #accountDetailsSection let-pageSection="pageSection">
      <!-- Start Account Details-->
      <ng-container *ngIf="! loading">
        <ng-container formGroupName="accountDetails">
          <div class="formbar row no-gutters">
            <div class="col-12">
              Account Details
              <span class="form-h addProduct">
                <div class="check-container">
                  <button name="priceBook" type="button" (click)="openPriceBook()" class="btn btn-primary"
                      [disabled]='(allHardwareSets.length === 0) && (allHardware.length === 0)'>
                    Price Book
                  </button>
                  <button name="addProduct" type="button" (click)="addProduct()" class="btn btn-primary">Add Product</button>
                </div>
              </span>
              <button (click)="minimizeSection(pageSection)" type="button" class="btn minMax">
                <i *ngIf="!pageSection.minimized" class="pi pi-minus"></i>
                <i *ngIf="pageSection.minimized" class="pi pi-plus"></i>
              </button>
            </div>
          </div>
          <div class="formsect accdetail">
            <div [ngStyle]="{'display': pageSection.minimized? 'none': 'block'}">
              <div class="form-row">
                <div class="form-group col-6">
                  <label>Alarm Plan</label>
                  <input class="noAutoSave" type="text" name="plan" formControlName="plan" required (blur)="discountReview(true)">
                </div>
                <div class="form-group col-6">
                  <label>VAT Status</label>
                  <select formControlName="vat" required (change)="discountReview(false)">
                    <option disabled selected value></option>
                    <option *ngFor="let vatStatus of vatStatuses" [value]="vatStatus.value">
                      {{vatStatus.label}}
                    </option>
                  </select>
                </div>
                <div class="form-group col-6">
                  <label>Plan Type</label>
                  <select class="noAutoSave" name="planType" formControlName="planType" (change)="onPlanTypeChange()" required>
                    <option selected value></option>
                    <option value="monthly">Monthly</option>
                    <option value="quarterly">Quarterly</option>
                    <option value="annual">Annual</option>
                    <option value="lifetime">Lifetime</option>
                  </select>
                </div>
                <div class="form-group col-6">
                  <label>Monitoring</label>
                  <select name="monitoring" formControlName="monitoring">
                    <option disabled selected value></option>
                    <option *ngFor="let monitoringOption of monitoringOptions" [value]="monitoringOption.value">
                      {{monitoringOption.label}}
                    </option>
                  </select>
                </div>
              </div>
              <ng-container *ngTemplateOutlet="billingAccountLinks"></ng-container>
              <div class="form-row">
                <div class="form-group col-5">
                  <label for="stripeCustomerId">Stripe Customer Id</label>
                  <input type="text" name="stripeCustomerId" id="stripeCustomerId" formControlName="stripeCustomerId"
                    class="noAutoSave validated" (blur)="checkStripeCustomerId()">
                </div>
                <div class="form-group col-1">
                  <label></label>
                  <a href="https://dashboard.stripe.com{{environment.production? '': '/test'}}/customers/{{order.accountDetails.stripeCustomerId}}"
                      target="_blank"
                    *ngIf="order.accountDetails.stripeCustomerId; else noStripeCustLink" title="Open Customer on Stripe">
                    <i class="pi pi-external-link"></i>
                  </a>
                  <ng-template #noStripeCustLink>
                    <div title="Open Customer on Stripe" class="mt-1">
                      <i class="pi pi-external-link disabledLink"></i>
                    </div>
                  </ng-template>
                </div>
                <div class="form-group col-5">
                  <label for="stripeSubscriptionId">Stripe Subscription Id</label>
                  <input type="text" name="stripeSubscriptionId" id="stripeSubscriptionId" formControlName="stripeSubscriptionId"
                    class="noAutoSave validated" (blur)="checkStripeSubscriptionId()">
                </div>
                <div class="form-group col-1">
                  <label></label>
                  <a href="https://dashboard.stripe.com{{environment.production? '': '/test'}}/subscriptions/{{order.accountDetails.stripeSubscriptionId}}"
                      target="_blank" *ngIf="order.accountDetails.stripeSubscriptionId; else noStripeSubLink" title="Open Subscription on Stripe">
                    <i class="pi pi-external-link"></i>
                  </a>
                  <ng-template #noStripeSubLink>
                    <div title="Open Subscription on Stripe" class="mt-1">
                      <i class="pi pi-external-link disabledLink"></i>
                    </div>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <div class="formsect my-0 py-0" [ngStyle]="{'display': pageSection.minimized? 'none': 'block'}">
          <div class="form-row">
            <div class="form-group col-6">
              <label for="pslid">SmartDebit Service User</label>
              <select name="pslid" id="pslid" formControlName="pslid" (change)="smartDebitServiceUserChange($event)">
                <option value=""></option>
                <option *ngFor="let smartDebitAccount of smartDebitAccounts" [value]="smartDebitAccount.value">
                  {{smartDebitAccount.label}}
                </option>
              </select>
            </div>
            <div class="form-group col-5">
              <label for="referencenumber">SmartDebit Bacs Reference</label>
              <input type="text" name="referencenumber" id="referencenumber" formControlName="referencenumber" class="noAutoSave" (blur)="checkBacsReference()">
            </div>
            <div class="form-group col-1">
              <label></label>
              <a href="https://{{environment.production? 'online': 'sandbox'}}.smartdebit.co.uk/Payers/Details?Pslid={{order.pslid}}&ReferenceNumber={{order.referencenumber}}"
                  target="_blank" *ngIf="order.pslid && order.referencenumber; else noSmartDebitLink" title="Open Payer on SmartDebit">
                <i class="pi pi-external-link"></i>
              </a>
              <ng-template #noSmartDebitLink>
                <div title="Open Payer on SmartDebit" class="mt-1">
                  <i class="pi pi-external-link disabledLink"></i>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
        <div class="formsect my-0 py-0" [ngStyle]="{'display': pageSection.minimized? 'none': 'block'}">
          <div class="form-row">
            <div class="form-group col-5">
              <label for="goCardlessId">GoCardless Customer Id</label>
              <input type="text" name="goCardlessId" id="goCardlessId" formControlName="goCardlessId">
            </div>
            <div class="form-group col-1">
              <label></label>
              <a href="https://{{environment.production? 'manage': 'manage-sandbox'}}.gocardless.com/customers/{{order.goCardlessId}}"
                  target="_blank" *ngIf="order.goCardlessId; else noGoCardlessCustLink" title="Open Customer on GoCardless">
                <i class="pi pi-external-link"></i>
              </a>
              <ng-template #noGoCardlessCustLink>
                <div title="Open Customer on GoCardless" class="mt-1">
                  <i class="pi pi-external-link disabledLink"></i>
                </div>
              </ng-template>
            </div>
            <div class="form-group col-5">
              <label for="goCardlessSubId">GoCardless Subscription Id</label>
              <input type="text" name="goCardlessSubId" id="goCardlessSubId" formControlName="goCardlessSubId" class="noAutoSave validated"
                (blur)="checkGoCardlessSubId()">
            </div>
            <div class="form-group col-1">
              <label></label>
              <a href="https://{{environment.production? 'manage': 'manage-sandbox'}}.gocardless.com/subscriptions/{{order.goCardlessSubId}}"
                  target="_blank" *ngIf="order.goCardlessSubId; else noGoCardlessSubLink" title="Open Subscription on GoCardless">
                <i class="pi pi-external-link"></i>
              </a>
              <ng-template #noGoCardlessSubLink>
                <div title="Open Subscription on GoCardless" class="mt-1">
                  <i class="pi pi-external-link disabledLink"></i>
                </div>
              </ng-template>
            </div>
            <div class='form-group col-3 offset-9'>
              <button name="paymentDetails" type="button" (click)="openPaymentDataDialog()" class="btn btn-primary">
                View Payment Details
              </button>
            </div>
          </div>
        </div>
        <div class="formsect my-0 py-0" [ngStyle]="{'display': pageSection.minimized? 'none': 'block'}">
          <div class="form-row">
            <div class="form-group col-md-6">
              <div>
                <label>Alarm Codes (one per line, must start SC/TD/HP/GY)</label>
                <textarea [(ngModel)]="jontekCodesString" rows="3" [ngModelOptions]="{standalone:true}"
                  class="noAutoSave" (blur)="checkJontekCodes()">
                </textarea>
              </div>
              <div>
                <label>Old Alarm Codes (automatically updated as active codes removed)</label>
                <textarea [(ngModel)]="oldAlarmCodesString" rows="3" [ngModelOptions]="{standalone:true}" class="noAutoSave" [readonly]="!userCanEditOldAlarmCodes()"
                    (blur)="updateOldJontekCodes()">
                </textarea>
              </div>
            </div>
            <div class="form-group col-md-6">
              <label>EVO Device Links</label>
              <div class="form-row" *ngFor="let evoDevice of evoDeviceIds; let evoDeviceIndex=index">
                <div class="col-10">
                  <input type="text" name="evoDeviceIdent" value="{{evoDevice.dataUsedToMatchInternal}}" readonly>
                </div>
                <div class="col-2">
                  <a href="{{environment.evoBaseUrl}}&pagetype=entityrecord&etn=sbr_device&id={{evoDevice.idValue}}" target="_blank" title="Open Device Details on EVO">
                    <i class="pi pi-external-link"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- End Account Details-->
        <!-- Start Active Equipment -->
        <div class="formsect aplans" formArrayName="plans" [ngStyle]="{'display': pageSection.minimized? 'none': 'block'}">
          <div class="subsectionHeading">Active Equipment</div>
          <ng-template #planFirstItemTemplate let-planFirstItem="plan" let-planItemIndex="index">
            <div class="form-row" [formGroupName]="planItemIndex">
              <div class="plandiv col-md-12">
                <label>Trigger</label>
                <input type="text" name="equipment" formControlName="equipment" style="font-weight: 700;font-size: 16px;">
                <div class="equip-buttons">
                  <button *ngIf="planFirstItem.value.serial | serialToManual" type="button" (click)="openManual(planFirstItem.value.serial)" title="Open Manual">
                    <i class="fas fa-info-circle"></i>
                  </button>
                </div>
              </div>
              <div class="form-group col-md-6">
                <label>Serial</label>
                <input type="text" name="serial" formControlName="serial">
              </div>
              <div class="form-group col-md-5">
                <label>Status</label>
                <select class="noAutoSave" name="status" formControlName="status"
                  (ngModelChange)="onReturningChange($event, 'Alarm', getEquipmentField(planFirstItem, 'serial'))">
                  <option disabled selected value></option>
                  <option *ngFor="let equipStatus of equipmentStatuses" [value]="equipStatus">{{equipStatus}}</option>
                </select>
              </div>
              <div class="form-group col-md-1">
                <label></label>
                <div class="faultBtns">
                  <a class="head-button icon-m pr-1" title="Report Equipment Fault"
                      (click)="openAddFaultDialog(planFirstItem.value?.serial, planFirstItem.value?.equipment, planFirstItem.value?.status, false)">
                    <i class="fas fa-exclamation-triangle"></i>
                  </a>
                  <a class="head-button icon-m" target="_blank" title="View Faults For This Serial" routerLink="/faulty-equipments"
                      [queryParams]="{'serial': planFirstItem.value?.serial}">
                    <i class="fas fa-eye"></i>
                  </a>
                </div>
              </div>
              <div *ngIf="planFirstItem.value.serial | serialToName" class="col-12">
                <div class="alert alert-warning m-0 px-2 py-1">
                  <label>Serial Identified As</label>
                  {{planFirstItem.value.serial | serialToName}}
                </div>
              </div>
              <div class="form-group col-3">
                <label>Year of Manufacture</label>
                <input type="text" name="baseUnitYearMade" formControlName="baseUnitYearMade" maxlength="4" min="1990" max="{{currentYear}}"
                    (keypress)="numberOnly($event, false)" required>
              </div>
              <div class="form-group col-3">
                <label>Added To Order</label>
                <input type="datetime-local" name="added" class="noAutoSave" onkeydown="return false" (change)="changePlanAddedDate($event, planItemIndex)"
                  value="{{planFirstItem.value.added | date: 'yyyy-MM-ddTHH:mm'}}" />
              </div>
            </div>
          </ng-template>
          <ng-template #planSecondItemTemplate let-planSecondItem="plan" let-planItemIndex="index">
            <div class="form-row" [formGroupName]="planItemIndex">
              <div class="col-md-12">
                <label>Trigger</label>
                <input type="text" name="typePendant" formControlName="typePendant"
                    style="font-weight: 700;font-size: 16px;">
                <div class="equip-buttons">
                  <button *ngIf="planSecondItem.value.serialPendant | serialToManual" type="button" (click)="openManual(planSecondItem.value.serialPendant)" title="Open Manual">
                    <i class="fas fa-info-circle"></i>
                  </button>
                </div>
              </div>
              <div class="form-group col-md-6">
                <label>Trigger Serial</label>
                <input type="text" name="serialPendant" formControlName="serialPendant">
              </div>
              <div class="form-group col-md-5">
                <label class="smallv">Status</label>
                <select class="noAutoSave" name="status" formControlName="serialStatus"
                        (ngModelChange)="onReturningChange($event, getEquipmentField(planSecondItem, 'typePendant'), getEquipmentField(planSecondItem, 'serialPendant'))">
                  <option disabled selected value></option>
                  <option *ngFor="let equipStatus of equipmentStatuses" [value]="equipStatus">{{equipStatus}}</option>
                </select>
              </div>
              <div class="form-group col-md-1">
                <label></label>
                <div class="faultBtns">
                  <a class="head-button icon-m pr-1" title="Report Equipment Fault"
                      (click)="openAddFaultDialog(planSecondItem.value?.serialPendant, planSecondItem.value?.typePendant, planSecondItem.value?.serialStatus, true)">
                    <i class="fas fa-exclamation-triangle"></i>
                  </a>
                  <a class="head-button icon-m" target="_blank" title="View Faults For This Serial" routerLink="/faulty-equipments"
                      [queryParams]="{'serial': planSecondItem.value?.serialPendant}">
                    <i class="fas fa-eye"></i>
                  </a>
                </div>
              </div>
              <div *ngIf="planSecondItem.value.serialPendant | serialToName" class="col-12">
                <div class="alert alert-warning m-0 px-2 py-1">
                  <label>Serial Identified As</label>
                  {{planSecondItem.value.serialPendant | serialToName}}
                </div>
              </div>
              <div class="form-group col-3">
                <label>Year of Manufacture</label>
                <input type="text" name="pendantYearMade" formControlName="pendantYearMade" maxlength="4" min="1990" max="{{currentYear}}"
                    (keypress)="numberOnly($event, false)" required>
              </div>
              <div class="form-group col-3">
                <label>Added To Order</label>
                <input type="datetime-local" name="added" class="noAutoSave" value="{{planSecondItem.value.added | date: 'yyyy-MM-ddTHH:mm'}}" readonly/>
              </div>
            </div>
          </ng-template>
          <ng-container *ngFor="let plan of plansForms.controls; let i=index">
            <ng-container *ngIf="(plan.value.status | statusDisplaySection: 'hardware') === 'active'">
              <ng-container *ngTemplateOutlet="planFirstItemTemplate; context: {'plan': plan, 'index': i}">
              </ng-container>
            </ng-container>
            <ng-container *ngIf="(plan.value.equipment !== 'Safety Socket') && (plan.value.serialStatus | statusDisplaySection: 'hardware') === 'active'">
              <ng-container *ngTemplateOutlet="planSecondItemTemplate; context: {'plan': plan, 'index': i}">
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
        <div class="formsect addequip" formArrayName="replacementEquipment" [ngStyle]="{'display': pageSection.minimized? 'none': 'block'}">
          <ng-template #replacementEquipTemplate let-equipment="equipment" let-equipmentIndex="index">
            <div class="form-row" [formGroupName]="equipmentIndex">
              <div class="col-md-12">
                <label>Trigger</label>
                <input type="text" name="equipment"
                    formControlName="equipment" style="font-weight: 700;font-size: 16px;">
                <div class="equip-buttons">
                  <button *ngIf="equipment.value.serial | serialToManual" type="button" (click)="openManual(equipment.value.serial)" title="Open Manual">
                    <i class="fas fa-info-circle"></i>
                  </button>
                  <button type="button" (click)="deleteEquipment(true, equipmentIndex)" title="Delete Equipment">
                    <i class="fas fa-trash"></i>
                  </button>
                </div>
              </div>
              <ng-container *ngIf="!(equipment.value.equipment+'').toLowerCase().includes('monitoring only')">
                <div class="form-group col-md-6">
                  <label>Serial</label>
                  <input type="text" name="serial" formControlName="serial">
                </div>
                <div class="form-group col-md-5">
                  <label>Status</label>
                  <select class="noAutoSave" name="status" formControlName="status"
                        (ngModelChange)="onReturningChange($event, getEquipmentField(equipment, 'equipment'), getEquipmentField(equipment, 'serial'))">
                    <option disabled selected value></option>
                    <option *ngFor="let equipStatus of equipmentStatuses" [value]="equipStatus">{{equipStatus}}</option>
                  </select>
                </div>
                <div class="form-group col-md-1">
                  <label></label>
                  <div class="faultBtns">
                    <a class="head-button icon-m pr-1" title="Report Equipment Fault"
                        (click)="openAddFaultDialog(equipment.value?.serial, equipment.value?.equipment, equipment.value?.status, false)">
                      <i class="fas fa-exclamation-triangle"></i>
                    </a>
                    <a class="head-button icon-m" target="_blank" title="View Faults For This Serial" routerLink="/faulty-equipments"
                        [queryParams]="{'serial': equipment.value?.serial}">
                      <i class="fas fa-eye"></i>
                    </a>
                  </div>
                </div>
                <div *ngIf="equipment.value.serial | serialToName" class="col-12">
                  <div class="alert alert-warning m-0 px-2 py-1">
                    <label>Serial Identified As</label>
                    {{equipment.value.serial | serialToName}}
                  </div>
                </div>
                <div class="form-group col-3">
                  <label>Year of Manufacture</label>
                  <input type="text" name="equipmentYearMade" formControlName="equipmentYearMade" maxlength="4" min="1990" max="{{currentYear}}"
                      (keypress)="numberOnly($event, false)" required>
                </div>
                <div class="form-group col-3">
                  <label>Added To Order</label>
                  <input type="datetime-local" name="added" class="noAutoSave" onkeydown="return false" (change)="changeEquipmentAddedDate($event, true, equipmentIndex)"
                      value="{{equipment.value.added | date: 'yyyy-MM-ddTHH:mm'}}" />
                </div>
                <div class="form-group col-3">
                  <label>Free of Charge</label>
                  <input type="checkbox" formControlName="freeOfCharge" name="freeOfCharge" />
                </div>
              </ng-container>
            </div>
          </ng-template>

          <div class="form-row" *ngFor="let equipment of replacementEquipmentForms.controls; let i=index">
            <ng-container *ngIf="(equipment.value.status | statusDisplaySection: 'hardware') === 'active'">
              <ng-container *ngTemplateOutlet="replacementEquipTemplate; context: {'equipment': equipment, 'index': i}">
              </ng-container>
            </ng-container>
          </div>
        </div>
        <div class="formsect addequip" formArrayName="additionalEquipment" [ngStyle]="{'display': pageSection.minimized? 'none': 'block'}">
          <ng-template #additionalEquipTemplate let-equipment="equipment" let-equipmentIndex="index">
            <div class="form-row" [formGroupName]="equipmentIndex">
              <div class="col-md-12">
                <label>Trigger</label>
                <input type="text" name="equipment"
                    formControlName="equipment" style="font-weight: 700;font-size: 16px;">
                <div class="equip-buttons">
                  <button *ngIf="equipment.value.serial | serialToManual" type="button" (click)="openManual(equipment.value.serial)" title="Open Manual">
                    <i class="fas fa-info-circle"></i>
                  </button>
                  <button type="button" (click)="deleteEquipment(false, equipmentIndex)" title="Delete Equipment">
                    <i class="fas fa-trash"></i>
                  </button>
                </div>
              </div>
              <ng-container *ngIf="!(equipment.value.equipment+'').toLowerCase().includes('monitoring only')">
                <div class="form-group col-md-6">
                  <label>Serial</label>
                  <input type="text" name="serial" formControlName="serial">
                </div>
                <div class="form-group col-md-5">
                  <label>Status</label>
                  <select class="noAutoSave" name="status" formControlName="status"
                        (ngModelChange)="onReturningChange($event, getEquipmentField(equipment, 'equipment'), getEquipmentField(equipment, 'serial'))">
                    <option disabled selected value></option>
                    <option *ngFor="let equipStatus of equipmentStatuses" [value]="equipStatus">{{equipStatus}}</option>
                  </select>
                </div>
                <div class="form-group col-md-1">
                  <label></label>
                  <div class="faultBtns">
                    <a class="head-button icon-m pr-1" title="Report Equipment Fault"
                        (click)="openAddFaultDialog(equipment.value?.serial, equipment.value?.equipment, equipment.value?.status, false)">
                      <i class="fas fa-exclamation-triangle"></i>
                    </a>
                    <a class="head-button icon-m" target="_blank" title="View Faults For This Serial" routerLink="/faulty-equipments"
                        [queryParams]="{'serial': equipment.value?.serial}">
                      <i class="fas fa-eye"></i>
                    </a>
                  </div>
                </div>
                <div *ngIf="equipment.value.serial | serialToName" class="col-12">
                  <div class="alert alert-warning m-0 px-2 py-1">
                    <label>Serial Identified As</label>
                    {{equipment.value.serial | serialToName}}
                  </div>
                </div>
                <div class="form-group col-3">
                  <label>Year of Manufacture</label>
                  <input type="text" name="equipmentYearMade" formControlName="equipmentYearMade" maxlength="4" min="1990" max="{{currentYear}}"
                      (keypress)="numberOnly($event, false)" required>
                </div>
                <div class="form-group col-3">
                  <label>Added To Order</label>
                  <input type="datetime-local" name="added" class="noAutoSave" onkeydown="return false" (change)="changeEquipmentAddedDate($event, false, equipmentIndex)"
                      value="{{equipment.value.added | date: 'yyyy-MM-ddTHH:mm'}}" />
                </div>
                <div class="form-group col-3">
                  <label>Free of Charge</label>
                  <input type="checkbox" formControlName="freeOfCharge" name="freeOfCharge" />
                </div>
              </ng-container>
            </div>
          </ng-template>
          <div class="form-row" *ngFor="let equipment of additionalEquipmentForms.controls; let i=index">
            <ng-container *ngIf="(equipment.value.status | statusDisplaySection: 'hardware') === 'active'">
              <ng-container *ngTemplateOutlet="additionalEquipTemplate; context: {'equipment': equipment, 'index': i}">
              </ng-container>
            </ng-container>
          </div>
        </div>
        <!-- Start Transition Equipment -->
        <div class="formsect aplans" [ngStyle]="{'display': pageSection.minimized? 'none': 'block'}">
          <div class="subsectionHeading">Returning/Lost Chasing/Damaged/Recommending WO</div>
          <ng-container *ngFor="let plan of plansForms.controls; let i=index">
            <ng-container *ngIf="(plan.value.status | statusDisplaySection: 'hardware') === 'transition'">
              <ng-container *ngTemplateOutlet="planFirstItemTemplate; context: {'plan': plan, 'index': i}">
              </ng-container>
            </ng-container>
            <ng-container *ngIf="(plan.value.equipment !== 'Safety Socket') && (plan.value.serialStatus | statusDisplaySection: 'hardware') === 'transition'">
              <ng-container *ngTemplateOutlet="planSecondItemTemplate; context: {'plan': plan, 'index': i}">
              </ng-container>
            </ng-container>
          </ng-container>
          <div class="form-row" *ngFor="let equipment of replacementEquipmentForms.controls; let i=index">
            <ng-container *ngIf="(equipment.value.status | statusDisplaySection: 'hardware') === 'transition'">
              <ng-container *ngTemplateOutlet="replacementEquipTemplate; context: {'equipment': equipment, 'index': i}">
              </ng-container>
            </ng-container>
          </div>
          <div class="form-row" *ngFor="let equipment of additionalEquipmentForms.controls; let i=index">
            <ng-container *ngIf="(equipment.value.status | statusDisplaySection: 'hardware') === 'transition'">
              <ng-container *ngTemplateOutlet="additionalEquipTemplate; context: {'equipment': equipment, 'index': i}">
              </ng-container>
            </ng-container>
          </div>
        </div>
        <!-- Start Inactive Equipment -->
        <div class="formsect aplans" [ngStyle]="{'display': pageSection.minimized? 'none': 'block'}">
          <div class="subsectionHeading">Inactive Equipment</div>
          <ng-container *ngFor="let plan of plansForms.controls; let i=index">
            <ng-container *ngIf="(plan.value.status | statusDisplaySection: 'hardware') === 'inactive'">
              <ng-container *ngTemplateOutlet="planFirstItemTemplate; context: {'plan': plan, 'index': i}">
              </ng-container>
            </ng-container>
            <ng-container *ngIf="(plan.value.equipment !== 'Safety Socket') && (plan.value.serialStatus | statusDisplaySection: 'hardware') === 'inactive'">
              <ng-container *ngTemplateOutlet="planSecondItemTemplate; context: {'plan': plan, 'index': i}">
              </ng-container>
            </ng-container>
          </ng-container>
          <div class="form-row" *ngFor="let equipment of replacementEquipmentForms.controls; let i=index">
            <ng-container *ngIf="(equipment.value.status | statusDisplaySection: 'hardware') === 'inactive'">
              <ng-container *ngTemplateOutlet="replacementEquipTemplate; context: {'equipment': equipment, 'index': i}">
              </ng-container>
            </ng-container>
          </div>
          <div class="form-row" *ngFor="let equipment of additionalEquipmentForms.controls; let i=index">
            <ng-container *ngIf="(equipment.value.status | statusDisplaySection: 'hardware') === 'inactive'">
              <ng-container *ngTemplateOutlet="additionalEquipTemplate; context: {'equipment': equipment, 'index': i}">
              </ng-container>
            </ng-container>
          </div>
        </div>
        <!-- End Active Equipment -->

        <!-- Start keySafe-->
        <div class="formsect akeysafe" formArrayName="keySafes" [ngStyle]="{'display': pageSection.minimized? 'none': 'block'}">
          <ng-template #keySafeTemplate let-keySafeItem="keySafe" let-keySafeIndex="index">
            <ng-container [formGroupName]="keySafeIndex">
              <div class="form-group col-12 col-xl-6">
                <label>Product Type</label>
                <input type="text" name="equipment" formControlName="equipment" readonly>
                <div class="equip-buttons">
                  <button type="button" (click)="deletekeySafes(keySafeIndex)" title="Delete Keysafe">
                    <i class="fas fa-trash"></i>
                  </button>
                </div>
              </div>
              <div class="form-group col-6 col-xl-3">
                <label>Added To Order</label>
                <input type="datetime-local" name="added" class="noAutoSave" onkeydown="return false" (change)="changeKeysafeAddedDate($event, keySafeIndex)"
                    value="{{keySafeItem.value.added | date: 'yyyy-MM-ddTHH:mm'}}" />
              </div>
              <div class="form-group col-6 col-xl-3">
                <label>Status</label>
                <select class="noAutoSave" name="status" formControlName="status"
                    (ngModelChange)="onReturningChange($event, keySafeItem.value.equipment, '')">
                  <option disabled selected value></option>
                  <option *ngFor="let keySafeStatus of keySafeStatuses" [value]="keySafeStatus">
                    {{keySafeStatus}}
                  </option>
                </select>
              </div>
            </ng-container>
          </ng-template>
          <ng-container *ngIf="keySafesForms.controls.length > 0">
            <div class="subsectionHeading">
              Key Safes
            </div>
            <div class="form-row"></div>
            <ng-container *ngFor="let keysafe of keySafesForms.controls; let keySafeIndex=index">
              <div *ngIf="keySafeStatusConfig[keysafe.value.status].active" class="form-row">
                <ng-container *ngTemplateOutlet="keySafeTemplate; context: {'keySafe': keysafe, 'index': keySafeIndex}">
                </ng-container>
              </div>
            </ng-container>
            <div class="subsectionHeading inactive">
              Inactive Key Safes
              <button (click)="isInactiveKeySafesMinimized = !isInactiveKeySafesMinimized" type="button" class="btn minMax">
                <i *ngIf="!isInactiveKeySafesMinimized" class="pi pi-minus"></i>
                <i *ngIf="isInactiveKeySafesMinimized" class="pi pi-plus"></i>
              </button>
            </div>
            <div class="form-row"></div>
            <div [ngStyle]="{'display': isInactiveKeySafesMinimized? 'none': 'block'}">
              <ng-container *ngFor="let keysafe of keySafesForms.controls; let keySafeIndex=index" >
                <div *ngIf="!keySafeStatusConfig[keysafe.value.status].active" class="form-row">
                  <ng-container *ngTemplateOutlet="keySafeTemplate; context: {'keySafe': keysafe, 'index': keySafeIndex}">
                  </ng-container>
                </div>
              </ng-container>
            </div>
          </ng-container>
        </div>
        <!-- end KeySafe-->

        <!-- Start services-->
        <div class="formsect akeysafe" formArrayName="services" [ngStyle]="{'display': pageSection.minimized? 'none': 'block'}">
          <ng-template #orderServiceTemplate let-serviceItem="service" let-serviceIndex="index">
            <ng-container [formGroupName]="serviceIndex">
              <div class="form-row">
                <div class="form-group col-12 col-xl-6">
                  <label>Service</label>
                  <input type="text" name="serviceName" formControlName="serviceName" readonly>
                  <div class="equip-buttons">
                    <button type="button" (click)="deleteService(serviceIndex)" title="Delete {{serviceItem.value.serviceName}}`">
                      <i class="fas fa-trash"></i>
                    </button>
                  </div>
                </div>
                <div class="form-group col-6 col-xl-3">
                  <label>Added To Order</label>
                  <input type="datetime-local" name="added" class="noAutoSave" onkeydown="return false" (change)="changeServiceAddedDate($event, serviceIndex)"
                      value="{{serviceItem.value.added | date: 'yyyy-MM-ddTHH:mm'}}" />
                </div>
                <div class="form-group col-6 col-xl-3">
                  <label>Status</label>
                  <select class="noAutoSave" name="status" formControlName="status"
                      (ngModelChange)="statusChangeOnService($event, serviceItem.value.serviceName, serviceIndex)">
                    <option disabled selected value></option>
                    <option *ngFor="let serviceStatus of serviceStatuses" [value]="serviceStatus">
                      {{serviceStatus}}
                    </option>
                  </select>
                </div>
              </div>
              <ng-container *ngIf="serviceItem.value.serviceName === 'Friends and Family'">
                <div class="subsectionHeading">
                  Service Users [{{serviceUsers.length}}/6]
                </div>
                <ng-container *ngFor="let serviceUser of serviceUsers; let serviceUserIndex=index">
                  <div class="form-row">
                    <div class="form-group col-6 col-xl-4">
                      <label>First Name</label>
                      <input type="text" name="serviceUserFirstName" value="{{serviceUser.srvUserFirstName}}" readonly>
                    </div>
                    <div class="form-group col-6 col-xl-4">
                      <label>Last Name</label>
                      <input type="text" name="serviceUserLastName" value="{{serviceUser.srvUserLastName}}" readonly>
                    </div>
                    <div class="form-group col-6 col-xl-4">
                      <label>Invitation Code</label>
                      <input type="text" name="invitationCode" value="{{serviceUser.invitationCode}}" readonly>
                    </div>
                    <div class="form-group col-6 col-xl-12">
                      <label>Email Address</label>
                      <input type="text" name="serviceUserEmail" value="{{serviceUser.srvUserEmail}}" readonly>
                      <div class="equip-buttons">
                        <button type="button" (click)="resendInviteEmail(serviceUserIndex)" title="Resend Invite Email"
                            [disabled]="!serviceUser.invitationCode">
                          <i class="fas fa-redo"></i>
                        </button>
                        <button type="button" (click)="confirmServiceUserDelete(serviceUserIndex)" title="Delete Service User">
                          <i class="fas fa-trash"></i>
                        </button>
                      </div>
                    </div>
                    <div class="col-12"><hr/></div>
                  </div>
                </ng-container>
                <ng-container *ngIf="serviceUsers.length < 6">
                  <div class="subsectionHeading">
                    Add Service User
                  </div>
                  <div class="form-row" [formGroup]="addServiceUserForm">
                    <div class="form-group col-6 col-xl-3">
                      <label>First Name</label>
                      <input class="noAutoSave" type="text" name="srvUserFirstName" formControlName="srvUserFirstName" required>
                    </div>
                    <div class="form-group col-6 col-xl-3">
                      <label>Last Name</label>
                      <input class="noAutoSave" type="text" name="srvUserLastName" formControlName="srvUserLastName" required>
                    </div>
                    <div class="form-group col-8 col-xl-4">
                      <label>Email Address</label>
                      <input class="noAutoSave" type="email" name="srvUserEmail" formControlName="srvUserEmail" required>
                    </div>
                    <div class="form-group col-4 col-xl-2">
                      <label></label>
                      <button type="button" class="noAutoSave btn btn-primary btn-sm" (click)="addServiceUser()"
                          title="Add Service User" [disabled]="!isOkToServiceUser()">
                        Add User
                      </button>
                    </div>
                  </div>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-template>
          <ng-container *ngIf="serviceForms.controls.length > 0">
            <div class="subsectionHeading">
              Services
            </div>
            <div class="form-row"></div>
            <ng-container *ngFor="let serviceControl of serviceForms.controls; let serviceIndex=index">
              <ng-container *ngIf="serviceControl.value.status === 'Active'">
                <ng-container *ngTemplateOutlet="orderServiceTemplate; context: {'service': serviceControl, 'index': serviceIndex}">
                </ng-container>
              </ng-container>
            </ng-container>
            <div class="subsectionHeading inactive">
              Inactive Services
              <button (click)="isInactiveServicesMinimized = !isInactiveServicesMinimized" type="button" class="btn minMax">
                <i *ngIf="!isInactiveServicesMinimized" class="pi pi-minus"></i>
                <i *ngIf="isInactiveServicesMinimized" class="pi pi-plus"></i>
              </button>
            </div>
            <div class="form-row"></div>
            <div [ngStyle]="{'display': isInactiveServicesMinimized? 'none': 'block'}">
              <ng-container *ngFor="let serviceControl of serviceForms.controls; let serviceIndex=index">
                <ng-container *ngIf="serviceControl.value.status !== 'Active'">
                  <ng-container *ngTemplateOutlet="orderServiceTemplate; context: {'service': serviceControl, 'index': serviceIndex}">
                  </ng-container>
                </ng-container>
              </ng-container>
            </div>
          </ng-container>
        </div>
        <!-- end services-->
      </ng-container>
    </ng-template>

    <!-- Start Renewal Information-->
    <ng-template #renewalSection let-pageSection="pageSection">
      <ng-container *ngIf="! loading" formGroupName="renewalInformation">
        <div class="formbar row no-gutters" [ngClass]="{'attention':attention}">
          <div class="col-12">
            Renewal Information
            <span class="form-h" id="ribar">
              <div class="debit attention">
                {{toLetterCode(orderForm.value.renewalInformation.renewalType) }}
              </div>
            </span>
            <button (click)="minimizeSection(pageSection)" type="button" class="btn minMax">
              <i *ngIf="!pageSection.minimized" class="pi pi-minus"></i>
              <i *ngIf="pageSection.minimized" class="pi pi-plus"></i>
            </button>
          </div>
        </div>
        <div class="formsect">
          <div [ngStyle]="{'display': pageSection.minimized? 'none': 'block'}">
            <div class="form-row">
              <div *ngIf="order.legalCompany | stripeAccountName:order.website.title" class="form-group col-12 alert alert-danger">
                All billing through {{order.legalCompany | stripeAccountName:order.website.title}}
              </div>
            </div>
            <ng-container *ngTemplateOutlet="billingAccountLinks"></ng-container>
            <div class="form-row">
              <div class="form-group col-6">
                <label>Expiry Date</label>
                <input type="date" class="noAutoSave" (change)="changeRenewalDate()"
                    onkeydown="return false" min="2014-01-01"
                    [ngStyle]="{'color':orderForm.value.renewalInformation.renewalDate  === null ||
                    orderForm.value.renewalInformation.renewalDate  === '' ? '#fff' : 'black' }   "
                    name="renewalDate" formControlName="renewalDate" />
              </div>

              <div class="form-group col-6">
                <label>Payment Due Date</label>
                <input type="date" name="paymentDueDate" formControlName="paymentDueDate" readonly />
              </div>

              <div class="form-group col-md-6">
                <label class="renew">Renewal Price</label>
                <input class="noAutoSave renew" type="number" min="0" name="renewalPrice"
                    formControlName="renewalPrice" (change)="changeRenewalPrice()" required>
              </div>

              <div class="form-group col-6">
                <label>Renewal Type</label>
                <select class="noAutoSave" name="renewalType" (change)="confirmUpdateRenewalType()" formControlName="renewalType">
                  <option *ngFor="let renewalTypeSelectItem of renewalTypeSelectItems" value="{{renewalTypeSelectItem.value}}">
                    {{renewalTypeSelectItem.label}}
                  </option>
                </select>
              </div>

              <div class="form-group col-4">
                <label>Free months</label>
                <input name="freemonths" readonly
                    value="{{orderForm.value.renewalInformation.freemonths? orderForm.value.renewalInformation.freemonths + ' months': ''}}" />
              </div>
              <div class="form-group col-4">
                <label></label>
                <div>
                  <button type="button" (click)="changeFreeMonths()" class="btn btn-primary">
                    Change Free Months
                  </button>
                </div>
              </div>
              <div class="form-group col-4">
                <label></label>
                <div>
                  <button type="button" (click)="openRefundRequestDialog()" class="btn btn-primary">
                    Request Refund
                  </button>
                </div>
              </div>
            </div>
            <ng-container *ngIf="discountForms.controls.length > 0">
              <div class="subsectionHeading">
                Discounts
              </div>
              <ng-template #discountTemplate let-discountItem="discount" let-discountIndex="index">
                <div class="form-group col-2">
                  <label>Discount Amount</label>
                  <input type="text" name="discount" value="{{discountItem.value.discount}}" readonly>
                </div>
                <div class="form-group col-4">
                  <label>Discount Expires at the start of</label>
                  <input type="text" name="discountExpiry" readonly
                    value="{{discountItem.value.discountExpiry? (discountItem.value.discountExpiry | date:'dd/MM/yyyy'): 'Will not expire'}}">
                </div>
                <div class="form-group col-6">
                  <label>Reason</label>
                  <input type="text" name="reason" value="{{discountItem.value.reason}}" readonly>
                  <div class="discount-buttons">
                    <button type="button" (click)="deleteRenewalDiscount(discountIndex)" title="Delete Discount">
                      <i class="fas fa-trash"></i>
                    </button>
                  </div>
                </div>
                <div *ngIf="discountItem.value.otherReasonText" class="form-group col-12">
                  <label>Other Reason Text</label>
                  <input type="text" name="otherReasonText" value="{{discountItem.value.otherReasonText}}" readonly>
                </div>
                <div class="col-12"><hr/></div>
              </ng-template>
              <ng-container *ngFor="let renewalDiscount of discountForms.controls; let discountIndex=index">
                <div *ngIf="(!renewalDiscount.value.discountExpiry) || (renewalDiscount.value.discountExpiry > todayDateStr)" class="form-row">
                  <ng-container *ngTemplateOutlet="discountTemplate; context: {'discount': renewalDiscount, 'index': discountIndex}">
                  </ng-container>
                </div>
              </ng-container>
              <div class="subsectionHeading inactive">
                Expired Discounts
                <button (click)="isExpiredDiscountsMinimized = !isExpiredDiscountsMinimized" type="button" class="btn minMax">
                  <i *ngIf="!isExpiredDiscountsMinimized" class="pi pi-minus"></i>
                  <i *ngIf="isExpiredDiscountsMinimized" class="pi pi-plus"></i>
                </button>
              </div>
              <div [ngStyle]="{'display': isExpiredDiscountsMinimized? 'none': 'block'}">
                <ng-container *ngFor="let renewalDiscount of discountForms.controls; let discountIndex=index">
                  <div *ngIf="(!!renewalDiscount.value.discountExpiry) && (renewalDiscount.value.discountExpiry <= todayDateStr)" class="form-row">
                    <ng-container *ngTemplateOutlet="discountTemplate; context: {'discount': renewalDiscount, 'index': discountIndex}">
                    </ng-container>
                  </div>
                </ng-container>
              </div>
            </ng-container>

            <div class="subsectionHeading">Add New Discount</div>
            <div class="form-row">
              <ng-container [formGroup]="discountForm">
                <div class="form-group col-4">
                  <label>Discount Amount</label>
                  <input class="noAutoSave" type="number" min="0" step="0.01" (keypress)="numberOnly($event, true)" name="discount" formControlName="discount" required>
                </div>
                <div class="form-group col-5">
                  <label>Discount Expires at the start of</label>
                  <input class="noAutoSave" type="date" name="discountExpiry" formControlName="discountExpiry"
                      onkeydown="return false" min="{{tomorrow | date: 'yyyy-MM-dd'}}" (change)="clearDiscountDoNotExpireIfNeeded()" />
                </div>
                <div class="form-group col-3">
                  <label>Do Not Expire</label>
                  <input class="noAutoSave" type="checkbox" formControlName="doNotExpire" name="doNotExpire"
                      (change)="clearDiscountExpiryIfNeeded()" />
                </div>

                <div class="form-group col-9">
                  <label>Reason</label>
                  <select class="noAutoSave" name="reason" formControlName="reason" required>
                    <option disabled selected value></option>
                    <option *ngFor="let discountReasonSelectItem of discountReasonSelectItems" value="{{discountReasonSelectItem.value}}">
                      {{discountReasonSelectItem.label}}
                    </option>
                  </select>
                </div>
                <div class="form-group col-3">
                  <label></label>
                  <button type="button" class="btn btn-primary" (click)="addRenewalDiscount()" [disabled]="!isOkToAddDiscount()">
                    Add Discount
                  </button>
                </div>
                <div *ngIf="discountForm.value.reason === 'Other'" class="form-group col-12">
                  <label>Other Reason Text</label>
                  <input class="noAutoSave" type="text" name="otherReasonText" formControlName="otherReasonText"
                      [ngClass]="discountForm.errors?.discountReasonRequired? 'valueRequired': 'valueProvided'">
                  <div *ngIf="discountForm.errors?.discountReasonRequired" class="alert alert-danger">
                    {{discountForm.errors?.discountReasonRequired}}
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-template>
    <!-- End Renewal Information-->
    <ng-template #vimMessages let-pageSection="pageSection">
      <ng-container *ngIf="! loading">
        <div *ngFor="let vim of vimForms.controls; let vimIndex=index">
          <span class="vimmessage"> {{vim.value.content}}</span>
          <span class="vimby"> By {{vim.value.userName}}
            &#64; {{vim.value.date | date:'dd/MM/y HH:mm'}}</span>
          <span>
            <button type="button" class="btn vimdelete" (click)="confirmdeletevim(vimIndex)" title="Delete VIM">
              <i class="fa fa-trash"></i>
            </button>
          </span>
        </div>
        <div [formGroup]="vimForm" class="message" *ngIf="enable">
          <textarea formControlName="content" class="noAutoSave fullv notes" rows="5" cols="50">
          </textarea>
        </div>
        <div class="d-flex justify-content-end">
          <button type="button" *ngIf="enable" class="btn addvim" (click)="addVim()" title="Add VIM">
            Add Message <i class="fas fa-plus"></i>
          </button>
        </div>
      </ng-container>
    </ng-template>
    <!-- Start Vim -->
    <p-dialog id="vimPopup" [(visible)]="vimPopupOpen" header="" [modal]="true"  [style]="{width: '80%'}" (onHide)="closeVimPopup()" [transitionOptions]="'0ms'">
      <p-header>
        <div class="d-flex justify-content-between">
          <span>Very Important Message</span>
          <button type="button" class="btn popupenablebtn" (click)="enabled()" title="Lock/Unlock VIM">
            <i *ngIf="!enable" class="fas fa-lock"></i>
            <i *ngIf="enable" class="fas fa-unlock"></i>
          </button>
        </div>
      </p-header>
      <ng-container *ngTemplateOutlet="vimMessages"></ng-container>
      <p-footer>
        <button type="button" class="btn btn-primary" (click)="closeVimPopup()">
          <span aria-hidden="true" class="pi pi-check"></span>OK
        </button>
      </p-footer>
    </p-dialog>
    <ng-template #vim>
      <div *ngIf="! loading" class="formsect col-12 vim" [ngClass]="{'stickyvim': vimForms.controls.length !== 0}" [hidden]="vimPopupOpen">
        <div class="formmessage" [ngStyle]="{'background-color': vimForms.controls.length !== 0 ? '#ff0000' :'grey'}">
          <span style="font-size: 19px;">Very Important Message</span>
          <ng-container *ngTemplateOutlet="vimMessages"></ng-container>
          <button type="button" class="btn enablebtn" (click)="enabled()" title="Lock/Unlock VIM">
            <i *ngIf="!enable" class="fas fa-lock"></i>
            <i *ngIf="enable" class="fas fa-unlock"></i>
          </button>
        </div>
      </div>
    </ng-template>
    <!-- end Vim -->
    <!-- Start Order Status -->
    <ng-template #statusSection let-pageSection="pageSection">
      <ng-container *ngIf="! loading">
        <div class="formbar row no-gutters">
          <div class="col-6">
            Status
          </div>
          <div class="col-5 wide-buttons">
            <button name="retained" type="button" (click)="openRetentionDialog()" class="btn btn-primary"
                [disabled]="retentionRecorded">
              Record Retention
            </button>
          </div>
          <div class="col-1">
            <button (click)="minimizeSection(pageSection)" type="button" class="btn minMax">
              <i *ngIf="!pageSection.minimized" class="pi pi-minus"></i>
              <i *ngIf="pageSection.minimized" class="pi pi-plus"></i>
            </button>
          </div>
        </div>
        <div class="formsect">
          <div formGroupName="status" [ngStyle]="{'display': pageSection.minimized? 'none': 'block'}">
            <div class="form-row">
              <div class="form-group col-4">
                <label class="setby"></label>
                <select name="status" formControlName="status" class="noAutoSave"
                    [style.background-color]="getStatusBackgroundColour(orderForm.value.status.status)"
                    [style.color]="getStatusColour(orderForm.value.status.status)"
                    (change)="changeStatusSetbyAndDate()">
                  <option *ngFor="let status of statuses" value="{{status}}"
                      [style.background-color]="statusConfigs[status].background"
                      [style.color]="statusConfigs[status].color">
                    {{status}}
                  </option>
                </select>
              </div>
              <div class="form-group col-4">
                <label class="setby">Set By</label>
                <input type="text" name="setby" formControlName="setby" readonly>
              </div>
              <div class="form-group col-4">
                <label>Date</label>
                <input value=" {{orderForm.value.status.date| date:'dd/MM/yyyy HH:mm' }}" readonly>
              </div>
              <div class="form-group col-6">
                <label>Background Auto Test Call</label>
                <input value=" {{orderForm.value.status.backgroundAutoTestCall| date:'dd/MM/yyyy HH:mm' }}" readonly>
              </div>
              <div class="form-group col-6">
                <label>Last Box Activation</label>
                <input value=" {{orderForm.value.status.lastBoxActivation | date:'dd/MM/yyyy HH:mm' }}" readonly>
              </div>
              <div class="form-group col-6">
                <label>Pendant 1 - Last Activation</label>
                <input value="{{orderForm.value.status.pendantOneLastActivation | date:'dd/MM/yyyy HH:mm'}}" readonly>
              </div>
              <div class="form-group col-6">
                <label>Pendant 2 - Last Activation</label>
                <input value="{{orderForm.value.status.pendantTwoLastActivation | date:'dd/MM/yyyy HH:mm'}}" readonly>
              </div>
              <div *ngIf="orderForm.value.status.lastGpsActivation" class="form-group col-6">
                <label>GPS - Last Activation</label>
                <input value="{{orderForm.value.status.lastGpsActivation | date:'dd/MM/yyyy HH:mm'}}" readonly>
              </div>
              <div *ngIf="orderForm.value.status.lastRadioTestMissing" class="form-group col-6">
                <label>Last Radio Test Missing</label>
                <input value="{{orderForm.value.status.lastRadioTestMissing | date:'dd/MM/yyyy HH:mm'}}" readonly>
              </div>
              <div *ngIf="orderForm.value.status.lastEpilepsyAlert" class="form-group col-6">
                <label>Last Epilepsy Alert</label>
                <input value="{{orderForm.value.status.lastEpilepsyAlert | date:'dd/MM/yyyy HH:mm'}}" readonly>
              </div>
              <div class="form-group col-6">
                <label>Last Mains Fail</label>
                <input value="{{orderForm.value.status.lastMainsFail | date:'dd/MM/yyyy HH:mm'}}" readonly>
              </div>
              <div class="form-group col-6">
                <label>Mains Fail Count (For last outage)</label>
                <input value="{{orderForm.value.status.mainsFailCount}}" readonly>
              </div>
              <div *ngIf="orderForm.value.status.lastSmokeAlarmActivation" class="form-group col-md-6">
                <label>Smoke Alarm - Last Activation</label>
                <input value="{{orderForm.value.status.lastSmokeAlarmActivation | date:'dd/MM/yyyy HH:mm'}}" readonly>
              </div>
              <div *ngIf="orderForm.value.status.lastCODetectorActivation" class="form-group col-md-6">
                <label>CO Detector - Last Activation</label>
                <input value="{{orderForm.value.status.lastCODetectorActivation | date:'dd/MM/yyyy HH:mm'}}" readonly>
              </div>
              <div *ngIf="orderForm.value.status.lastHighUseDate" class="form-group col-md-6">
                <label>Last Appeared on High User Report</label>
                <input value="{{orderForm.value.status.lastHighUseDate | date:'dd/MM/yyyy HH:mm'}}" readonly>
              </div>
              <div *ngIf="orderForm.value.status.highUseCount" class="form-group col-md-6">
                <label>No. Of Consecutive High User Reports Appeared On</label>
                <input value="{{orderForm.value.status.highUseCount}}" readonly>
              </div>
              <div class="form-group col-12">
                <app-alarm-activation-section [orderId]="orderDbId"></app-alarm-activation-section>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-template>
    <!-- end Order  Status -->

    <!-- Start outstanding Actions -->
    <ng-template #actionsSection let-pageSection="pageSection">
      <ng-container *ngIf="! loading" formGroupName="outstandingActions">
        <div class="formbar row no-gutters">
          <div class="col-12">
            Outstanding Actions
            <button (click)="minimizeSection(pageSection)" type="button" class="btn minMax">
              <i *ngIf="!pageSection.minimized" class="pi pi-minus"></i>
              <i *ngIf="pageSection.minimized" class="pi pi-plus"></i>
            </button>
          </div>
        </div>
        <div class="formsect">
          <div [ngStyle]="{'display': pageSection.minimized? 'none': 'block'}">
            <datalist id="returnedEquipList">
              <option value="MyAmie Pendant">MyAmie Pendant</option>
              <option value="Vibby Fall Detector">Vibby Fall Detector</option>
              <option value="IP Vibby">IP Vibby</option>
              <option value="GSM Vibby">GSM Vibby</option>
              <option value="IP Pendant">IP Pendant</option>
              <option value="GSM Pendant">GSM Pendant</option>
              <option value="Alarm Equipment">Alarm Equipment</option>
              <option value="iVi Fall Detector">iVi Fall Detector</option>
              <option value="Key Safe">Key Safe</option>
              <option value="Smoke Detector">Smoke Detector</option>
              <option value="Alarm Base Unit">Alarm Base Unit</option>
              <option value="Safety Socket">Safety Socket</option>
              <option value="Onyx Pendant">Onyx Pendant</option>
              <option value="Minuet Watch">Minuet Watch</option>
              <option value="Bed Sensor">Bed Sensor</option>
              <option value="Universal Sensor">Universal Sensor</option>
              <option value="GO GPS Alarm">GO GPS Alarm</option>
              <option value="GO GPS Alarm FD">GO GPS Alarm FD</option>
              <option value="GPS Alarm">GPS Alarm</option>
              <option value="GPS Charging Station/Pad">GPS Charging Station/Pad</option>
            </datalist>
            <div class="form-row">
              <div *ngFor="let action of outstandingActionsForms.controls; let actionIndex=index"
                  class="form-row os-act" [formGroupName]="actionIndex">
                <div class="col-6">
                  <input
                      [style.background-color]="getActionBackgroundColour(action.value.outstandingName, action.value.outstandingColor)"
                      [style.color]="getActionColour(action.value.outstandingName)"
                      name="outstandingName"
                      formControlName="outstandingName" readonly style="text-align: center;color: #000;">
                </div>
                <div class="col-5">
                  <a class="head-button" style="margin-left: initial;">
                    <button type="button" class="btn btn-primary" (click)="openContactAttempt(action)">Contact Attempt</button>
                  </a>
                </div>
                <div class="col-1">
                  <button type="button" class="btn action-delete" (click)="confirmDeleteOutstandingAction(actionIndex)"
                      title="Delete {{action.value.outstandingName}} action">
                    <i class="fas fa-trash"></i>
                  </button>
                </div>
                <div class="col-6">
                  <label>Date Action Initiated</label>
                  <input type="text" value="{{action.value.actionInitiatedDate | date:'dd/MM/yyyy HH:mm'}}" readonly>
                </div>
                <div class="col-6">
                  <label>Action Due:</label>
                  <input *ngIf="action.value.renewalDateTaken" value="{{action.value.renewalDateTaken| date:'dd/MM/yyyy HH:mm'}}" readonly>
                  <input *ngIf="!action.value.renewalDateTaken" class="noAutoSave" type="datetime-local" name="renewalDateTaken"
                    onkeydown="return false" (change)="changeActionDueDate($event, actionIndex)" />
                </div>
                <div class="col-6" *ngIf="actions[action.value.outstandingName]?.returnedEquipLabel">
                  <label for="returnedEquip">{{actions[action.value.outstandingName].returnedEquipLabel}}</label>
                  <input type="search" list="returnedEquipList" id="returnedEquip" name="returnedEquip" formControlName="returnedEquip">
                </div>
                <div class="col-6" *ngIf="actions[action.value.outstandingName]?.returnedEquipTwoLabel">
                  <label for="returnedEquipTwo">{{actions[action.value.outstandingName].returnedEquipTwoLabel}}</label>
                  <input type="search" list="returnedEquipList" name="returnedEquipTwo" formControlName="returnedEquipTwo">
                </div>
                <div class="col-6" *ngIf="actions[action.value.outstandingName]?.returnedEquipThreeLabel">
                  <label for="returnedEquipThree">{{actions[action.value.outstandingName].returnedEquipThreeLabel}}</label>
                  <input type="search" list="returnedEquipList" name="returnedEquipThree" formControlName="returnedEquipThree">
                </div>
                <div class="col-6" *ngIf="actions[action.value.outstandingName]?.personReturningLabel">
                  <label>{{actions[action.value.outstandingName].personReturningLabel}}</label>
                  <input type="text" name="personReturning" formControlName="personReturning" >
                </div>
                <div class="col-6" *ngIf="actions[action.value.outstandingName]?.cancellationEmailLabel">
                  <label>{{actions[action.value.outstandingName].cancellationEmailLabel}}</label>
                  <input type="text" name="cancellationEmail" formControlName="cancellationEmail" >
                </div>
                <div class="col-6" *ngIf="actions[action.value.outstandingName]?.responsiblePersonNameLabel">
                  <label>{{actions[action.value.outstandingName].responsiblePersonNameLabel}}</label>
                  <input type="text" name="responsiblePersonName" formControlName="responsiblePersonName" >
                </div>
                <div class="col-6" *ngIf="actions[action.value.outstandingName]?.contactNumberLabel">
                  <label>{{actions[action.value.outstandingName].contactNumberLabel}}</label>
                  <input type="text" name="contactNumber" formControlName="contactNumber" class="validated">
                </div>
                <div class="col-6" *ngIf="actions[action.value.outstandingName]?.emailAddressLabel">
                  <label>{{actions[action.value.outstandingName].emailAddressLabel}}</label>
                  <input type="text" name="emailAddress" formControlName="emailAddress" class="validated">
                </div>
                <div class="col-6" *ngIf="actions[action.value.outstandingName]?.statusLabel">
                  <label>{{actions[action.value.outstandingName].statusLabel}}</label>
                  <input type="text" name="status" formControlName="status">
                </div>
                <div class="col-6" *ngIf="actions[action.value.outstandingName]?.dateLabel">
                  <label>{{actions[action.value.outstandingName].dateLabel}}</label>
                  <input class="noAutoSave" type="datetime-local" name="date" value="{{action.value.date | date: 'yyyy-MM-ddTHH:mm'}}"
                    onkeydown="return false" (change)="changeActionDate($event, actionIndex)" min="2021-01-01T00:00">
                </div>
                <div class="col-6" *ngIf="actions[action.value.outstandingName]?.countLabel">
                  <label>{{actions[action.value.outstandingName].countLabel}}</label>
                  <input type="number" name="count" formControlName="count" (keypress)="numberOnly($event, false)"/>
                </div>
                <div class="col-6" *ngIf="actions[action.value.outstandingName]?.holdUntilLabel">
                  <label>{{actions[action.value.outstandingName].holdUntilLabel}}</label>
                  <input type="date" name="holdUntil" formControlName="holdUntil" min="2014-01-01" >
                </div>
                <div class="col-6" *ngIf="actions[action.value.outstandingName]?.owedPaymentLabel">
                  <label>{{actions[action.value.outstandingName]?.owedPaymentLabel}}</label>
                  <input type="text" formControlName="owedPayment" min="0" step="0.01" (keypress)="numberOnly($event, true)" >
                </div>
                <div class="col-12" *ngIf="actions[action.value.outstandingName]?.reasonLabel">
                  <div class="form-row">
                    <div class="col-6">
                      <label>{{actions[action.value.outstandingName]?.reasonLabel}}</label>
                      <input *ngIf="!actionReasonLookups[action.value.outstandingName]" type="text" formControlName="reason" name="reason">
                      <p-dropdown *ngIf="actionReasonLookups[action.value.outstandingName]" [options]="actionReasonLookups[action.value.outstandingName]"
                        formControlName="reason" placeholder="Select a reason" (onChange)="changeActionReason($event, actionIndex)">
                      </p-dropdown>
                    </div>
                    <div class="col-6">
                      <ng-container *ngIf="actionReasonLookups[action.value.outstandingName] && (action.value.reason === 'Other')">
                        <label>Other {{actions[action.value.outstandingName]?.reasonLabel}}</label>
                        <input type="text" formControlName="reasonOther" name="reasonOther">
                      </ng-container>
                    </div>
                  </div>
                </div>
                <div class="col-6" *ngIf="actions[action.value.outstandingName]?.returnLabelNumbersLabel">
                  <label>{{actions[action.value.outstandingName].returnLabelNumbersLabel}}</label>
                  <textarea [rows]="3" [cols]="30" pInputTextarea name="returnLabelNumbers" formControlName="returnLabelNumbers">
                  </textarea>
                </div>
                <div class="col-6" *ngIf="actions[action.value.outstandingName]?.noteLabel">
                  <label>{{actions[action.value.outstandingName].noteLabel}}</label>
                  <textarea [rows]="3" [cols]="30" pInputTextarea name="note" formControlName="note">
                  </textarea>
                </div>
              </div>
            </div>

            <div class="form-row" [formGroup]="actionForm" style="margin-top: 10px;">
              <div class="form-row" style="width: 100%;margin-right: 0px;margin-left: 0px;">
                <div class="form-group col-6">
                  <p-dropdown [options]="actionNames" class="noAutoSave fullv"
                            name="outstanding" formControlName="outstanding"
                            placeholder="action" [filter]=true [resetFilterOnHide]="true">
                    <ng-template let-action pTemplate="item">
                      <div [style.background-color]="actions[action.value].background" [style.color]="actions[action.value].color" class="p-1">
                        {{action.label}}
                      </div>
                    </ng-template>
                  </p-dropdown>
                </div>
                <div class="form-group col-6">
                  <a class="head-button" style="margin-left: initial;">
                    <button type="button" class="btn btn-primary" (click)="addOutstandingAction()">Add Action</button>
                  </a>
                </div>
              </div>
              <span class="alert-warning" *ngIf="outstandingActionsErr">This selected action can't be duplicated!</span>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-template>
    <!-- end outstanding Actions -->

    <!-- Start notes-->
    <ng-template #notesSection let-pageSection="pageSection">
      <div *ngIf="! loading" class="notespdf" formArrayName="notes">
        <div class="formbar row no-gutters">
          <form class="formnotesearch notes">
            <div class="col-2 px-1">Notes</div>
            <div class="col-5 px-1">
              <input class="noAutoSave inputsearchforms fromdate" type="date" min="2014-01-01" max="{{tomorrow | date: 'yyyy-MM-dd'}}"
                  [(ngModel)]="fromDate" name="fromDate" onkeydown="return false">
            </div>
            <div class="col-5 px-1">
              <input class="noAutoSave inputsearchforms todate" type="date" min="2014-01-01" max="{{tomorrow | date: 'yyyy-MM-dd'}}"
                  [(ngModel)]="toDate" name="toDate" onkeydown="return false">
              <button (click)="minimizeSection(pageSection)" type="button" class="btn minMax">
                <i *ngIf="!pageSection.minimized" class="pi pi-minus"></i>
                <i *ngIf="pageSection.minimized" class="pi pi-plus"></i>
              </button>
            </div>
            <div class="col-3 px-1">
              <!-- <select class="noAutoSave inputsearchforms" (change)="selectCategory($event)">
                <option *ngFor="let category of categories" [ngValue]="category.value">{{category.label}}</option>
              </select> -->
              <p-multiSelect [options]="categoriesToAddNote" appendTo="body" [(ngModel)]="selectedCategory" [ngModelOptions]="{standalone:true}"
              placeholder="Select categories" [filter]=true [resetFilterOnHide]="true" [maxSelectedLabels]="0" selectedItemsLabel="{0} selected">
              </p-multiSelect>
            </div>
            <div class="col-3 px-1">
              <select class="noAutoSave inputsearchforms" [(ngModel)]="filterType" [ngModelOptions]="{standalone:true}">
                <option value="all">all selected categories</option>
                <option value="any">any selected categories</option>
                <option value="not">exclude selected categories</option>
              </select>
            </div>
            <div class="col-6 px-1">
              <input class="noAutoSave inputsearchforms" type="search"
                  placeholder="Search Notes" [(ngModel)]="searchText" name="searchText">
            </div>
          </form>
          <div *ngIf="isNotLLMonitored" class="col-12">
            <div class="mx-1 px-1 py-1 mt-1 mb-0 alert alert-danger">
              Please note this order is monitoring by {{monitoringCompany}}, not by the Norwich ARC team.
            </div>
          </div>
        </div>
        <div class="formsect">
          <div [ngStyle]="{'display': pageSection.minimized? 'none': 'block'}">
            <div class="form-row note-list" id="note">
              <li class="note-l"
                  *ngFor="let note of notesForms.controls | filter : searchText : fromDate : toDate : selectedCategory: filterType;
                  let noteIndex=index"
                  [formGroupName]="noteIndex">
                <button type="button" class="noteBtn" (click)="confirmdeleteNote(noteIndex)" title="Delete Note">
                  <i class="fas fa-trash"></i>
                </button>
                <span *ngIf="note.value.userName === userName && !isNoteEditing[noteIndex]">
                  <button type="button" class="noteBtn" (click)="enableNoteEditing(note.value.userName, noteIndex)" title="Edit Note">
                    <i class="fas fa-edit"></i>
                  </button>
                </span>
                <span *ngIf="note.value.userName === userName && isNoteEditing[noteIndex]">
                  <button type="button" class="noteBtn" (click)="saveNote(noteIndex)" title="Save Note Changes">
                    <i class="fas fa-save"></i>
                  </button>
                  <button type="button" class="noteBtn" style="margin-left: 5px;" (click)="undoNote(noteIndex)" title="Undo Note Changes">
                    <i class="fas fa-times"></i>
                  </button>
                </span>
                <button type="button" class="noteBtn" (click)="emailNote(noteIndex)" title="Email Note">
                  <i class="fas fa-envelope"></i>
                </button>
                <button type="button" class="noteBtn" (click)="copyToClipboard(note.value.content)" title="Copy Note">
                  <i class="fas fa-copy"></i>
                </button>
                <div class="note-m">
                  <ng-container *ngIf="(note.value.userName !== userName) || !isNoteEditing[noteIndex]">
                    <span *ngFor="let category of note.value.categories; let i = index"
                        [ngStyle]="{'background-color':getCategoryColor(category)}">
                      {{category }}
                      <span *ngIf="i+1 !== note.value.categories.length">,</span>
                    </span>
                  </ng-container>
                  <p-multiSelect *ngIf="(note.value.userName === userName) && isNoteEditing[noteIndex]"
                    class="noAutoSave selectvategory"
                    [options]="categoriesToAddNote" formControlName="categories" scrollHeight="$1"
                    [panelStyle]="{minWidth:'12em'}" placeholder="Choose Categories"
                    appendTo="body">
                  </p-multiSelect>
                  Added By {{note.value.userName}}
                </div>
                <div class="note-m">
                  {{note.value.date | date:'EEEE, dd/MM/y - HH:mm'}}
                  <span *ngIf="note.value.updatedAt" class="note-m">
                    Edited: {{note.value.updatedAt | date:'EEEE, dd/MM/y - HH:mm'}}
                    <ng-template #noteToolipText>{{note.value.previousContent}}</ng-template>
                    <div class="noteTooltipLabel" placement="top" [ngbTooltip]="noteToolipText" tooltipClass="noteTooltip">
                      (view)
                    </div>
                  </span>
                </div>
                <textarea *ngIf="isNoteEditing[noteIndex]" class="noAutoSave noteContent" [rows]="5" [cols]="30" pInputTextarea
                    name="content" formControlName="content">
                </textarea>
                <div *ngIf="!isNoteEditing[noteIndex]" class="note-t" innerHTML="{{note.value.content |  highlight : searchText }} "></div>
              </li>
            </div>
            <div class="form-row py-2" [formGroup]="noteForm">
              <div class="form-group col-12" style="margin-bottom: 0px;">
                <textarea class="noAutoSave fullv notes" rows="4" cols="50"
                    placeholder="Enter notes here..." formControlName="content" (keypress)="addNoteIfNeeded($event)">
                </textarea>
              </div>
              <div class="col-6">
                <p-multiSelect class="noAutoSave selectvategory" #noteCategoriesSelect
                  [options]="categoriesToAddNote" formControlName="categories" scrollHeight="$1" (onPanelHide)="focusOnNoteCategories()"
                  [panelStyle]="{minWidth:'12em'}" placeholder="Choose Categories" (keypress)="addNoteIfNeeded($event)" [required]="true">
                </p-multiSelect>
              </div>
              <div class="col-6">
                <a class="head-button">
                  <button type="button " class="btn btn-primary" (click)="addNote()" (keypress)="addNoteIfNeeded($event)">
                    Add Note
                  </button>
                </a>
              </div>
              <div class="col-6">
                <label>Note Template</label>
                <p-dropdown [options]="noteTemplates" name="noteTemplates" [showClear]="true" placeholder="Select a template" id="noteTemplates"
                  (onChange)="changeNoteTemplate($event)" [filter]=true [resetFilterOnHide]="true" [optionLabel]="'label'" #templateDropdown>
                </p-dropdown>
              </div>
              <div class="col-6">
                <label>&nbsp;</label>
                <p-multiSelect class="noAutoSave selectvategory" #noteCategoriesFilterSelect
                  [options]="categoriesToAddNoteTemplateFilter" formControlName="filterCategories" scrollHeight="$1"
                  (onChange)="filterNoteTemplates($event)" [filter]=true [panelStyle]="{minWidth:'12em'}" placeholder="Filter note templates" [required]="true">
                </p-multiSelect>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <!-- end notes-->
    <!-- Start Tags-->
    <ng-template #tagsSection let-pageSection="pageSection">
      <ng-container *ngIf="! loading">
        <div class="formbar row no-gutters">
          <div class="col-12">
            Tags
            <button (click)="minimizeSection(pageSection)" type="button" class="btn minMax">
              <i *ngIf="!pageSection.minimized" class="pi pi-minus"></i>
              <i *ngIf="pageSection.minimized" class="pi pi-plus"></i>
            </button>
          </div>
        </div>
        <div class="formsect">
          <div [ngStyle]="{'display': pageSection.minimized? 'none': 'block'}">
            <div class="addTag" [formGroup]="tagForm">
              <div class="form-row">
                <div class="form-group col-10">
                  <p-dropdown [options]="tags" class="noAutoSave fullv"
                            (onChange)="clearExpiryOptions()"
                            name="selectedTag" formControlName="selectedTag"
                            placeholder="Tag" [filter]=true [resetFilterOnHide]="true">
                    <ng-template let-tag pTemplate="item">
                      <div [ngStyle]="{'background-color': tag.value.color}" class="p-1">
                        {{tag.label}}
                      </div>
                    </ng-template>
                  </p-dropdown>
                </div>
                <div class="form-group col-2 addtagdiv">
                  <button type="button" class="btn btn-primary" (click)="addtag()" [disabled]="!isOkToAddTag()">
                    Add Tag
                  </button>
                </div>
                <ng-container *ngIf="tagForm.value.selectedTag.allowExpiry">
                  <span class="form-group col-8">
                    <label class="form-label" for="expiryDate">Expire at the start of</label>
                    <input class="noAutoSave" type="date" formControlName="expiryDate" name="expiryDate" id="expiryDate"
                        onkeydown="return false" min="{{tomorrow | date: 'yyyy-MM-dd'}}" (change)="clearTagDoNotExpireIfNeeded()" />
                  </span>
                  <span class="form-group col-4">
                    <label class="form-check-label" for="doNotExpire">Do Not Expire</label>
                    <input class="noAutoSave" type="checkbox" formControlName="doNotExpire"  name="doNotExpire" id="doNotExpire"
                      (change)="clearTagExpiryIfNeeded()" />
                  </span>
                  <span class="alert-warning" *ngIf="!tagForm.value.doNotExpire && !tagForm.value.expiryDate">
                    You must either select an expiry date, or set the tag not to expire.
                  </span>
                </ng-container>
              </div>
              <span class="alert-warning" *ngIf="tagsErr">This selected tag can't be duplicated! </span>
            </div>
            <div class="form-row" formArrayName="tags">
              <div class=" input-group col-12" *ngFor="let tag of tagsForms.controls ; let tagIndex=index" [formGroupName]="tagIndex">
                <span [ngStyle]="{'background-color':tag.value.color}" class="tagfield">
                  {{tag.value.content}}
                  <ng-container *ngIf="tag.value.expiryDate">
                    <br/>Expires at the start of: {{tag.value.expiryDate | date:'dd/MM/yyyy' }}
                  </ng-container>
                </span>
                <button class="input-group-btn tagbutton" type="button" (click)="deleteTag(tagIndex)" title="Delete {{tag.value.content}} Tag">
                  <i class="fas fa-times"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-template>
    <!-- end Tags-->
    <div class="row">
      <ng-container *ngIf="isSmallerThanLarge && vim">
        <ng-container *ngTemplateOutlet="vim"></ng-container>
      </ng-container>

      <ng-template #column let-sections="sections" let-columnId="columnId" let-connectedTo="connectedTo">
        <div class="col-12 col-lg-6 px-1" cdkDropList (cdkDropListDropped)="dropPageSection($event)"
            [cdkDropListData]="sections" [cdkDropListConnectedTo]="connectedTo" [id]="columnId">
          <ng-container *ngIf="!isSmallerThanLarge && vim && (columnId === 'rightColumn')">
            <ng-container *ngTemplateOutlet="vim"></ng-container>
          </ng-container>
          <div cdkDrag *ngFor="let pageSection of sections" class="dragSection">
            <!--
              Drag handle does not work if in the template, so have to put it outside and position to the correct location
              as cancellationSection does not show unless the status is cancelling or cancelled have we do not want the
              drag handle showing up for it unless it's visible
            -->
            <div cdkDragHandle class="dragHandle"
                *ngIf="((pageSection.template !== 'cancellationSection') || (orderForm.value.status.status === 'cancelling') || (orderForm.value.status.status === 'cancelled'))">
              <svg width="24px" fill="white" viewBox="0 0 24 24">
                <path d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
                <path d="M0 0h24v24H0z" fill="none"></path>
              </svg>
            </div>
            <ng-container [ngSwitch]="pageSection.template">
              <app-order-links-section *ngSwitchCase="'orderLinksSection'" [pageSection]="pageSection" (minimizeSectionMethod)="minimizeSection($event)"
                [orderId]="orderDbId">
              </app-order-links-section>
              <app-initial-order-details-section *ngSwitchCase="'initialOrderSection'" [pageSection]="pageSection" (minimizeSectionMethod)="minimizeSection($event)">
              </app-initial-order-details-section>
              <app-initial-dispatch-section *ngSwitchCase="'initialDespatchSection'" [pageSection]="pageSection" (minimizeSectionMethod)="minimizeSection($event)">
              </app-initial-dispatch-section>
              <app-cancellation-section *ngSwitchCase="'cancellationSection'" [pageSection]="pageSection" (minimizeSectionMethod)="minimizeSection($event)"
                (addNoteFromSection)="addNoteFromSection($event)" (saveOrderFromSection)="autosave($event)">
              </app-cancellation-section>
              <app-reviews-section *ngSwitchCase="'reviewsSection'" [pageSection]="pageSection" (minimizeSectionMethod)="minimizeSection($event)"
                [orderId]="orderDbId">
              </app-reviews-section>
              <app-jontek-contacts-section *ngSwitchCase="'jontekContactsSection'" [pageSection]="pageSection" (minimizeSectionMethod)="minimizeSection($event)">
              </app-jontek-contacts-section>
              <app-customer-feedback-section *ngSwitchCase="'customerFeedbackSection'" [pageSection]="pageSection" [tags]="orderForm.value.tags"
                (minimizeSectionMethod)="minimizeSection($event)" [orderId]="orderDbId" [order]="order" [feedbackIdToEdit]="feedbackIdToEdit"
                (addNoteFromSection)="addNoteFromSection($event)"
                (removeActionFromSection)="removeActionFromSection($event)">
              </app-customer-feedback-section>
              <ng-container *ngSwitchDefault>
                <ng-container *ngTemplateOutlet="this[pageSection.template]; context: {'pageSection': pageSection}">
                </ng-container>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </ng-template>

      <!-- Start Left Column-->
      <ng-container *ngTemplateOutlet="column; context: {'sections': leftColumnSections, 'columnId': 'leftColumn', 'connectedTo': 'rightColumn'}">
      </ng-container>
      <!-- end Left Column-->
      <!-- Start Right Column-->
      <ng-container *ngTemplateOutlet="column; context: {'sections': rightColumnSections, 'columnId': 'rightColumn', 'connectedTo': 'leftColumn'}">
      </ng-container>
      <!-- end Right Column-->
    </div>
  </form> <!-- End order form (and end of autosave)-->
  <!-- Start Log -->
  <div class="changelogpdf">
    <p-treeTable id="changeLog" #changeLogTable [value]="historiesTree" [columns]="historiesCols" [rows]="30" [paginator]="true" [pageLinks]="3"
        [rowsPerPageOptions]="[10,20,30,50,100,200,500,1000]" [globalFilterFields]="historiesFilterFields">
      <ng-template pTemplate="caption">
        <div class="formbar row no-gutters">
          <div class="col-6">Change Log</div>
          <div class="col-6 text-right">
            <div [hidden]="!historiesShown" class="chlogs">
              <form class="formnotesearch">
                <input type="search" placeholder="" pInputText [(ngModel)]="searchLog" name="searchLog"
                    (input)="globalChangeLogFilter($event, 'contains')">
              </form>
            </div>
            <div *ngIf="historiesLoading" class="load-logs spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>
            <div *ngIf="!(historiesShown || historiesLoading)" class="load-logs">
              <button type="button" class="btn btn-primary" (click)="loadHistories()">Load Change Log</button>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
          <tr>
              <th *ngFor="let col of historiesCols">
                  {{col.header}}
              </th>
          </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
          <tr>
              <td *ngFor="let col of historiesCols; let colIndex = index">
                <p-treeTableToggler [rowNode]="rowNode" *ngIf="colIndex === 0"></p-treeTableToggler>
                {{rowData[col.field]}}
              </td>
          </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
          <tr>
              <td [attr.colspan]="historiesCols.length">No data found.</td>
          </tr>
      </ng-template>
    </p-treeTable>
  </div>
  <!-- end Log -->
</div>

<!-- put at the end so outside of autosave form event-->
<app-contact-attempt *ngIf="isOpenContactAttempt" (closeModal)="closeContactAttempt()" (updateOrder)="updateOrderAfterActionsAndVotings($event)"
    [orderData]="order" [outstandingActionName]="outstandingActionNameForContactAttempt">
</app-contact-attempt>
<app-renewal-update *ngIf="isRenewalInformationChangeDialogOpen" (updateOrderOnExpiryDateChange)="updateOrderOnExpiryDateChange($event)"
    (closeDialog)="closeExpiryDateChangeDialog()" [orderData]="order" [newExpiryDate]="orderForm.value.renewalInformation.renewalDate">
</app-renewal-update>
<app-renewal-price *ngIf="isRenewalPriceChangeDialogOpen" (closeDialog)="closeRenewalPriceChangeDialog()"
    (updateOrderOnRenewalPriceChange)="updateOrderOnRenewalPriceChange($event)" [orderData]="order"
    [newRenewalPrice]="orderForm.value?.renewalInformation?.renewalPrice">
</app-renewal-price>
<app-free-months-update *ngIf="isFreeMonthsChangeDialogOpen" (updateOrderOnFreeMonthsChange)="updateOrderOnFreeMonthsChange($event)"
    (closeDialog)="closeFreeMonthChangeDialog()" [orderData]="order">
</app-free-months-update>
<app-message-report *ngIf="isMessageReportOpen" [orderId]="orderDbId" [tdCode]="order.alarmUserDetails.tdCode" (closeModal)="closeMessageReport()">
</app-message-report>
<app-add-equipment-fault *ngIf="isAddFaultDialogOpen" (closeDialog)="closeAddFaultDialog($event)"
  [equipDetails]="equipDetailsForAddFault" [orderId]="orderDbId">
</app-add-equipment-fault>
<app-replacement-item *ngIf="displayReplacementItemPopup" (closePopup)="closeReplacementItemPopup()" [order]="order">
</app-replacement-item>
<app-email-send *ngIf="manualSendEmailMessageTypes.length > 0" [order]="orderForm.value" [tags]="orderForm.value.tags"
    [emailTypes]="manualSendEmailMessageTypes" [fieldInitialisation]="manualSendFieldInitialisation" (closeModal)="closeSendEmailDialog()">
</app-email-send>
<app-letter-send *ngIf="manualSendLetterMessageTypes.length > 0" [order]="order" [tags]="orderForm.value.tags"
    [letterTypes]="manualSendLetterMessageTypes" (closeModal)="closeSendLetterDialog()">
</app-letter-send>
<app-sms-send *ngIf="manualSendSmsMessageTypes.length > 0" [order]="order" [tags]="orderForm.value.tags"
    [smsTypes]="manualSendSmsMessageTypes" (closeModal)="closeSendSmsDialog()">
</app-sms-send>
<app-add-post-sheet *ngIf="showPostSheetAdd" openMode="addFromOrder" [order]="order" (closeAddPostSheet)="closeAddPostSheet($event)">
</app-add-post-sheet>
<app-refund-request *ngIf="showRefundRequestDialog" [order]="order" (closeModal)="closeShowRefundRequestDialog($event)">
</app-refund-request>
<app-price-book *ngIf="isPriceBookOpen" [order]="order" [allHardwareSets]="allHardwareSets" [allHardware]="allHardware" [allServices]="allServices"
    (closeModal)="closePriceBook()">
</app-price-book>
<app-email-note *ngIf="isEmailNoteOpen" [orderId]="orderDbId" [noteContent]="noteToEmailContent" (closeModal)="closeEmailNote()"
  [tdCode]="order.alarmUserDetails.tdCode" [custName]="order.alarmUserDetails.firstName + ' ' + order.alarmUserDetails.lastName"
  [alarmCode]="alarmCode">
</app-email-note>
<app-retention-dialog *ngIf="isRetentionDialogOpen" [order]="order" (closeModal)="closeRetentionDialog($event)" [retentionActionDate]="retentionActionDate">
</app-retention-dialog>
<app-cancelling-dialog *ngIf="showCancellingDialog" [emailTemplates]="initialCancellationEmailTemplates" [noEmailTagName]='noCancellingEmailTag' (closeModal)="closeCancellingDialog($event)">
</app-cancelling-dialog>
<app-third-party-payment-dialog *ngIf="showPaymentDataDialog" [order]="order" (closeModal)="closePaymentDataDialog()">
</app-third-party-payment-dialog>
<app-add-note *ngIf="addNoteContent" [noteContent]="addNoteContent" (closeModal)="closeAddNoteDialog($event)">
</app-add-note>
<app-ncf-dialog *ngIf="showNcfDialog" (closeModal)="closeNcfDialog($event)">
</app-ncf-dialog>
<router-outlet (window:unload)="close()"></router-outlet>
