<p-dialog (onHide)="closeDialog()" header="Contact Attempt" [(visible)]="display" [modal]="true"
    [style]="{width: '75vw',height:'auto'}" [maximizable]="false" [minY]="70" [baseZIndex]="3100"
    (onShow)="removeScrollBlock()">
    <div id="contactDialogBody">
        <div *ngIf="!isDateChange">
            <form [formGroup]="contactAttemptForm" (ngSubmit)="submitData()">
                <div class="row">
                    <div class="col-md-6 form-group">
                        <p-dropdown (onChange)="contactOptionChange()" [options]="contactOptions"
                            formControlName="selectedContactOption" placeholder="Choose Contact Option"
                            [showClear]="true" name="selectedContactOption" [required]=true>
                        </p-dropdown>
                        <div *ngIf="selectedContactOption.touched && selectedContactOption.invalid" class="alert alert-danger">
                            The contact attempt outcome is required
                        </div>
                    </div>
                    <div class="col-md-6 form-group"
                        *ngIf="selectedContactOption.value && (selectedContactOption.value !== 'Other - Override Action Date Manually')">
                        <p-dropdown [options]="phoneNumbersForSelect" formControlName="selectedNumber"
                            [placeholder]="placeholderForContactAttemptOption"
                            [showClear]="true" name="selectedNumber" [required]=true [autoDisplayFirst]=false
                            (onChange)="selectedNumberChange()">
                        </p-dropdown>
                        <div *ngIf="selectedNumber.touched && selectedNumber.invalid" class="alert alert-danger">
                            The phone number used must be selected
                        </div>
                    </div>
                    <div *ngIf="outstandingActionName === 'Low Battery' && selectedContactOption.value === 'Successful Contact - Action Complete'"
                        class="form-group col-12 col-md-6">
                        <legend class="col-form-label pt-0">Replacement items required?</legend>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="faultyRadio" id="faultyRadioYes" [value]="true"
                                formControlName="replacementItemRequired" [required]="selectedContactOption.value === 'Successful Contact - Action Complete'">
                            <label class="form-check-label" for="faultyRadioYes">
                                Yes
                            </label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="faultyRadio" id="faultyRadioNo" [value]="false"
                                formControlName="replacementItemRequired" [required]="selectedContactOption.value === 'Successful Contact - Action Complete'">
                            <label class="form-check-label" for="faultyRadioNo">
                                No
                            </label>
                        </div>
                    </div>
                    <div class="col-md-6 form-group" *ngIf="selectedContactOption.value === 'Other - Override Action Date Manually'">
                        <span class="p-float-label">
                            <input id="float-reason" type="search" class="w100" pInputText
                                formControlName="reasonToChangeDateManually" name="reasonToChangeDateManually" required>
                            <label for="float-reason" class="w100">Why do you want to change the Action Due Date?</label>
                        </span>
                        <div *ngIf="reasonToChangeDateManually.touched && reasonToChangeDateManually.invalid" class="alert alert-danger">
                            The reason for changing the due date is required.
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="selectedNumber.value === 'Other' && (selectedContactOption.value !== 'Other - Override Action Date Manually')">
                    <div class="row">
                        <div class="col-md-4 form-group">
                            <span class="p-float-label">
                                <input id="float-forename" type="text" class="w100" pInputText formControlName="accFirstName" name="accFirstName">
                                <label for="float-forename" class="w100">
                                    Enter First Name
                                </label>
                            </span>
                        </div>
                        <div class="col-md-4 form-group">
                            <span class="p-float-label">
                                <input id="float-othername" type="text" class="w100" pInputText formControlName="accOtherName" name="accOtherName">
                                <label for="float-othername" class="w100">
                                    Enter Other Name
                                </label>
                            </span>
                        </div>

                        <div class="col-md-4 form-group">
                            <span class="p-float-label">
                                <input id="float-surname" type="text" class="w100" pInputText formControlName="accLastName" name="accLastName">
                                <label for="float-surname" class="w100">
                                    Enter Last Name
                                </label>
                            </span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 form-group">
                            <span class="p-float-label">
                                <input id="float-email" type="email" pInputText formControlName="accEmail" name="accEmail" class="validated w100">
                                <label for="float-email" class="w100">Enter email</label>
                            </span>
                        </div>
                        <div class="col-md-6 form-group">
                            <span class="p-float-label">
                                <input id="float-rel" type="text" class="w100" pInputText formControlName="accRelationship" name="accRelationship">
                                <label for="float-rel" class="w100">Enter Relationship</label>
                            </span>
                        </div>
                    </div>
                    <div class="row" [ngClass]="contactAttemptForm.errors?.contactDetailsRequired? 'valueRequired': 'valueProvided'">
                        <div class="col-md-6">
                            <span class="p-float-label">
                                <input id="float-mobile" (keypress)="phoneNumberOnly($event)" type="text" pInputText
                                    formControlName="accMobileNumberToAdd" name="accMobileNumberToAdd" class="validated w100">
                                <label for="float-mobile" class="w100">
                                    Enter Mobile Number
                                </label>
                            </span>
                        </div>
                        <div class="col-md-6">
                            <span class="p-float-label">
                                <input id="float-tel" (keypress)="phoneNumberOnly($event)" type="text" pInputText
                                    formControlName="accTelephoneNumberToAdd" name="accTelephoneNumberToAdd" class="validated w100">
                                <label for="float-tel" class="w100">
                                    Enter Landline Number
                                </label>
                            </span>
                        </div>
                    </div>
                    <div class="row form-group" *ngIf="isFormSubmitted && (accMobileNumberToAdd.invalid && accTelephoneNumberToAdd.invalid)">
                        <div class="col-md-12">
                            <div class="alert alert-danger">
                                Either a mobile or landline number is required
                            </div>
                        </div>
                    </div>
                </ng-container>
                <div class="row" *ngIf="(selectedNumber.value || selectedContactOption.value === 'Other - Override Action Date Manually')">
                    <div class="col-md-12">
                        <h6>Additional Note</h6>
                        <textarea [rows]="3" [cols]="30" id="float-textarea" class="form-control" name="additionaNote" pInputTextarea
                            formControlName="additionalNote"></textarea>
                    </div>
                    <div class="col-12">
                      <label>Note Template</label>
                      <p-dropdown [options]="noteTemplates" name="noteTemplates" [showClear]="true" placeholder="Select a template" id="noteTemplates"
                        (onChange)="changeNoteTemplate($event)" [filter]=true [resetFilterOnHide]="true" [optionLabel]="'label'" [overlayOptions]="overlayOptions">
                      </p-dropdown>
                    </div>
                </div>
                <div class="row" *ngIf="selectedNumber.value && selectedContactOption.value === 'Unsuccessful Contact Attempt'">
                    <div class="col-md-6">
                        <p-checkbox name="group1" [binary]="true" label="Message left for customer?"
                          formControlName="isMessageLeftOnUnsuccessfulAttempt">
                        </p-checkbox>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 form-group">
                        <button type="submit" class="btn btn-primary" [disabled]="!contactAttemptForm.valid || isSubmitButtonDisabled">
                            Submit
                        </button>
                        <button type="button" class="btn btn-warning ml-2" (click)="closeDialog()">Cancel</button>
                    </div>
                </div>
            </form>
        </div>
        <div *ngIf="isDateChange">
            <form [formGroup]="dueDateForm" (ngSubmit)="submitDateChange()">
                <div class="row">
                    <div class="col-md-12 form-group">
                        <label>Change Action Due</label>
                        <input class="form-control" type="datetime-local" name="changedActionDueDate"
                            formControlName="changedActionDueDate" onkeydown="return false" min="{{today | date: 'yyyy-MM-ddTHH:mm'}}" required />
                        <div *ngIf="changedActionDueDate.touched && changedActionDueDate.invalid" class="alert alert-danger">
                            A new Due Date is required
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 form-group">
                        <button type="submit" class="btn btn-primary" [disabled]="!dueDateForm.valid || isDateChangeSubmitButtonDisabled">Submit</button>
                        <button type="button" class="btn btn-warning ml-2" (click)="closeDialog()">Cancel</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</p-dialog>
