<p-dialog (onHide)="onHideDialog()" header="Change Expiry Date" [(visible)]="display" [modal]="true"
    [style]="{width: '50vw',height:'auto'}" [maximizable]="false" [minY]="70" [baseZIndex]="3100"
    id="renewalUpdateDialog" (onShow)="removeScrollBlock()">
  <div id="renewalUpdateDialogBody">
    <form [formGroup]="renewalDateForm" (ngSubmit)="submitData()">
      <div class="row">
        <div class="col-md-12">
          <p-dropdown (onChange)="expiryDateChangeReasonChange()" [options]="expiryDateChangeReasons"
            placeholder="Why are you changing the Expiry Date?" [style]="{width:'100%'}" [showClear]="true"
            formControlName="selectedExpiryDateChangeReason" name="selectedExpiryDateChangeReason" [required]=true>
          </p-dropdown>
          <div *ngIf="selectedExpiryDateChangeReason.touched && selectedExpiryDateChangeReason.invalid" class="alert alert-danger">
            The reason you are changing the expiry date is required
          </div>
        </div>
      </div>
        <div *ngIf="selectedExpiryDateChangeReason.value === 'Successful Outbound Payment' || selectedExpiryDateChangeReason.value === 'Successful Inbound Payment'">
          <div class="row">
            <div class="col-md-6 form-group">
              <p-dropdown [options]="inboundOutboundPaymentModeOptions" formControlName="selectedInboundOutboundPaymentMode"
                placeholder="How was this payment made?" [style]="{width:'100%'}" [showClear]="true"
                name="inboundOutboundPaymentMode" [required]=true>
              </p-dropdown>
              <div *ngIf="selectedInboundOutboundPaymentMode.touched && selectedInboundOutboundPaymentMode.invalid" class="alert alert-danger">
                The payment method is required
              </div>
            </div>
            <div *ngIf="selectedInboundOutboundPaymentMode.value" class="col-md-6 form-group">
              <p-dropdown (onChange)="selectedNumberChange()" [options]="phoneNumbersForSelect" formControlName="selectedUserPhoneNumber"
                placeholder="Which number was payment made on?" [style]="{width:'100%'}" [showClear]="true"
                name="selectedUserPhoneNumber" [required]=true>
              </p-dropdown>
              <div *ngIf="selectedUserPhoneNumber.touched && selectedUserPhoneNumber.invalid" class="alert alert-danger">
                The number used in making the payment is required
              </div>
            </div>
          </div>
          <ng-container *ngIf="selectedUserPhoneNumber.value === 'Other'">
            <div class="row">
              <div class="col-md-4 form-group">
                <span class="p-float-label" width="100%">
                  <input id="float-forename" type="text" style="width: 100%;" pInputText formControlName="accFirstName" name="accFirstName">
                  <label for="float-forename" width="100%">
                    Enter First Name
                  </label>
                </span>
              </div>
              <div class="col-md-4 form-group">
                <span class="p-float-label" width="100%">
                  <input id="float-othername" type="text" style="width: 100%;" pInputText formControlName="accOtherName" name="accOtherName">
                  <label for="float-othername" width="100%">
                    Enter Other Name
                  </label>
                </span>
              </div>
              <div class="col-md-4 form-group">
                <span class="p-float-label" width="100%">
                  <input id="float-surname" type="text" style="width: 100%;" pInputText formControlName="accLastName" name="accLastName">
                  <label for="float-surname" width="100%">
                    Enter Last Name
                  </label>
                </span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 form-group">
                <span class="p-float-label" width="100%">
                  <input id="float-email" type="email" style="width: 100%;" pInputText formControlName="accEmail" name="accEmail" class="validated">
                  <label for="float-email" width="100%">Enter email</label>
                </span>
              </div>
              <div class="col-md-6 form-group">
                <span class="p-float-label" width="100%">
                  <input id="float-rel" type="text" style="width: 100%;" pInputText formControlName="accRelationship" name="accRelationship">
                  <label for="float-rel" width="100%">Enter Relationship</label>
                </span>
              </div>
            </div>
            <div class="row" [ngClass]="renewalDateForm.errors?.contactDetailsRequired? 'valueRequired': 'valueProvided'">
              <div class="col-md-6">
                <span class="p-float-label" width="100%">
                  <input id="float-mobile" (keypress)="phoneNumberOnly($event)" type="text" style="width: 100%;" pInputText
                    formControlName="accMobileNumberToAdd" name="accMobileNumberToAdd" class="validated">
                  <label for="float-mobile" width="100%">
                    Enter Mobile Number
                  </label>
                </span>
              </div>
              <div class="col-md-6">
                <span class="p-float-label" width="100%">
                  <input id="float-tel" (keypress)="phoneNumberOnly($event)" type="text" style="width: 100%;" pInputText
                    formControlName="accTelephoneNumberToAdd" name="accTelephoneNumberToAdd" class="validated">
                  <label for="float-tel" width="100%">
                    Enter Landline Number
                  </label>
                </span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 form-group" *ngIf="isFormSubmitted">
                  <div class="alert alert-danger">
                    Either a mobile or landline number is required
                  </div>
              </div>
            </div>
          </ng-container>
        </div>
        <div *ngIf="selectedExpiryDateChangeReason.value === 'Independent Customer Payment'">
          <div class="row">
            <div class="col-md-6 form-group">
              <p-dropdown [options]="independentCustomerPaymentOptions" formControlName="selectedIndependentCustomerPayment"
                placeholder="How was this payment made?" [style]="{width:'100%'}" [showClear]="true"
                name="selectedIndependentCustomerPayment" [required]=true>
              </p-dropdown>
              <div *ngIf="selectedIndependentCustomerPayment.touched && selectedIndependentCustomerPayment.invalid"
                  class="alert alert-danger">
                The payment method is required
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="selectedExpiryDateChangeReason.value === 'Account on Hold'">
          <div class="row">
            <div class="col-md-6 form-group">
              <p-dropdown [options]="accountOnHoldMonthOptions" formControlName="freeMonths"
                optionLabel="name" placeholder="How long the account is on hold for?" [style]="{width:'100%'}" [showClear]="true"
                name="selectedMonthForAccountOnHold" [required]=true>
              </p-dropdown>
              <div *ngIf="freeMonths.touched && freeMonths.invalid" class="alert alert-danger">
                The length of time the acount is on hold for is required
              </div>
            </div>
            <div *ngIf="freeMonths.value" class="col-md-6 form-group">
              <p-dropdown (onChange)="accountHoldReasonChange()" [options]="accountOnHoldReasons" formControlName="selectedReasonForAccountOnHold"
                placeholder="Why account is on hold?" [style]="{width:'100%'}" [showClear]="true"
                name="selectedReasonForAccountOnHold" [required]=true>
              </p-dropdown>
              <div *ngIf="selectedReasonForAccountOnHold.touched && selectedReasonForAccountOnHold.invalid" class="alert alert-danger">
                The reason the account is on hold is required
              </div>
            </div>
          </div>
          <div class="row">
            <div *ngIf="selectedReasonForAccountOnHold.value === 'Other'" class="col-md-6 form-group">
              <span class="p-float-label" width="100%">
                <input id="float-hold" type="text" style="width: 100%;" pInputText formControlName="otherReasonForAccountOnHold"
                   name="otherReasonForAccountOnHold" required>
                <label for="float-hold" width="100%">
                  Enter Reason For Account On Hold
                </label>
              </span>
              <div *ngIf="otherReasonForAccountOnHold.touched && otherReasonForAccountOnHold.invalid"
                  class="alert alert-danger">
                The reason the account is on hold is required
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="selectedExpiryDateChangeReason.value === 'Complaint'">
          <div class="row floatLabelSpace">
            <div class="col-md-12 form-group">
              <span class="p-float-label" width="100%">
                <p-dropdown [options]="complaintFreeMonthOptions" formControlName="freeMonths" id="complaintFreeMonths"
                  optionLabel="name" [style]="{width:'100%'}" [showClear]="true"
                  name="complaintFreeMonths" [required]=true>
                </p-dropdown>
                <label for="complaintFreeMonths" width="100%">How many free months have been given?</label>
              </span>
              <div *ngIf="freeMonths.touched && freeMonths.invalid" class="alert alert-danger">
                The number of free months given is required.
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="selectedExpiryDateChangeReason.value === 'Other'" class="row floatLabelSpace">
          <div class="col-md-12 form-group">
            <span class="p-float-label" width="100%">
              <input id="float-date" type="text" style="width: 100%;" pInputText formControlName="otherReasonForRenewalDateChange"
                name="otherReasonForRenewalDateChange" required>
              <label for="float-date" width="100%">
                Enter Reason to change renewal date
              </label>
            </span>
            <div *ngIf="otherReasonForRenewalDateChange.touched && otherReasonForRenewalDateChange.invalid"
                class="alert alert-danger">
              The reason for the renewal date change is required
          </div>
        </div>
      </div>
      <div class="row" *ngIf="selectedExpiryDateChangeReason.value">
        <div class="col-md-12">
          <h6>Additional Note</h6>
          <textarea [rows]="3" [cols]="30" id="float-textarea" style="width: 100%;" name="additionaNote" pInputTextarea
            formControlName="additionalNote"></textarea>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 form-group">
          <button type="submit" class="btn btn-primary" [disabled]="!renewalDateForm.valid || isSubmitButtonDisabled">Submit</button>
          <button type="button" class="btn btn-warning ml-2" (click)="onHideDialog()">Cancel</button>
        </div>
      </div>
    </form>
  </div>
</p-dialog>
