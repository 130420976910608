import { Component, OnInit } from '@angular/core';
import {ShopifyWebhookLog} from '../../models/reporting/shopifyWebhookLog.model';
import {Column} from '../../models/column.model';
import {Title} from '@angular/platform-browser';
import {LogsService} from '../logs/logs.service';
import {MultiRecordResponse} from '../../models/responses/multiRecordResponse.model';
import {ConfirmationService, OverlayOptions, SelectItem} from 'primeng/api';
import {numberOnly} from '../../helpers/keyboardHelpers';
import {shopifyTopics} from '../../lookups/reporting/shopifyTopics';
import {DEFAULT_OVERLAY} from '../../lookups/primeng/overlayOptions';
import {BrandCfg, getBrandConfigs, getBrandSelectItems} from '../../lookups/brands';
import {SimpleResponse} from '../../models/responses/simpleResponse.model';
import {UsersService} from '../../setup/users/users.service';

@Component({
  selector: 'app-shopify-webhook-logs',
  templateUrl: './shopify-webhook-logs.component.html',
  styleUrl: './shopify-webhook-logs.component.scss',
  providers: [ConfirmationService]
})
export class ShopifyWebhookLogsComponent implements OnInit {
  webhookLogs: ShopifyWebhookLog[];
  columns: Column[];
  logsLoading: boolean = false;
  shopifyOrderId: string;
  numberOnly = numberOnly;
  JSON = JSON;
  logsLoadedFor: string;
  shopifyTopicOptions = shopifyTopics;
  selectedTopic: string;
  overlayOptions: OverlayOptions = DEFAULT_OVERLAY;
  runningUpdate: boolean;
  selectedBrand: string;
  websiteId: string;
  brandConfigs: BrandCfg;
  brands: SelectItem<string>[];
  userCanReprocess: boolean;

  constructor(
    private title: Title,
    private logsService: LogsService,
    private confirmationService: ConfirmationService,
    private userService: UsersService,
  ) {
  }

  ngOnInit() {
    this.title.setTitle('CRM Shopify Webhook Logs');
    this.runningUpdate = false;
    this.userCanReprocess = this.userService.userHasPermission('Reprocess Shopify Webhook');
    this.shopifyOrderId = '';
    this.logsLoadedFor = '';
    this.selectedTopic = '';
    this.selectedBrand = undefined;
    this.websiteId = '';
    this.webhookLogs = [];
    this.columns = [
      {field: 'webhookTopic', header: 'Topic'},
      {field: 'hmac', header: 'HMAC'},
      {field: 'shopifyOrderId', header: 'Shopify Order Id'},
      {field: 'processingResult', header: 'Processing Result'},
      {field: 'createdBy', header: 'Created By'},
      {field: 'createdAt', header: 'Created'},
    ];
    this.brandConfigs = getBrandConfigs();
    this.brands = getBrandSelectItems();
  }

  loadWebhookLogs() {
    this.logsLoadedFor = '';
    this.websiteId = this.brandConfigs[this.selectedBrand]._id;
    this.logsService.getShopifyWebhookLogs(this.websiteId, this.shopifyOrderId).subscribe({
      'next': (response: MultiRecordResponse<ShopifyWebhookLog>) => {
        if (response.success) {
          this.webhookLogs = response.data;
          this.logsLoadedFor = this.shopifyOrderId;
        } else {
          this.showErrorPopUp('Error loading logs', response.message);
        }
      },
      'error': (err: Error) => {
        this.showErrorPopUp('Error loading logs', err.message);
      }
    });
  }

  canRunUpdate(): boolean {
    if (this.runningUpdate) {
      return false;
    }
    return (!!this.logsLoadedFor && !!this.websiteId && !!this.selectedTopic);
  }

  runShopifyUpdate() {
    this.runningUpdate = true;
    this.logsService.runShopifyUpdate(this.websiteId, this.selectedTopic, this.logsLoadedFor).subscribe({
      'next': (response: SimpleResponse) => {
        this.runningUpdate = false;
        this.loadWebhookLogs();
        if (response.success) {
          this.showInfoPopUp('Response to processing', response.message);
        } else {
          this.showErrorPopUp('Error processing Update', response.message);
        }
      },
      'error': (err: Error) => {
        this.runningUpdate = false;
        this.showErrorPopUp('Error processing Update', err.message);
      }
    });

  }

  showInfoPopUp(header: string, message: string): void {
    this.showPopUp('general', header, message, 'pi pi-info-circle');
  }

  showErrorPopUp(header: string, message: string): void {
    this.showPopUp('error', header, message, 'pi pi-exclamation-triangle');
  }

  showPopUp(key: string, header: string, message: string, icon: string): void {
    this.confirmationService.confirm({
      key: key,
      message: message,
      header: header,
      rejectVisible: false,
      acceptLabel:'OK',
      icon: icon,
      accept: () => {
      },
      reject: () => {
      }
    });
  }
}