import {SelectItem} from 'primeng/api';

const shopifyTopics: SelectItem<string>[] = [
  { label: 'orders/create', value: 'orders/create' },
  { label: 'orders/paid', value: 'orders/paid' },
  { label: 'orders/fulfilled', value: 'orders/fulfilled' },
];

export {
  shopifyTopics,
};
