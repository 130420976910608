import {SelectItem} from 'primeng/api';
import {importTypes} from '../lookups/importTypes';
import {ImportType} from '../models/importType.model';

const permissions: SelectItem<string>[] = [
  { label: 'Bulk Order Entry', value: 'Bulk Order Entry' },
  { label: 'Business Growth', value: 'Business Growth' },
  { label: 'Can delete leads', value: 'Can delete leads' },
  { label: 'Core CRM Access', value: 'Core CRM Access' },
  { label: 'Delete Customer Feedback', value: 'Delete Customer Feedback' },
  { label: 'Delete Order', value: 'Delete Order' },
  { label: 'Delete Users', value: 'Delete Users' },
  { label: 'Edit Old Alarm Codes', value: 'Edit Old Alarm Codes' },
  { label: 'Export Customer Feedback', value: 'Export Customer Feedback' },
  { label: 'Export Orders', value: 'Export Orders' },
  { label: 'Fault Admin', value: 'Fault Admin' },
  { label: 'Maintain Actions', value: 'Maintain Actions' },
  { label: 'Maintain Announcements', value: 'Maintain Announcements' },
  { label: 'Maintain Cancellation Links', value: 'Maintain Cancellation Links' },
  { label: 'Maintain Hardware', value: 'Maintain Hardware' },
  { label: 'Maintain Manuals', value: 'Maintain Manuals' },
  { label: 'Maintain Products', value: 'Maintain Products' },
  { label: 'Maintain Renewal Links', value: 'Maintain Renewal Links' },
  { label: 'Maintain Roles', value: 'Maintain Roles' },
  { label: 'Maintain Tags', value: 'Maintain Tags' },
  { label: 'Maintain Tooltips', value: 'Maintain Tooltips' },
  { label: 'Maintain Users', value: 'Maintain Users' },
  { label: 'Maintain Websites', value: 'Maintain Websites' },
  { label: 'Marketing Maintenance', value: 'Marketing Maintenance' },
  { label: 'NCC Order Entry', value: 'NCC Order Entry' },
  { label: 'Reprocess Shopify Webhook', value: 'Reprocess Shopify Webhook' },
  { label: 'Send Pendant Test Emails', value: 'Send Pendant Test Emails' },
  { label: 'Set Order to Cancelling', value: 'Set Order to Cancelling' },
  { label: 'View Logs', value: 'View Logs' },
  { label: 'View SAP Data', value: 'View SAP Data', 'disabled': true },
  { label: '*Super Admin', value: 'Super Admin' },

];
permissions.push(
  ...importTypes.map((element: ImportType) => {
    return {
      // Prefix the label with Import, so it's clear that's what these are
      label: `Import: ${element.label}`,
      value: element.label,
    }
  })
);


export {
  permissions,
};