import {Component, OnInit, ViewChild} from '@angular/core';
import {ConfirmationService, MessageService, OverlayOptions, SelectItem} from 'primeng/api';
import {BrandCfg, getBrandConfigs, getBrandSelectItems} from '../../lookups/brands';
import {MultiRecordResponse} from '../../models/responses/multiRecordResponse.model';
import {SingleRecordResponse} from '../../models/responses/singleRecordResponse.model';
import {PopulatedProduct} from '../../models/product.model';
import {ProductsService} from './products.service';
import {PopulatedVariation} from '../../models/variation.model';
import {vatStatuses} from '../../lookups/vatStatuses';
import {Hardware} from '../../models/hardware.model';
import {HardwareService} from '../hardware.service';
import {HardwareSet} from '../../models/hardwareSet.model';
import {EX_TO_INC_VAT_MULTIPLIER, sortByLabel} from '../../helpers/helperFunctions';
import {Title} from '@angular/platform-browser';
import {AccountService} from '../../models/accountService.model';
import {Website} from '../../models/website.model';
import {RawShopifyProduct, RawShopifyVariant} from '../../models/shopifyProduct.model';
import {RefreshStatus} from '../../models/responses/refreshStatus.model';
import {Column} from '../../models/column.model';
import {Table} from 'primeng/table';
import {WOO_PRODUCT_CATEGORY_OPTS} from '../../lookups/productCategories';
import {DEFAULT_OVERLAY} from '../../lookups/primeng/overlayOptions';
import {rentalPeriodOptions} from '../../lookups/rentalPeriodOptions';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
  providers: [MessageService, ConfirmationService]
})
export class ProductsComponent implements OnInit {
  overlayOptions: OverlayOptions = DEFAULT_OVERLAY;
  processing: boolean = false;
  progressVal: number;
  progress: number;
  products: PopulatedProduct[] = [];
  shopifyProducts: RawShopifyProduct[] = [];
  display: boolean = false;
  brandConfigs: BrandCfg;
  brands: SelectItem<string>[];
  selectedBrand: string;
  selectedWebsite: Website;
  currencyCode: string;
  vatStatuses: SelectItem<string>[] = vatStatuses;
  hardwareList: SelectItem<Hardware>[] = [];
  hardwareSetList: SelectItem<HardwareSet>[] = [];
  shopifyBaseUnitList: SelectItem<string>[] = [];
  shopifyGpsList: SelectItem<string>[] = [];
  shopifyAccessoryList: SelectItem<string>[] = [];
  exToIncVatMultiplier: number = EX_TO_INC_VAT_MULTIPLIER;
  shopifyTags: string[] = [];

  accountServicesList: SelectItem<string>[] = [];
  statuses: SelectItem<string>[] = [{
    'label': 'Not Configured',
    'value': 'not configured'
  }, {
    'label': 'Configured',
    'value': 'configured'
  }, {
    'label': 'Archived',
    'value': 'archived'
  }];
  selectedStatuses: string[] = [];
  @ViewChild('shopifyProductTable', {static: false})
  shopifyProductTable: Table;
  @ViewChild('productRefreshTable', {static: false})
  productRefreshTable: Table;
  ids: string = '';
  refreshingProducts: boolean;
  showRefreshResponse: boolean;
  refreshResponses: RefreshStatus[] = [];
  shopifyProductsColumns: Column[] = [{
    'field': 'shopifyProductId',
    'header': 'Product Id',
  }, {
    'field': 'crmProductTitle',
    'header': 'CRM Title',
  }, {
    'field': 'displayOnCseOrderPage',
    'header': 'Show on Order page',
  }, {
    'field': 'crmCategory',
    'header': 'Product Type',
  }, {
    'field': 'tags',
    'header': 'Shopify Tags',
  }, {
    'field': 'shopifyVariants',
    'header': 'Varaints',
  }, {
    'field': 'status',
    'header': 'Status',
  }];
  refreshColumns: Column[] = [{
    'field': 'identifier',
    'header': 'Id',
  }, {
    'field': 'status',
    'header': 'Status',
  }];
  yesNoOptions: SelectItem<string>[] = [{
    'label': 'Yes',
    'value': 'yes',
  }, {
    'label': 'No',
    'value': 'no',
  }];
  rentalPeriodOptions: SelectItem<string>[] = rentalPeriodOptions;
  productStatusOptions: SelectItem<string>[] = [{
    'label': 'Not Configured',
    'value': 'not configured',
  }, {
    'label': 'Configured',
    'value': 'configured',
  }, {
    'label': 'Archived',
    'value': 'archived',
  }];
  wooProductCategories: SelectItem<string>[] = WOO_PRODUCT_CATEGORY_OPTS;

  constructor(
    private productsService: ProductsService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private hardwareService: HardwareService,
    private title: Title,
  ) {
  }

  allUpdate() {
    this.progress = 0;
    this.progressVal = 0;
    this.display = true;
    this.products.map((product: PopulatedProduct) => {
      this.saveProd(product._id, product);
      product.variations.map((variation: PopulatedVariation) => {
        this.saveVariation(variation._id, variation);
      });
    });
  };

  saveProd(id: string, product: PopulatedProduct) {
    this.productsService.updateProduct(id, {'product': product}).subscribe({
      next: (response: SingleRecordResponse<PopulatedProduct>) => {
        this.progress += 1 / this.products.length;
        this.progressVal = Math.floor(this.progress * 100)+1;
        if (!response.success) {
          this.showErrorPopUp('Error saving', `Error saving product ${product.sku} ${response.message}: ${response.error?.message}`);
        }
      },
      error: (err: any) => {
        this.showErrorPopUp('Error saving', `Error saving product ${product.sku} ${err.message}`);
      }
    });
  }

  saveVariation(id: number, variation: PopulatedVariation) {
    this.productsService.updateVariation(id, {'variation': variation}).subscribe({
      next: (response: SingleRecordResponse<PopulatedVariation>) => {
        if (!response.success) {
          this.showErrorPopUp('Error saving', `Error saving variation ${variation.sku} ${response.message}: ${response.error?.message}`);
        }
      },
      error: (err: any) => {
        this.showErrorPopUp('Error saving', `Error saving variation ${variation.sku} ${err.message}`);
      }
    });
  }

  ngOnInit() {
    this.refreshingProducts = false;
    this.showRefreshResponse = false;
    this.brandConfigs = getBrandConfigs();
    this.brands = getBrandSelectItems();
    this.progress = -1;
    this.selectedStatuses.push('not configured');
    this.selectedStatuses.push('configured');
    this.title.setTitle('CRM Products');
  }

  get isRefreshProductsDisabled(): boolean {
    return this.refreshingProducts || this.ids.trim() == '';
  }

  loadSiteProducts() {
    this.display = false;
    this.progress = -1;
    this.selectedWebsite = undefined;
    if (!this.selectedBrand || !this.brandConfigs[this.selectedBrand]) {
      return;
    }
    this.selectedWebsite = this.brandConfigs[this.selectedBrand];
    if (!this.selectedWebsite) {
      return;
    }
    this.currencyCode = 'GBP';
    if (this.selectedWebsite.title == 'LLIE') {
      this.currencyCode = 'EUR';
    }
    this.loadProducts();
    this.hardwareService.getHardwareForSite(this.selectedWebsite._id).subscribe({
      next: (response: MultiRecordResponse<Hardware>) => {
        if (response.success) {
          this.hardwareList = response.data.map((hardware: Hardware) => {
            return {
              'label': hardware.title,
              'value': hardware,
            };
          });
          sortByLabel(this.hardwareList);
          this.shopifyBaseUnitList = [];
          this.shopifyGpsList = [];
          this.shopifyAccessoryList = [];
          this.hardwareList.forEach((hwSelect: SelectItem<Hardware>) => {
            if (hwSelect.value.category == 'Base Unit') {
              this.shopifyBaseUnitList.push({
                'label': hwSelect.label,
                'value': hwSelect.value._id
              });
            } else if (hwSelect.value.category == 'GPS Alarm') {
              this.shopifyGpsList.push({
                'label': hwSelect.label,
                'value': hwSelect.value._id
              });
            } else if (hwSelect.value.category == 'Pendant/Accessory') {
              this.shopifyAccessoryList.push({
                'label': hwSelect.label,
                'value': hwSelect.value._id
              });
            }
          });
        } else {
          this.showErrorPopUp('Error loading hardware', `Error loading hardware ${response.message}`);
        }
      },
      error: (err: Error) => {
        console.log('ERROR while getting hardware: ', err);
        this.showErrorPopUp('Error loading hardware', `Error loading hardware ${err.message}`);
      }
    });
    this.hardwareService.getHardwareSetsForSite(this.selectedWebsite._id).subscribe({
      next: (response: MultiRecordResponse<HardwareSet>) => {
        if (response.success) {
          this.hardwareSetList = response.data.map((hardwareSet: HardwareSet) => {
            return {
              'label': hardwareSet.title,
              'value': hardwareSet,
            }
          });
          sortByLabel(this.hardwareSetList);
        } else {
          this.showErrorPopUp('Error loading hardware sets', `Error loading hardware sets ${response.message}`);
        }
      },
      error: (err: Error) => {
        console.log('ERROR while getting hardware sets: ', err);
        this.showErrorPopUp('Error loading hardware sets', `Error loading hardware sets ${err.message}`);
      }
    });
    this.hardwareService.getServicesForSite(this.selectedWebsite._id).subscribe({
      next: (response: MultiRecordResponse<AccountService>) => {
        if (response.success) {
          this.accountServicesList = response.data.map((accountService: AccountService) => {
            return {
              'label': accountService.title,
              'value': accountService._id,
            }
          });
          sortByLabel(this.accountServicesList);
        } else {
          this.showErrorPopUp('Error loading services', `Error loading services ${response.message}`);
        }
      },
      error: (err: Error) => {
        console.log('ERROR while getting account services: ', err);
        this.showErrorPopUp('Error loading services', `Error loading services ${err.message}`);
      }
    });
  }

  loadProducts() {
    if (!this.selectedWebsite) {
      return;
    }
    if (this.selectedWebsite.usingWebBackend == 'Shopify') {
      this.shopifyTags = [];
      this.productsService.getAllShopifyProductsForSite(this.selectedWebsite._id).subscribe({
        next: (response: MultiRecordResponse<RawShopifyProduct>) => {
          if (!response.success) {
            this.showErrorPopUp('Error loading products', `Error loading products ${response.message}`);
            return;
          }
          this.shopifyProducts = response.data;
          this.shopifyProducts.forEach((shopifyProduct: RawShopifyProduct) => {
            shopifyProduct.tags.forEach((tag: string) => {
              if (!this.shopifyTags.includes(tag)) {
                this.shopifyTags.push(tag);
              }
            });
          });
          this.shopifyTags.sort();
        },
        error: (err: any) => {
          this.showErrorPopUp('Error loading products', `Error loading products ${err.message}`);
        }
      })
    } else {
      this.productsService.getAllProductsForSite(this.selectedWebsite._id).subscribe({
        next: (response: MultiRecordResponse<PopulatedProduct>) => {
          if (!response.success) {
            this.showErrorPopUp('Error loading products', `Error loading products ${response.message}`);
            return;
          }
          this.products = response.data;
          const temp: PopulatedProduct[] = [];
          // Make sure sub products come after their parent
          for (let prod of this.products) {
            if (prod.subProducts.length > 0) {
              temp.push(prod);
              for (let id of prod.subProducts) {
                let p: PopulatedProduct = this.products.find((p: PopulatedProduct) => (id == p.productWebID));
                if ((p !== undefined) && (p !== null)) {
                  p.isSub = true;
                  temp.push(p);
                }
              }
            }
          }
          // Add any remaining products not already added
          for (let prod of this.products) {
            if (!temp.includes(prod)) {
              temp.push(prod);
            }
          }
          this.products = temp;
        },
        error: (err: any) => {
          this.showErrorPopUp('Error loading products', `Error loading products ${err.message}`);
        }
      });
    }
  }

  deleteHardwareSet(item: PopulatedProduct|PopulatedVariation, index: number): void {
    item.hardwareSets.splice(index, 1);
  }

  addHardwareSet(item: PopulatedProduct|PopulatedVariation, newHardwareSet: HardwareSet): void {
    if (!newHardwareSet) {
      return;
    }
    item.hardwareSets.push({
      '_id': newHardwareSet._id,
      'title': newHardwareSet.title,
    });
  }

  deleteHardware(item: PopulatedProduct|PopulatedVariation, index: number): void {
    item.hardware.splice(index, 1);
  }

  addHardware(item: PopulatedProduct|PopulatedVariation, newHardware: Hardware): void {
    if (!newHardware) {
      return;
    }
    item.hardware.push({
      '_id': newHardware._id,
      'title': newHardware.title,
    });
  }

  setCategory(product: PopulatedProduct) {
    if (product.category == 'keySafe') {
      product.vat = 'not exempt';
      product.renewalPrice = 0;
      product.planOrEquipment = '';
      product.renewalAfterDate = 0;
      product.variations.forEach((variation: PopulatedVariation) => {
        variation.vat = 'not exempt';
        variation.renewalPrice = 0;
        variation.symbol = '';
        variation.renewalAfterDate = 0;
        variation.hardware = [];
        variation.hardwareSets = [];
      });
      product.hardware = [];
      product.hardwareSets = [];
    }
    if (product.category == 'services') {
      product.hardware = [];
      product.hardwareSets = [];
      product.variations.forEach((variation: PopulatedVariation) => {
        variation.hardware = [];
        variation.hardwareSets = [];
      });
    } else {
      product.serviceId = null;
    }
  }

  refreshProducts() {
    this.showRefreshResponse = false;
    if (!this.selectedWebsite) {
      return;
    }
    if (!this.ids && !this.ids.trim()) {
      this.showErrorPopUp('No Codes Supplied', 'You must specify the codes to import/refresh.');
    }
    this.refreshingProducts = true;
    this.productsService.refreshShopifyProducts(this.selectedWebsite._id, this.ids.split('\n')).subscribe({
      next: (response: MultiRecordResponse<RefreshStatus>) => {
        this.refreshingProducts = false;
        if (!response.success) {
          this.showErrorPopUp('Error Importing/Refreshing Products', `Error importing/refreshing products ${response.message}`);
        } else {
          this.refreshResponses = response.data;
          this.showRefreshResponse = true;
          this.loadProducts();
        }
      },
      error: (err: any) => {
        this.refreshingProducts = false;
        this.showErrorPopUp('Error Importing/Refreshing Products', `Error importing/refreshing products ${err.message}`);
      }
    });
  }

  validToSaveShopifyProduct(shopifyProduct: RawShopifyProduct): boolean {
    const errors: string[] = [];
    if (!shopifyProduct.crmProductTitle) {
      errors.push('enter a title on the product');
    }
    shopifyProduct.shopifyVariants.forEach((variant: RawShopifyVariant) => {
      if (!variant.crmVariantTitle) {
        errors.push('enter a title on each variant');
      }
      if (!shopifyProduct.isBundle && ['Base Unit', 'GPS Alarm', 'Additional Equipment', 'Discontinued'].includes(shopifyProduct.crmCategory)) {
        if (variant.hardware == null) {
          errors.push('set hardware on each base unit, gps alarm or additional equipment');
        }
      }
    });
    if (errors.length > 0) {
      this.showInfoPopUp('Invalid Information', `You must ${errors.join(', ')} before saving.`);
      return false;
    } 
    return true;
  }

  saveShopifyProduct(shopifyProduct: RawShopifyProduct): void {
    if (!this.validToSaveShopifyProduct(shopifyProduct)) {
      return;
    }
    shopifyProduct.updatedBy = localStorage.getItem('userName');
    this.productsService.updateShopifyProduct(shopifyProduct._id, {
      'shopifyProduct': shopifyProduct
    }).subscribe({
      next: (rsp: SingleRecordResponse<RawShopifyProduct>) => {
        if (!rsp.success || !rsp.data) {
          this.showErrorPopUp('Error Updating Product', rsp.message || 'Something went wrong when trying to update the product. Please try again.');
        } else {
          this.showSuccess();
          const tempFirst: number = this.shopifyProductTable.first;
          this.shopifyProducts = [...[rsp.data], ...this.shopifyProducts.filter((currentProduct: RawShopifyProduct) => 
            currentProduct._id && (currentProduct._id !== rsp.data._id)
          )];
          this.shopifyProductTable.first = tempFirst;
        }
      }, 
      error: (err: Error) => {
        this.showErrorPopUp('Error Updating Product',
            `Something went wrong when trying to update the product. Please try again. Error: ${err.message}`);
      }
    });
  }

  applyProductFilter($event: Event, field: string, filterType: string): void {
    this.shopifyProductTable.filter(($event.target as HTMLInputElement).value, field, filterType);
  }

  applyVariantFilter(event: Event, field: string, filterType: string, subfields: string[]): void {
    subfields.unshift((event.target as HTMLInputElement).value);
    this.shopifyProductTable.filter(subfields, field, filterType);
  }

  applyProductRefreshFilter($event: Event, field: string, filterType: string): void {
    this.productRefreshTable.filter(($event.target as HTMLInputElement).value, field, filterType);
  }

  showInfoPopUp(header: string, message: string) {
    this.showPopUp(header, message, 'pi pi-info-circle');
  }

  showErrorPopUp(header: string, message: string) {
    this.showPopUp(header, message, 'pi pi-exclamation-triangle');
  }

  showPopUp(header: string, message: string, icon: string) {
    this.confirmationService.confirm({
      key: 'general',
      message: message,
      header: header,
      rejectVisible: false,
      acceptLabel:'OK',
      icon: icon,
      accept: () => {
      },
      reject: () => {
      }
    });
  }

  showSuccess() {
    this.messageService.add({
      severity: 'success',
      life: 1000,
      summary: 'Success Update!',
      detail: 'Changes Successfully Applied',
    });
  }
}
