<div class="mb-xl-2"></div>
<div class="container-fluid" id="logTable">
  <p-table [value]="webhookLogs" [paginator]="true" [rows]="30" [sortField]="'createdAt'" [sortOrder]=-1 [sortMode]="'single'"
      [columns]="columns" dataKey="_id">
    <ng-template pTemplate="caption">
      <input id="shopifyOrderId" type="text" (keypress)="numberOnly($event, false)" [(ngModel)]="shopifyOrderId"/>
      <span class="load-logs">
        <p-dropdown [options]="brands" appendTo="body" [(ngModel)]="selectedBrand" placeholder="Brands" scrollHeight="600px" [overlayOptions]="overlayOptions">
          <ng-template let-Pbrand let-i="index" pTemplate="item">
            <span class="brandDropDown" [ngStyle]="{'background-color': brandConfigs[Pbrand.value].background, 'color': brandConfigs[Pbrand.value].color}">
              &nbsp;{{Pbrand.value}}
            </span>
          </ng-template>
        </p-dropdown>
      </span>
      <span class="load-logs" *ngIf="!logsLoading else logsLoadingBlock">
        <button type="button" class="btn btn-primary" (click)="loadWebhookLogs()" [disabled]="(shopifyOrderId === '') || (selectedBrand === undefined)">
          Load Webhook Logs
        </button>
      </span>
      <ng-template #logsLoadingBlock>
        <div class="load-logs spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </ng-template>
    </ng-template>

    <ng-template pTemplate="header">
      <tr>
        <th class='toggleColumn'>&nbsp;</th>
        <ng-container *ngFor="let column of columns">
          <th [pSortableColumn]="column.field">
            {{column.header}}
            <p-sortIcon [field]="column.field" ariaLabel="Activate to sort"
              ariaLabelDesc="Activate to sort in descending order" 
              ariaLabelAsc="Activate to sort in ascending order">
            </p-sortIcon>
          </th>  
        </ng-container>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-webhookLog let-columns="columns" let-expanded='expanded'>
      <tr>
        <td>
          <button type="button" pButton pRipple [pRowToggler]="webhookLog" class="p-button-plain"
              [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'">
          </button>
        </td>
        <td *ngFor="let column of columns" [ngSwitch]="column.field">
          <div *ngSwitchCase="'createdAt'">
            {{webhookLog[column.field] | date:'dd/MM/y HH:mm'}}
          </div>
          <div *ngSwitchDefault>
              {{webhookLog[column.field]}}
          </div>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="rowexpansion" let-webhookLog>
      <tr>
        <td>&nbsp;</td>
        <td colspan='5'>{{JSON.stringify(webhookLog.webhookPayload, null, 2)}}</td>
      </tr>
    </ng-template>

    <ng-template pTemplate="summary">
      There are {{webhookLogs?.length}} logs
    </ng-template>
  </p-table>
  <div class='row' *ngIf='userCanReprocess && (logsLoadedFor !== "")'>
    <div class='col-12'>
      You can manually run an update using the below options. It will go through the same processing that the notifications from Shopify
      will have, so if the log records indicate there is something stopping the processing and that issue has not been resolved it will still fail.
      <br/>
      You can only run an update for the Shopify order id that you are viewing the logs for. This is so you have at least seen the logs before trying the import.
    </div>
    <div class='col-12'>
      <label for='topicSelection'>Run Shopify Update for order {{logsLoadedFor}}</label>
      <p-dropdown [options]="shopifyTopicOptions" class="ml-2" [overlayOptions]="overlayOptions" placeholder="--Please Select a Topic--"
          [(ngModel)]="selectedTopic">
      </p-dropdown>
      <button type="button" class="btn btn-primary ml-2" (click)="runShopifyUpdate()" [disabled]="!canRunUpdate()">
        Run Update
      </button>
    </div>
  </div>
</div>
<p-confirmDialog key="general"></p-confirmDialog>
<p-confirmDialog key="error"></p-confirmDialog>