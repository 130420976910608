import {SelectItem} from 'primeng/api';

const WOO_PRODUCT_CATEGORY_OPTS: SelectItem<string>[] = [{
  'label': 'Quarterly Plan',
  'value': 'quarterlyPlan',
},{
  'label': 'Annual Plan',
  'value': 'annualPlan',
},{
  'label': 'Lifetime Plan',
  'value': 'lifetimePlan',
},{
  'label': 'Key Safe',
  'value': 'keySafe',
},{
  'label': 'Additional Equipment',
  'value': 'additionalEquipment',
},{
  'label': 'Installations',
  'value': 'installations',
}, {
  'label': 'Cables',
  'value': 'cables',
}, {
  'label': 'Discontinued',
  'value': 'discontinued',
}];

const SHOPIFY_PRODUCT_CATEGORY_OPTS: SelectItem<string>[] = [{
  'label': 'Plans',
  'value': 'Plans',
},{
  'label': 'Key Safes',
  'value': 'Key Safes',
},{
  'label': 'Additional Equipment',
  'value': 'Additional Equipment',
},{
  'label': 'Installations',
  'value': 'Installations',
}, {
  'label': 'Cables/Chargers',
  'value': 'Cables/Chargers',
}, {
  'label': 'Gift Cards/Vouchers',
  'value': 'Gift Cards/Vouchers',
// }, {
//   'label': 'Discontinued',
//   'value': 'Discontinued',
}];

export {
  WOO_PRODUCT_CATEGORY_OPTS,
  SHOPIFY_PRODUCT_CATEGORY_OPTS,
};
