import {StringIndexedObject} from './utility/stringIndexedObject.model';

const CRM_CLIENT_VERSION: string = '2024-11-27';

interface Environment {
  'production': boolean;
  'IPAddress': string;
  'ioAddress': string;
  'webName': string;
  'navBarColour': string;
  'protocol': string;
  'stripeKeys': StringIndexedObject<string>;
  'getAddressDomainToken': string;
  'evoBaseUrl': string;
}

export {
  Environment,
  CRM_CLIENT_VERSION,
};
