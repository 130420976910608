import {BreakpointObserver, BreakpointState} from '@angular/cdk/layout';
import {Component, EventEmitter, OnInit, Output, Input, OnDestroy} from '@angular/core';
import {AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import moment from 'moment-timezone';
import {ConfirmationService, OverlayOptions, SelectItem} from 'primeng/api';
import {Subscription} from 'rxjs';
import {environment} from '../../../environments/environment';
import {getWorkingDaysBetween} from '../../helpers/helperFunctions';
import {numberOnly, phoneNumberOnly} from '../../helpers/keyboardHelpers';
import {getBrandBackgroundColour, getBrandColour} from '../../lookups/brands';
import {getCustomerFeedbackContactMethods, getCustomerFeedbackMainReasons,
  getCustomerFeedbackPartnerships, getCustomerFeedbackSpecificReasonsForMainReason, getCustomerFeedbackStatuses} from '../../lookups/customerFeedback';
import {CustomerFeedback, FeebackContactAttempt} from '../../models/customerFeedback.model';
import {SimpleResponse} from '../../models/responses/simpleResponse.model';
import {SingleRecordResponse} from '../../models/responses/singleRecordResponse.model';
import {SelectItemWithEmail} from '../../models/selectItemWithEmail.model';
import {CustFeedbackLockData} from '../../models/socket-io/custFeedbackLockData.model';
import {OrderLockData} from '../../models/socket-io/orderLockData.model';
import {LocksSocketService} from '../../sockets/locks-socket.service';
import {contactDetailsRequiredValidator} from '../../validators/contact-details-required.validator';
import {getExternalEmailValidator, multiEmailValidator} from '../../validators/email.validator';
import {phoneNumberValidator} from '../../validators/phone-number.validator';
import {CustomerFeedbackService} from '../customer-feedback.service';
import {OrderIdPlanOnly} from '../../models/mongoose-populated-ids/orderIdPlanOnly.model';
import {DEFAULT_OVERLAY} from '../../lookups/primeng/overlayOptions';

@Component({
  selector: 'app-edit-customer-feedback[feedbackId][fromOrder][closeModal]',
  templateUrl: './edit-customer-feedback.component.html',
  styleUrls: ['./edit-customer-feedback.component.scss'],
  providers: [ConfirmationService]
})
export class EditCustomerFeedbackComponent implements OnInit, OnDestroy {
  @Input() feedbackId: string;
  @Input() fromOrder: boolean;
  @Output() closeModal: EventEmitter<boolean> = new EventEmitter<boolean>();
  user: string;
  documentBlocked: boolean = false;
  closing: boolean = false;
  custFeedbackEditingBy: CustFeedbackLockData;
  orderLockList: OrderLockData[] = [];
  socketId: string;
  widthChangeSubscription: Subscription;
  isSmallerThanLarge: boolean;
  // feedbackTypes: SelectItem<string>[];
  feedbackPartnerships: SelectItemWithEmail<string>[];
  feedbackMainReasons: SelectItemWithEmail<string>[];
  feedbackSpecificReasons: SelectItem<string>[];
  feedbackContactMethods: SelectItem<string>[];
  feedbackStatuses: SelectItem<string>[];
  dialogVisible: boolean;
  customerFeedbackForm: UntypedFormGroup;
  saveDisabled: boolean;
  emailRequired: boolean;
  resultMessage: string;
  originalFeedback: CustomerFeedback;
  minDateStr: string;
  escalateTo: SelectItemWithEmail<string>;
  numberOnly = numberOnly;
  phoneNumberOnly = phoneNumberOnly;
  getBrandBackgroundColour = getBrandBackgroundColour;
  getBrandColour = getBrandColour;
  feedbackPriorities: SelectItem<string>[] = [{
    'label': 'Low',
    'value': 'Low'
  }, {
    'label': 'Medium',
    'value': 'Medium'
  }, {
    'label': 'High',
    'value': 'High'
  }];
  overlayOptions: OverlayOptions = DEFAULT_OVERLAY;

  constructor(
    private customerFeedbackService: CustomerFeedbackService,
    private locksSocket: LocksSocketService,
    private formBuilder: UntypedFormBuilder,
    private confirmationService: ConfirmationService,
    private breakpointObserver: BreakpointObserver,
  ) { }

  ngOnInit(): void {
    this.escalateTo = undefined;
    this.documentBlocked = false;
    this.user = localStorage.getItem('userName');
    // this.feedbackTypes = getCustomerFeedbackTypes();
    this.feedbackPartnerships = getCustomerFeedbackPartnerships();
    this.feedbackMainReasons = getCustomerFeedbackMainReasons();
    this.feedbackSpecificReasons = [];
    this.feedbackContactMethods = getCustomerFeedbackContactMethods();
    this.feedbackStatuses = getCustomerFeedbackStatuses();
    this.locksSocket.emit('lockingCustFeedback', {
      id: this.feedbackId,
      user: this.user
    }, (lockResponse: CustFeedbackLockData) => {
        if (lockResponse.added) {
          this.documentBlocked = false;
          this.socketId = lockResponse.socketId;
          this.custFeedbackEditingBy = lockResponse;
        } else {
          this.custFeedbackEditingBy = lockResponse;
          this.documentBlocked = true;
          this.socketId = '';
        }
        this.loadCustomerFeedback();
    });

    this.locksSocket.on('lockedCustFeedback', this.custFeedbackLockUpdate);

    this.locksSocket.on('custFeedbackLockList', this.custFeedbackLockListUpdate);
    this.locksSocket.on('lockList', this.orderLockListUpdate);
    this.locksSocket.emit('getLocked');
    this.isSmallerThanLarge = this.breakpointObserver.isMatched('(max-width: 991px)');
    this.widthChangeSubscription = this.breakpointObserver.observe([
      '(max-width: 991px)'
    ]).subscribe((result: BreakpointState) => {
      this.isSmallerThanLarge = result.matches;
    });
  }

  ngOnDestroy() {
    // Don't want to remove all listeners here as the parent component is listening on this socket too
    this.locksSocket.removeListener('lockedCustFeedback', this.custFeedbackLockUpdate);
    this.locksSocket.removeListener('custFeedbackLockList', this.custFeedbackLockListUpdate);
    this.locksSocket.removeListener('lockList', this.orderLockListUpdate);
    this.closing = true;
    if (!this.documentBlocked) {
      this.documentBlocked = true;
      this.locksSocket.emit('unlockingCustFeedback', {'id': this.feedbackId, 'user': this.user});
    }
    this.widthChangeSubscription.unsubscribe;
  }

  loadCustomerFeedback(): void {
    this.customerFeedbackService.getSpecificCustomerFeedback(this.feedbackId).subscribe((response: SingleRecordResponse<CustomerFeedback>) => {
      if ((response.success) && (response.data)) {
        this.originalFeedback = response.data;
        let paymentRestartDateStr: string = '';
        if (this.originalFeedback.datePaymentRestarts) {
          // because this does not have a time component DB always stores it in UTC
          paymentRestartDateStr = moment.utc(this.originalFeedback.datePaymentRestarts).format('YYYY-MM-DD');
        }
        this.customerFeedbackForm = this.formBuilder.group({
          'datePending': [this.originalFeedback.datePending],
          'closedBy': [this.originalFeedback.closedBy],
          'dateClosed': [this.originalFeedback.dateClosed],
          'feedbackType': [this.originalFeedback.feedbackType, Validators.required],
          'priority': [this.originalFeedback.priority, Validators.required],
          'partnership': [this.originalFeedback.partnership, Validators.required],
          'status': [this.originalFeedback.status, Validators.required],
          'resolutionDays': [this.originalFeedback.resolutionDays],
          'pendingDays': [this.originalFeedback.pendingDays],
          'whoContactedUs': [this.originalFeedback.whoContactedUs, Validators.required],
          'relationshipToUser': [this.originalFeedback.relationshipToUser, Validators.required],
          'emailAddress': [this.originalFeedback.emailAddress, getExternalEmailValidator(false)],
          'phoneNumber': [this.originalFeedback.phoneNumber, phoneNumberValidator],
          'contactMethod': [this.originalFeedback.contactMethod, Validators.required],
          'mainReason': [this.originalFeedback.mainReason, Validators.required],
          'specificReason': [this.originalFeedback.specificReason, Validators.required],
          'feedbackDetails': [this.originalFeedback.feedbackDetails, Validators.required],
          'resolvedAtFirstContact': [this.originalFeedback.resolvedAtFirstContact],
          'ccTo': [this.originalFeedback.ccTo, multiEmailValidator],
          'resolution': [this.originalFeedback.resolution],
          'respondByEmail': [this.originalFeedback.respondByEmail],
          'freeServiceGiven': [this.originalFeedback.freeServiceGiven],
          'datePaymentRestarts': [{value: paymentRestartDateStr, disabled: !this.originalFeedback.freeServiceGiven}, Validators.required],
          'chaserEmailSent': [this.originalFeedback.chaserEmailSent],
          'contactAttempts': this.formBuilder.array([]),
        }, {'validators': contactDetailsRequiredValidator('Feedback Contact', 'emailAddress', 'phoneNumber')});
        this.minDateStr = moment.tz(this.originalFeedback.dateLogged, 'Europe/London').format('YYYY-MM-DD');
        this.originalFeedback.contactAttempts.forEach((contactAttempt: FeebackContactAttempt) => {
          this.contactAttemptsForms.push(
            this.formBuilder.group({
              'byUsername': contactAttempt.byUsername,
              'dateOfAttempt': contactAttempt.dateOfAttempt
            })
          );
        });
        this.changeMainReason(this.customerFeedbackForm.get('mainReason').value, false);
        this.updateEmailAddressRequired();
        this.saveDisabled = false;
        this.dialogVisible = true;
      } else if (!response.data) {
        this.showErrorPopUp('Error loading customer feedback', 'Customer feedback not found.');
      } else {
        this.showErrorPopUp('Error loading customer feedback', response.message || 'Something went wrong try again.');
      }
    }, (err: Error) => {
      console.log('ERROR while getting customer feedback: ', err);
      this.showErrorPopUp('Error loading customer feedback', `Something went wrong try again. Error: ${err.message}`);
    });
  }

  custFeedbackLockUpdate: (lockedCustFeedback: CustFeedbackLockData) => void = (lockedCustFeedback: CustFeedbackLockData) => {
    if (lockedCustFeedback.socketId === this.socketId) {
      this.custFeedbackEditingBy = lockedCustFeedback;
      this.documentBlocked = true;
    }
  }

  custFeedbackLockListUpdate: (custFeedbackLockList: CustFeedbackLockData[]) => void = (lockedCustFeedbackRecords: CustFeedbackLockData[]) => {
    // Stop it trying to relock the message if the page is closing
    if (this.closing) {
      return;
    }
    if (this.documentBlocked && lockedCustFeedbackRecords.filter((lockData: CustFeedbackLockData) => lockData.id == this.feedbackId).length == 0) {
      this.locksSocket.emit('lockingCustFeedback', { id: this.feedbackId, user: this.user }, (currentCustFeedback: CustFeedbackLockData) => {
        if (currentCustFeedback.added) {
          this.socketId = currentCustFeedback.socketId;
          this.loadCustomerFeedback();
          this.documentBlocked = false;
        }
      });
    }
  }

  orderLockListUpdate: (lockList: OrderLockData[]) => void = (orderLocks: OrderLockData[]) => {
    this.orderLockList = orderLocks;
  }

  isOrderLocked(_id: string|OrderIdPlanOnly): boolean {
    const orderId: string = (typeof _id == 'string')? _id: _id._id;
    return this.orderLockList.filter((lockedOrder: OrderLockData) => lockedOrder.orderId == orderId).length > 0;
  }

  orderLockedBy(_id: string|OrderIdPlanOnly): string {
    const orderId: string = (typeof _id == 'string')? _id: _id._id;
    return this.orderLockList.filter((lockedOrder: OrderLockData) => lockedOrder.orderId == orderId)[0].user;
  }

  get isFreeServiceGiven(): boolean {
    return this.customerFeedbackForm.get('freeServiceGiven').value;
  }

  get isResolutionRequired(): boolean {
    return (['Closed'].includes(this.customerFeedbackForm.get('status').value) &&
      ['Complaint'].includes(this.customerFeedbackForm.get('feedbackType').value));
  }

  get contactAttemptsForms(): UntypedFormArray {
    return (this.customerFeedbackForm.get('contactAttempts') as UntypedFormArray);
  }

  get isOkToSave(): boolean {
    return this.customerFeedbackForm.valid && !this.saveDisabled;
  }

  get isOkToAddContactAttempt(): boolean {
    return !this.saveDisabled && !this.isStatusReadOnly;
  }

  get isStatusReadOnly(): boolean {
    return (this.customerFeedbackForm.get('resolvedAtFirstContact').value ||
      !['Complaint'].includes(this.customerFeedbackForm.get('feedbackType').value));
  }

  unlock() {
    this.confirmationService.confirm({
      'key': 'error',
      'message':
        `${this.custFeedbackEditingBy.user} is curently editing this customer feedback, do you want to have full access permission? ` +
        `Warning: any changes made by ${this.custFeedbackEditingBy.user} will be lost`,
      'header': 'Warning',
      'icon': 'pi pi-info-circle',
      accept: () => {
        this.locksSocket.emit('unlockingCustFeedback-F', { id: this.feedbackId, user: this.user }, (lockData: CustFeedbackLockData) => {
          if (lockData.added) {
            this.socketId = lockData.socketId;
            this.loadCustomerFeedback();
            this.documentBlocked = false;
          }
        })
      },
      reject: () => {

      }
    })
  }

  changeMainReason(newReason: string, clearSpecificReason: boolean): void {
    if (!newReason) {
      this.feedbackSpecificReasons = [];
    } else {
      this.feedbackSpecificReasons = getCustomerFeedbackSpecificReasonsForMainReason(newReason);
    }
    if (clearSpecificReason) {
      this.customerFeedbackForm.get('specificReason').setValue('');
    }
  }

  changeFeedbackStatus(newStatus: string): void {
    if ('Pending' == newStatus) {
      const pendingDate: string = (new Date()).toISOString();
      const resolutionDays: Number = getWorkingDaysBetween(this.originalFeedback.dateLogged, pendingDate);
      this.customerFeedbackForm.patchValue({
        'datePending': pendingDate,
        'closedBy': '',
        'dateClosed': null,
        'resolutionDays': resolutionDays,
        'pendingDays': null,
      });
    } else if ('Closed' == newStatus) {
      const closedDate: string = (new Date()).toISOString();
      this.customerFeedbackForm.patchValue({
        'dateClosed': closedDate,
        'closedBy': localStorage.getItem('userName'),
      })
      // Only update resolution days if not already set
      if (!this.customerFeedbackForm.get('resolutionDays').value) {
        const resolutionDays: Number = getWorkingDaysBetween(this.originalFeedback.dateLogged, closedDate);
        this.customerFeedbackForm.patchValue({
          'resolutionDays': resolutionDays,
        });
      }
      // Only update pending days if the feedback had been to pending
      if (this.customerFeedbackForm.get('datePending').value) {
        const pendingDays: Number = getWorkingDaysBetween(this.customerFeedbackForm.get('datePending').value, closedDate);
        this.customerFeedbackForm.patchValue({
          'pendingDays': pendingDays,
        });
      }
    } else {
      this.customerFeedbackForm.patchValue({
        'datePending': null,
        'closedBy': '',
        'dateClosed': null,
        'resolutionDays': null,
        'pendingDays': null,
        'chaserEmailSent': false,
      });
    }
    const resolutionControl: AbstractControl = this.customerFeedbackForm.get('resolution');
    if (this.isResolutionRequired) {
      resolutionControl.setValidators(Validators.required);
    } else {
      resolutionControl.clearValidators();
    }
    resolutionControl.updateValueAndValidity();
  }

  changeResolvedAtFirstContact(event: Event): void {
    if ((event.target as HTMLInputElement).checked && (this.customerFeedbackForm.get('status').value != 'Closed')) {
      this.customerFeedbackForm.patchValue({
        'status': 'Closed',
      });
      this.changeFeedbackStatus('Closed');
    }
  }

  updateEmailAddressRequired(): void {
    const emailControl: AbstractControl = this.customerFeedbackForm.get('emailAddress');
    const respondByEmail: boolean = this.customerFeedbackForm.get('respondByEmail').value;
    const contactMethod: string = this.customerFeedbackForm.get('contactMethod').value;
    if (respondByEmail || ('Email' == contactMethod)) {
      this.emailRequired = true;
      emailControl.setValidators([Validators.required, getExternalEmailValidator(false)]);
    } else {
      this.emailRequired = false;
      emailControl.setValidators(getExternalEmailValidator(false));
    }
    emailControl.updateValueAndValidity();
  }

  saveFeedback(): void {
    this.resultMessage = '';
    this.saveDisabled = true;
    // Need to getRawValue to include disabled fields, else Mongo won't blank those fields in the DB
    this.customerFeedbackService.saveCustomerFeedback(
      this.feedbackId, {
      'feedback': this.customerFeedbackForm.getRawValue(),
      'crmBaseUrl': `${environment.protocol}${environment.IPAddress}/`,
    }).subscribe((response: SimpleResponse) => {
      this.saveDisabled = false;
      if (response.success) {
        let complaintClosed: boolean = false;
        if (('Complaint' == this.originalFeedback.feedbackType) && ('Closed' != this.originalFeedback.status) &&
            ('Closed' == this.customerFeedbackForm.get('status').value)) {
          complaintClosed = true;
        }
        this.closeModal.emit(complaintClosed);
      } else {
        this.resultMessage = `Error saving Customer Feedback. Error: ${response.message}`;
      }
    }, (err: Error) => {
      this.saveDisabled = false;
      this.resultMessage = `Error saving Customer Feedback. Error: ${err.message}`;
    });
  }

  addContactAttempt(): void {
    this.contactAttemptsForms.push(
      this.formBuilder.group({
        'byUsername': localStorage.getItem('userName'),
        'dateOfAttempt': new Date(),
      })
    );
  }

  deleteContactAttempt(index: number): void {
    this.contactAttemptsForms.removeAt(index);
  }

  closeDialog(): void {
    this.closeModal.emit(false);
    this.closing = true;
    this.dialogVisible = false;
    if (!this.documentBlocked) {
      this.documentBlocked = true;
      this.locksSocket.emit('unlockingCustFeedback', {'id': this.feedbackId, 'user': this.user});
    }
  }

  close() {
    this.closing = true;
    this.dialogVisible = false;
    if (!this.documentBlocked) {
      this.documentBlocked = true;
      this.locksSocket.emit('unlockingCustFeedback', {'id': this.feedbackId, 'user': this.user});
    }
  }

  freeServiceChanged(): void {
    if (this.isFreeServiceGiven) {
      this.customerFeedbackForm.get('datePaymentRestarts').enable();
    } else {
      this.customerFeedbackForm.get('datePaymentRestarts').patchValue(null);
      this.customerFeedbackForm.get('datePaymentRestarts').disable();
    }
  }

  showInfoPopUp(header: string, message: string): void {
    this.showPopUp('general', header, message, 'pi pi-info-circle');
  }

  showErrorPopUp(header: string, message: string): void {
    this.showPopUp('error', header, message, 'pi pi-exclamation-triangle');
  }

  showPopUp(key: string, header: string, message: string, icon: string): void {
    this.confirmationService.confirm({
      key: key,
      message: message,
      header: header,
      rejectVisible: false,
      acceptLabel:'OK',
      icon: icon,
      accept: () => {
      },
      reject: () => {
      }
    });
  }
}
