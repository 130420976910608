const DD_GUARANTEE: string = `
•	The Guarantee is offered by all banks and building societies that accept instructions to pay Direct Debits.
•	If there are any changes to the amount, date or frequency of your Direct Debit we will notify you
	(normally 10 working days) in advance of your account being debited or as otherwise agreed. If you request us
	to collect a payment, confirmation of the amount and date will be given to you at the time of the request.
•	If an error is made in the payment of your Direct Debit, by us or your bank or building society,
	you are entitled to a full and immediate refund of the amount paid from your bank or building society.
		o	If you receive a refund you are not entitled to, you must pay it back when we asks you to.
•	You can cancel a Direct Debit at any time by simply contacting your bank or building society. Written confirmation 
	may be required. Please also notify us.`;

export {
  DD_GUARANTEE,
};
