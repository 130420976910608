<div class="mb-xl-2"></div>
<div class="container-fluid" id="cseOrderRportTable">
  <p-table #cseOrderTable [value]="cseOrderRecords" [paginator]="true" [rows]="30" dataKey="_id"
      [columns]="selectedOrderCols" [multiSortMeta]="[{field: 'createdAt', order: -1}]" [sortMode]="'multiple'"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="Page {currentPage} of {totalPages} - Orders {first} to {last} of {totalRecords}">
    <ng-template pTemplate="caption">
      <i class="fa fa-search" style="margin:4px 4px 0 0"></i>
      <label class="load-orders" for="startDate">Orders Between:</label>
      <input id="startDate" type="date" autocomplete="off" #startDate min="{{minDateStr}}" max="{{maxDateStr}}" value="{{defaultStartDateStr}}"/>
      <label class="load-orders" for="endDate">and:</label>
      <input id="endDate" type="date" autocomplete="off" #endDate min="{{minDateStr}}" max="{{maxDateStr}}" value="{{maxDateStr}}"/>
      <span class="load-orders" *ngIf="!recordsLoading else recordsLoadingBlock">
        <button type="button" class="btn btn-primary" (click)="loadOrderRecords(startDate.value, endDate.value)" >Load Orders</button>
      </span>
      <span>
        <label class="load-orders" for="orderColsSelect">Order Fields:</label>
        <p-multiSelect id='orderColsSelect' [options]="orderCols" [(ngModel)]="selectedOrderCols" optionLabel="header" [maxSelectedLabels]=2
              selectedItemsLabel="{0} columns selected" [style]="{minWidth: '200px'}" placeholder="Filter Columns" scrollHeight="600px"
              (onChange)="orderColumnsChanged($event)">
        </p-multiSelect>
      </span>
      <span>
        <label class="load-orders" for="lineColsSelect">Line Fields:</label>
        <p-multiSelect id='lineColsSelect' [options]="lineCols" [(ngModel)]="selectedLineCols" optionLabel="header" [maxSelectedLabels]=2
              selectedItemsLabel="{0} columns selected" [style]="{minWidth: '200px'}" placeholder="Filter Columns" scrollHeight="600px"
              (onChange)="lineColumnsChanged($event)">
        </p-multiSelect>
      </span>
      <div ngbDropdown class="d-inline-flex">
        <button type="button" id="exportCseOrders" ngbDropdownToggle class="ml-2 btn btn-primary" aria-controls="exportCseOrdersControl"
            [disabled]="recordsLoading || (cseOrderRecords.length === 0)">
          Export
        </button>
        <div ngbDropdownMenu aria-labelledby="exportCseOrders" id="exportCseOrdersOptions">
          <button ngbDropdownItem type="button" (click)="exportCseOrders()">
            Order Level
          </button>
          <button ngbDropdownItem type="button" (click)="exportCseOrderLines()">
            Line Level
          </button>
        </div>
      </div>
      <ng-template #recordsLoadingBlock>
        <div class="load-orders spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </ng-template>
    </ng-template>

    <ng-template pTemplate="header" let-columns>
      <tr>
        <th class='toggleColumn'>&nbsp;</th>
        <ng-container *ngFor="let orderCol of columns">
          <th [pSortableColumn]="orderCol.field">
            {{orderCol.header}}
            <p-sortIcon [field]="orderCol.field" ariaLabel="Activate to sort"
              ariaLabelDesc="Activate to sort in descending order" 
              ariaLabelAsc="Activate to sort in ascending order">
            </p-sortIcon>
          </th>
        </ng-container>
      </tr>
      <tr>
        <th class='toggleColumn'>&nbsp;</th>
        <th *ngFor="let orderCol of columns">
          <ng-container [ngSwitch]="orderCol.field">
            <ng-container *ngSwitchCase="'createdAt'">
              <div>
                <p-calendar [appendTo]="'body'" [selectionMode]="'range'" [readonlyInput]=true [dateFormat]="'dd/mm/yy'"
                    [(ngModel)]="dateFilters" (onSelect)="cseOrderTable.filter(dateFilters, orderCol.field, 'date-range')"
                    (onClearClick)="cseOrderTable.filter(dateFilters, orderCol.field, 'date-range')" [yearRange]=filterYearRange
                    [monthNavigator]=true [yearNavigator]=true [showButtonBar]=true [keepInvalid]=false
                    [minDate]=filterMinDate [maxDate]=filterMaxDate [numberOfMonths]="1"
                    [showOtherMonths]=true [selectOtherMonths]=true [showIcon]=true 
                    [style]="{width: '100%'}" [inputStyle]="{width: '100%'}">
                </p-calendar>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="'websiteId'">
              <p-multiSelect [options]="brands" [(ngModel)]="selectedBrand" placeholder="Brand"
                  filterPlaceHolder="brand" scrollHeight="600px" [maxSelectedLabels]=0
                  selectedItemsLabel="{0} selected" [style]="{'display':'flex'}" [overlayOptions]="overlayOptions"
                  (onChange)="cseOrderTable.filter($event.value, orderCol.field, 'brand-website')" >
                <ng-template let-Pbrand let-i="index" pTemplate="item">
                  <div class="brandBar" [ngStyle]="{'background-color': brandConfigs[Pbrand.value].background, 'color':brandConfigs[Pbrand.value].color}">
                    &nbsp;{{Pbrand.value}}
                  </div>
                </ng-template>
              </p-multiSelect>
            </ng-container>
            <ng-container *ngSwitchDefault>
              <input pInputText type="search" (input)="applyFilter($event, orderCol.field, 'contains')" style="width: 100%;" />
            </ng-container>
          </ng-container>
        </th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-cseOrder let-expanded='expanded' let-columns='columns'>
      <tr>
        <td>
          <button type="button" pButton pRipple [pRowToggler]="cseOrder" class="p-button-plain"
            [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'">
          </button>
        </td>
        <ng-container *ngFor="let orderCol of columns">
          <ng-container [ngSwitch]="orderCol.field">
            <td *ngSwitchCase="'createdAt'">{{cseOrder.createdAt | date:'dd/MM/yyyy HH:mm'}}</td>
            <td *ngSwitchCase="'websiteId'">
              <div class="brandBar" [ngStyle]="{
                'background-color': brandConfigs[cseOrder.websiteId.title].background, 'color':brandConfigs[cseOrder.websiteId.title].color
              }">
                {{cseOrder.websiteId.title}}
              </div>
            </td>
            <td *ngSwitchCase="'originalOrderTotal'" class='number'>
              {{cseOrder.originalOrderTotal | currency :(cseOrder.websiteId.title === 'LLIE'? 'EUR': 'GBP'):'symbol':'1.2-2'}}
            </td>
            <td *ngSwitchCase="'finalOrderTotal'" class='number'>
              {{cseOrder.finalOrderTotal | currency :(cseOrder.websiteId.title === 'LLIE'? 'EUR': 'GBP'):'symbol':'1.2-2'}}
            </td>
            <td *ngSwitchCase="'finalOrderVat'" class='number'>
              {{cseOrder.finalOrderVat | currency :(cseOrder.websiteId.title === 'LLIE'? 'EUR': 'GBP'):'symbol':'1.2-2'}}
            </td>
            <td *ngSwitchCase="'proRataCurrentPrice'" class='number'>
              {{cseOrder.proRataCurrentPrice | currency :(cseOrder.websiteId.title === 'LLIE'? 'EUR': 'GBP'):'symbol':'1.2-2'}}
            </td>
            <td *ngSwitchCase="'couponAmount'" class='number'>
              {{cseOrder.couponAmount | currency :(cseOrder.websiteId.title === 'LLIE'? 'EUR': 'GBP'):'symbol':'1.2-2'}}
            </td>
            <td *ngSwitchDefault>
              {{cseOrder[orderCol.field]}}
            </td>
          </ng-container>
        </ng-container>
      </tr>
    </ng-template>

    <ng-template pTemplate="rowexpansion" let-cseOrder>
      <tr>
        <td>&nbsp;</td>
        <td [attr.colspan]="selectedOrderCols.length">
          <p-table #cseOrderLineTable [value]="cseOrder.cseOrderItems" [columns]="selectedLineCols"
              [multiSortMeta]="[{field: 'cseOrderLine', order: 1}, {field: 'cseOrderLinePart', order: 1}]" [sortMode]="'multiple'">
            <ng-template pTemplate="header" let-columns>
              <tr>
                <ng-container *ngFor="let lineCol of columns">
                  <th [pSortableColumn]="lineCol.field">
                    {{lineCol.header}}
                    <p-sortIcon [field]="lineCol.field" ariaLabel="Activate to sort"
                      ariaLabelDesc="Activate to sort in descending order" 
                      ariaLabelAsc="Activate to sort in ascending order">
                    </p-sortIcon>
                  </th>
                </ng-container>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-cseOrderItem let-columns='columns'>
              <tr>
                <ng-container *ngFor="let lineCol of columns">
                  <ng-container [ngSwitch]="lineCol.field">
                    <td *ngSwitchCase="'cseOrderLine'" class='number'>
                      {{cseOrderItem.cseOrderLine}}
                    </td>
                    <td *ngSwitchCase="'cseOrderLinePart'" class='number'>
                      {{cseOrderItem.cseOrderLinePart}}
                    </td>
                    <td *ngSwitchCase="'originalPrice'" class='number'>
                      {{cseOrderItem.originalPrice | currency :(cseOrder.websiteId.title === 'LLIE'? 'EUR': 'GBP'):'symbol':'1.2-2'}}
                    </td>
                    <td *ngSwitchCase="'finalPrice'" class='number'>
                      {{cseOrderItem.finalPrice | currency :(cseOrder.websiteId.title === 'LLIE'? 'EUR': 'GBP'):'symbol':'1.2-2'}}
                    </td>
                    <td *ngSwitchCase="'finalVat'" class='number'>
                      {{cseOrderItem.finalVat | currency :(cseOrder.websiteId.title === 'LLIE'? 'EUR': 'GBP'):'symbol':'1.2-2'}}
                    </td>
                    <td *ngSwitchCase="'quantity'" class='number'>
                      {{cseOrderItem.quantity}}
                    </td>
                    <td *ngSwitchDefault>
                      {{cseOrderItem[lineCol.field]}}
                    </td>
                  </ng-container>
                </ng-container>
              </tr>
            </ng-template>
          </p-table>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
<p-confirmDialog key="general"></p-confirmDialog>
<p-confirmDialog key="error"></p-confirmDialog>