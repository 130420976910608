import { Component, OnInit } from '@angular/core';
import {OrderService} from '../../../post-order/order.service';
import {WebsiteService} from '../../../setup/websites/website.service';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, Params} from '@angular/router';
import {Website} from '../../../models/website.model';
import {WebsitesResponse} from '../../../models/responses/websitesResponse.model';
import {FindOrderResponse} from '../../../models/responses/findOrderResponse.model';
import {SimpleResponse} from '../../../models/responses/simpleResponse.model';

@Component({
  selector: 'app-shopify-order-directdebit',
  standalone: false,
  templateUrl: './shopify-order-directdebit.component.html',
  styleUrls: ['../../cse-order.component.scss', './shopify-order-directdebit.component.scss'],
})
export class ShopifyOrderDirectDebitComponent implements OnInit {
  websites: Website[];
  brand: string;
  website: Website;
  shopifyOrderId: string;
  gcStatus: string;
  generalErrorMsg: string;
  crmOrderErrorMsg: string;
  crmOrderLink: string;
  shopifyUpdateErrorMsg: string;
  shopifyOrderLink: string;
  paymentStatus: string;
  crmOrderId: string;

  constructor(
    private title: Title,
    private websiteService: WebsiteService,
    private orderService: OrderService,
    private route: ActivatedRoute,
  ) {

  }

  public ngOnInit(): void {
    this.title.setTitle('CRM GoCardless Response');
    this.brand = '';
    this.website = undefined;
    this.shopifyOrderId = '';
    this.gcStatus = '';
    this.websites = [];
    this.generalErrorMsg = '';
    this.crmOrderErrorMsg = '';
    this.crmOrderLink  = '';
    this.shopifyUpdateErrorMsg = '';
    this.shopifyOrderLink = '';
    this.websiteService.getWebsites().subscribe({
      next: (websites: WebsitesResponse) => {
        this.websites = websites.websites;
        if (this.brand) {
          this.selectBrand();
        }
      },
      error: (err: any) => {
        console.error('Error getting website data', err);
      }
    });
    this.route.params.subscribe((params: Params) => {
      if (params.paymentStatus) {
        this.paymentStatus = params.paymentStatus;
      }
      if (params.crmOrderId) {
        this.crmOrderId = params.crmOrderId;
      }
      if (params.gcStatus) {
        this.gcStatus = params.gcStatus;
      }
      if (params.shopifyOrderId) {
        this.shopifyOrderId = params.shopifyOrderId;
      }
      if (params.brand) {
        this.brand = params.brand;
        if (this.websites.length > 0) {
          this.selectBrand();
        }
      }
    });
  }

  selectBrand() {
    this.website = this.websites.find((website: Website) => website.title == this.brand);
    if (!this.website) {
      this.generalErrorMsg = `No website found with brand: ${this.brand}`;
    } else {
      if (!/^\d+$/.test(this.shopifyOrderId) || !['accepted', 'failed'].includes(this.gcStatus)) {
        this.generalErrorMsg = `Invalid Order ID: ${this.shopifyOrderId} or GoCardless status ${this.gcStatus}`;
      } else {
        const storeName: string = this.website.shopify.storeDomain.replace(/^([^.]+)\..*/, '$1');
        this.shopifyOrderLink = `https://admin.shopify.com/store/${storeName}/orders/${this.shopifyOrderId}`;
        this.findCrmOrder();
        this.updateShopifyOrder();
      }
    }
  }

  findCrmOrder() {
    this.crmOrderErrorMsg = '';
    if (this.crmOrderId && (this.crmOrderId != 'new')) {
      this.crmOrderLink = `/order/${this.crmOrderId}`;
      return;
    }
    this.orderService.findOrder({
      'orderId': this.shopifyOrderId,
      'initialOrderDetails.orderSource': 'Shopify',
      'website': this.website._id,
      'deleted': false
    }).subscribe({
      'next': (findResponse: FindOrderResponse) => {
        if (!findResponse.success || (findResponse.orders.length == 0)) {
          if (this.paymentStatus && (this.paymentStatus == 'pending')) {
            this.crmOrderErrorMsg = 'Order not found in CRM. The order will not be imported into the CRM until it has been paid';
          } else {
            this.crmOrderErrorMsg = 'Order not found in CRM';
          }
        } else {
          this.crmOrderLink = `/order/${findResponse.orders[0]._id}`;
        }
      },
      'error': (error: any) => {
        this.crmOrderErrorMsg = `Error finding CRM order. Error: ${error.message? error.message: 'unknown error'}`;
      }
    });
  }

  updateShopifyOrder() {
    this.shopifyUpdateErrorMsg = '';
    this.orderService.getUpdateGoCardlessStatus(this.gcStatus, this.shopifyOrderId, this.brand).subscribe({
      'next': (response: SimpleResponse) => {
        if (!response.success) {
          this.shopifyUpdateErrorMsg = response.message;
        }
      },
      'error': (error: any) => {
        this.shopifyUpdateErrorMsg = `Error updating GC status on Shopify. Error: ${error.message? error.messaage: 'unknown error'}`;
      }
    })
  }
}
