<p-blockUI *ngIf="dialogVisible" [blocked]="documentBlocked" [autoZIndex]='false' styleClass="blockDialog">
  <div class="cust-feedback-block">
    <div class="row">
      <div class="col text-center">
        <h1 style="color: #fff; padding: 20px;">
          This Customer Feedback is currently being reviewed by {{custFeedbackEditingBy.user}}
        </h1>
      </div>
    </div>
    <div class="row">
      <div class="col text-center">
        <i class="fa fa-lock" [ngClass]="isSmallerThanLarge? 'fa-3x': 'fa-5x'" (click)="unlock()">
          Unlock
        </i>
        &nbsp;
        <i class="fa fa-ban" [ngClass]="isSmallerThanLarge? 'fa-3x': 'fa-5x'" (click)="closeDialog()">
          Cancel
        </i>
      </div>
    </div>
  </div>
</p-blockUI>
<p-dialog (onHide)="closeDialog()" header="Edit Customer Feedback" [modal]="true" [(visible)]="dialogVisible"
    [style]="{width: '90vw',height:'auto'}" [maximizable]="false" [minY]="70" [baseZIndex]="2000"
    [styleClass]="'editFeedbackDialog'">
  <!-- append to body is to stop the contents of this dialog being part of the post-order component form -->
  <form *ngIf="customerFeedbackForm" [formGroup]="customerFeedbackForm">
    <div class="row">
      <div *ngIf="resultMessage" class="col-12 form-group">
        <div class="alert alert-danger">
          {{resultMessage}}
        </div>
      </div>
      <div class="col-4 col-lg-2 form-group">
        <label for="brand">Brand:</label>
        <input type="text" class="form-control noAutoSave" name="brand" id="brand" value="{{originalFeedback.websiteId.title}}" readonly="true"
          [ngStyle]="{'background-color': getBrandBackgroundColour(originalFeedback.websiteId.title), 'color': getBrandColour(originalFeedback.websiteId.title)}"/>
      </div>
      <div class="col-4 col-lg-2">
        <ng-container *ngIf="originalFeedback.orderId">
          <label for="brand">TD Code:</label>
          <a routerLink="/order/{{originalFeedback.orderId}}" target="_blank">
            {{originalFeedback.tdCode}}
            <span *ngIf="isOrderLocked(originalFeedback.orderId)">
              {{orderLockedBy(originalFeedback.orderId)}}
            </span>
          </a>
        </ng-container>
      </div>
      <div class="col-4 col-lg-8"></div>
      <div class="col-4 col-lg-2 form-group">
        <label for="feedbackType">Feedback Type:</label>
        <input type="text" class="form-control noAutoSave" name="feedbackType" id="feedbackType" formControlName="feedbackType" readonly="true"/>
      </div>
      <div class="col-4 col-lg-2 form-group">
        <label for="priority">Priority:</label>
        <p-dropdown [options]="feedbackPriorities" name="priority" id="priority" formControlName="priority"
          placeholder="Priority" [required]="true" [overlayOptions]="overlayOptions" class="noAutoSave">
        </p-dropdown>
      </div>
      <div class="col-4 col-lg-2 form-group">
        <label for="partnership">Partnership:</label>
        <p-dropdown [options]="feedbackPartnerships" name="partnership" id="partnership" formControlName="partnership"
          placeholder="Partnership" [required]="true" [overlayOptions]="overlayOptions" class="noAutoSave">
        </p-dropdown>
      </div>
      <div class="col-6 col-lg-3 form-group">
        <label for="whoContactedUs">Who Contacted Us:</label>
        <input type="text" class="form-control noAutoSave" name="whoContactedUs" id="whoContactedUs" formControlName="whoContactedUs" required="true"/>
      </div>
      <div class="col-6 col-lg-3 form-group">
        <label for="relationshipToUser">Relationship To User:</label>
        <input type="text" class="form-control noAutoSave" name="relationshipToUser" id="relationshipToUser" formControlName="relationshipToUser" required="true"/>
      </div>
    </div>
    <div class="row" [ngClass]="customerFeedbackForm.errors?.contactDetailsRequired? 'valueRequired': 'valueProvided'">
      <div class="col-6 form-group">
        <label for="emailAddress">Email Address:</label>
        <input type="text" class="form-control validated noAutoSave" name="emailAddress" id="emailAddress" formControlName="emailAddress" [required]="emailRequired" />
      </div>
      <div class="col-6 col-lg-3 form-group">
        <label for="phoneNumber">Phone Number:</label>
        <input type="text" class="form-control validated noAutoSave" name="phoneNumber" id="phoneNumber" formControlName="phoneNumber"
          (keypress)="phoneNumberOnly($event)"/>
      </div>
      <div class="col-6 col-lg-3 form-group">
        <label for="contactMethod">Contact Method:</label>
        <p-dropdown [options]="feedbackContactMethods" name="contactMethod" id="contactMethod" formControlName="contactMethod" class="noAutoSave"
          placeholder="Contact Method" [required]="true" [overlayOptions]="overlayOptions" (onChange)="updateEmailAddressRequired()">
        </p-dropdown>
      </div>
    </div>
    <div class="row">
      <div class="col-6 col-lg-3 form-group">
        <label for="mainReason">Main Reason:</label>
        <p-dropdown [options]="feedbackMainReasons" name="mainReason" id="mainReason" formControlName="mainReason"
          placeholder="Feedback Reason" [required]="true" [overlayOptions]="overlayOptions" class="noAutoSave"
          (onChange)="changeMainReason($event.value, true)">
        </p-dropdown>
      </div>
      <div class="col-6 col-lg-3 form-group">
        <label for="specificReason">Specifically:</label>
        <p-dropdown [options]="feedbackSpecificReasons" name="specificReason" id="specificReason" formControlName="specificReason"
          placeholder="Specific Reason" [required]="true" [overlayOptions]="overlayOptions" class="noAutoSave">
        </p-dropdown>
      </div>
      <div class="col-6 form-group">
        <label for="feedbackDetails">Feedback Details:</label>
        <textarea name="feedbackDetails" class="form-control noAutoSave" rows="3" formControlName="feedbackDetails" required="true">
        </textarea>
      </div>
    </div>
    <ng-container formArrayName="contactAttempts">
      <div class="row" *ngIf="contactAttemptsForms.length > 0">
        <div class="col-12">
          <label>Contact Attempts:</label>
        </div>
      </div>
      <div class="row" *ngFor="let contactAttempt of contactAttemptsForms.controls; let contactAttemptIndex=index" [formGroupName]="contactAttemptIndex">
        <div class="col-5 form-group">
          <label for="byUsername">Attempt By:</label>
          <input type="text" class="form-control noAutoSave" name="byUsername" readonly="true" value="{{contactAttempt.value.byUsername}}" />
        </div>
        <div class="col-5 form-group">
          <label for="dateOfAttempt">Attempt Made:</label>
          <input type="text" class="form-control noAutoSave" name="dateOfAttempt" readonly="true" value="{{contactAttempt.value.dateOfAttempt | date:'dd/MM/yyyy HH:mm'}}"/>
        </div>
        <div class="col-2 form-group">
          <button type="button" class="btn deleteBtn" (click)="deleteContactAttempt(contactAttemptIndex)">
            <i class="fas fa-trash"></i>
          </button>
        </div>
      </div>
    </ng-container>
    <div class="row">
      <div class="col-6 form-group">
        <label for="resolution">Resolution:</label>
        <textarea name="resolution" class="form-control noAutoSave" rows="3" formControlName="resolution" [required]="isResolutionRequired">
        </textarea>
      </div>
      <div class="col-6 form-group">
        <label for="respondByEmail">Respond To Customer By Email:</label>
        <input type="checkbox" name="respondByEmail" id="respondByEmail" formControlName="respondByEmail"
          (change)="updateEmailAddressRequired()" class="noAutoSave"/>
      </div>
      <div class="col-6 form-group">
        <label for="resolvedAtFirstContact">Resolved at 1st Contact:</label>
        <input type="checkbox" name="resolvedAtFirstContact" id="resolvedAtFirstContact" formControlName="resolvedAtFirstContact"
          (change)="changeResolvedAtFirstContact($event)" class="noAutoSave"/>
      </div>
      <div class="col-12 col-xl-6 form-group">
        <label for="ccTo">CC To:</label>
        <textarea name="ccTo" class="form-control validated noAutoSave" rows="3" formControlName="ccTo">
        </textarea>
        <span class="hint">semi colon, space or newline separated</span>
      </div>
      <div class="col-12 col-xl-4 form-group">
        <div class="row">
          <div class="col-6">
            <label for="freeServiceGiven">Free Service Given:</label>
            <input type="checkbox" name="freeServiceGiven" id="freeServiceGiven" formControlName="freeServiceGiven"
              (change)="freeServiceChanged()" class="noAutoSave" />
          </div>
          <div class="col-6" *ngIf="isFreeServiceGiven">
            <label for="datePaymentRestarts">Payment Restarts:</label>
            <input type="date" name="datePaymentRestarts" id="datePaymentRestarts" formControlName="datePaymentRestarts"
              required class="noAutoSave" />
          </div>
        </div>
      </div>
      <div class="col-12 col-xl-2 form-group">
        <label for="status">Status:</label>
        <p-dropdown [options]="feedbackStatuses" name="status" id="status" formControlName="status"
          placeholder="Feedback Status" [required]="true" [overlayOptions]="overlayOptions"
          (onChange)="changeFeedbackStatus($event.value)" [readonly]="isStatusReadOnly"
          [styleClass]="isStatusReadOnly? 'readonly': ''" class="noAutoSave">
        </p-dropdown>
      </div>
    </div>
  </form>
  <ng-template pTemplate="footer">
    <button type="button" class="btn btn-warning" (click)="closeDialog()">
      Cancel
    </button>
    <ng-container *ngIf="(fromOrder || !originalFeedback.orderId); else noEdit">
      <button type="button" class="btn btn-primary mr-2" [disabled]="!isOkToSave" (click)="saveFeedback()">
        Submit
      </button>
      <button type="button" class="btn btn-primary mr-2" [disabled]="!isOkToAddContactAttempt" (click)="addContactAttempt()">
        Record Contact Attempt
      </button>
    </ng-container>
    <ng-template #noEdit>
      <div class="alert alert-info mr-2 mb-0">You can only change an order linked feedback from the order page.</div>
    </ng-template>
  </ng-template>
</p-dialog>
<p-confirmDialog key="general"></p-confirmDialog>
<p-confirmDialog key="error" [baseZIndex]="4000" [autoZIndex]='true'></p-confirmDialog>
<router-outlet (window:unload)="close()"></router-outlet>
